import { Component, ContentChild, Input } from '@angular/core';
import { AdminHeaderToolbarDirective } from '@obo-admin/adminHeader/adminHeaderToolbar.directive';
import { AdminHeaderActionButtonsDirective } from '@obo-admin/adminHeader/adminHeaderActionButtons.directive';
import { AdminHeaderCustomTitleDirective } from '@obo-admin/adminHeader/adminHeaderCustomTitle.directive';

@Component({
    selector: 'adm-header',
    templateUrl: './adminHeader.component.html',
    styleUrls: ['./adminHeader.component.scss']
})
export class AdminHeaderComponent {
    @Input()
    title: string;

    @ContentChild(AdminHeaderCustomTitleDirective)
    public customTitleTpl: AdminHeaderCustomTitleDirective;
    @ContentChild(AdminHeaderToolbarDirective)
    public toolbarTpl: AdminHeaderToolbarDirective;
    @ContentChild(AdminHeaderActionButtonsDirective)
    public actionButtonsTpl: AdminHeaderActionButtonsDirective;
}
