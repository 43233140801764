import { BreakpointObserver } from '@angular/cdk/layout';
import { getUser } from '@obo-account/account.states';
import { AccountService } from '@obo-account/account/account.service';
import { DashboardComponent } from '@obo-dashboard/components/dashboard/dashboard.component';
import { ProjectComponent } from '@obo-dashboard/components/dashboard/project/project.component';
import { DashboardResolvers } from '@obo-dashboard/resolvers/dashboard.resolvers';
import { BuildingService } from '@obo-dashboard/services/building.service';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { Constants } from 'app/constants';

export const dashboardState: Ng2StateDeclaration = {
    parent: 'app',
    url: '/dashboard',
    name: 'dashboard',
    component: DashboardComponent,
    data: {
        module: 'main',
        additionalModules: ['shared'],
        roles: [Constants.Role.User]
    },
    resolve: [
        {
            token: 'projectList',
            deps: [ProjectService],
            resolveFn: DashboardResolvers.getProjectList
        },
        {
            token: 'modules',
            deps: [ModuleService],
            resolveFn: DashboardResolvers.modulesResolver
        },
        {
            token: 'user',
            deps: [AccountService, AuthService],
            resolveFn: getUser
        },
        {
            token: 'isMobile',
            deps: [BreakpointObserver],
            resolveFn: DashboardResolvers.getIsMobileScreenwidth
        }
    ],
    onEnter: DashboardResolvers.loadLocale
};

export const projectDetailState: Ng2StateDeclaration = {
    parent: 'app',
    url: '/projects/:projectId',
    name: 'project',
    params: {
        edit: false
    },
    data: {
        module: 'main',
        additionalModules: ['shared'],
        roles: [Constants.Role.User],
        scrollTo: '#buildings'
    },
    onEnter: DashboardResolvers.onEnterProjectHook,
    resolve: [
        {
            token: 'project',
            deps: [Transition, ProjectService],
            resolveFn: DashboardResolvers.getProject
        },
        {
            token: 'buildings',
            deps: [Transition, ProjectService, BuildingService],
            resolveFn: DashboardResolvers.getBuildings
        },
        {
            token: 'isMobile',
            deps: [BreakpointObserver],
            resolveFn: DashboardResolvers.getIsMobileScreenwidth
        }
    ],
    component: ProjectComponent
};
