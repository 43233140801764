import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Directive({
    selector: '[shdToggle]'
})
export class TogglePasswordDirective implements OnInit {
    private passwordShown = false;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private viewContainerRef: ViewContainerRef
    ) {}

    public ngOnInit(): void {
        const span = this.document.createElement('span');
        span.appendChild(this.getFaIconComponent('eye-slash').location.nativeElement);
        span.addEventListener('click', () => {
            this.toggle(span);
        });
        this.renderer.addClass(span, 'password-toggle-btn');

        this.element.nativeElement.parentElement.appendChild(span);
    }

    private toggle(span: HTMLElement) {
        this.passwordShown = !this.passwordShown;
        span.removeChild(span.childNodes[0]);
        if (this.passwordShown) {
            this.element.nativeElement.setAttribute('type', 'text');

            span.appendChild(this.getFaIconComponent('eye').location.nativeElement);
        } else {
            this.element.nativeElement.setAttribute('type', 'password');

            span.appendChild(this.getFaIconComponent('eye-slash').location.nativeElement);
        }
    }

    private getFaIconComponent(icon: 'eye' | 'eye-slash'): ComponentRef<FaIconComponent> {
        const component = this.viewContainerRef.createComponent<FaIconComponent>(FaIconComponent);

        component.instance.icon = ['fal', icon];
        component.instance.size = 'lg';
        component.instance.render();

        return component;
    }
}
