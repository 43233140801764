<div
    ngbDropdown
    class="d-flex"
    [container]="overflowHidden ? 'body' : null"
    [placement]="overflowHidden ? 'bottom-left' : 'bottom-right'"
    [class.divider]="hasDivider">
    <button
        [attr.id]="contextMenuId"
        [disabled]="disabled"
        (click)="$event.stopPropagation()"
        ngbDropdownToggle
        class="btn btn-fa btn-color-orange btn-contextmenu-toggle">
        <fa-icon [icon]="['fal', 'ellipsis-v']" [size]="size"></fa-icon>
    </button>
    <div
        shdClickInside
        (clickInside)="dropdown.close()"
        ngbDropdownMenu
        [attr.aria-labelledby]="contextMenuId"
        class="contextmenu-content"
        [class.parent-is-overflow-hidden]="overflowHidden">
        <ng-content></ng-content>
    </div>
</div>
