import { Injectable } from '@angular/core';
import { Constants } from 'app/constants';

@Injectable()
export class BreakpointService {
    public mediaBreakpoints: { [key: string]: number };

    constructor() {
        this.mediaBreakpoints = this.getMediaBreakPoints();
    }

    private getMediaBreakPoints(): { [key: string]: number } {
        const computedStyles = window.getComputedStyle(document.documentElement);
        return Object.keys(Constants.ResponsiveBreakpoint).reduce((result, v) => {
            const breakpointValue = computedStyles.getPropertyValue('--bs-breakpoint-' + v);

            result[v] = parseInt(breakpointValue);
            return result;
        }, {} as { [key: string]: number });
    }
}
