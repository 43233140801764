import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { OverlayConfig } from '@angular/cdk/overlay';

@Component({
    selector: 'adm-portal-country-modal',
    templateUrl: './portalCountryModal.component.html',
    styleUrls: ['./portalCountryModal.component.scss'],
    exportAs: 'portalCountryModal'
})
export class PortalCountryModalComponent {
    @Output()
    public confirmed = new EventEmitter<void>();
    @Output()
    public dismissed = new EventEmitter<void>();

    @Input()
    public fg: UntypedFormGroup;

    public get formFeatures() {
        return <UntypedFormArray>this.fg!.controls.features;
    }

    @ViewChild('modal') modal: TemplateRef<any>;

    constructor(
        private overlayService: OverlayService,
        private overlayConfig: OverlayConfig,
        private viewContainerRef: ViewContainerRef
    ) {
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
    }

    public show(): void {
        this.overlayService.open(this.modal);
    }

    public close(): void {
        this.overlayService.close();
        this.dismissed.emit();
    }

    public confirm(): void {
        this.overlayService.close();
        this.confirmed.emit();
    }
}
