import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { Window } from '@obo-main/utils/window.service';

@Directive({ selector: '[shdMouseWheel]' })
export class MouseWheelMovedDirective {
    @Input()
    public disableMouseWheelEvent: boolean = false;

    @Output()
    public mouseWheelUp = new EventEmitter();
    @Output()
    public mouseWheelDown = new EventEmitter();

    constructor(@Inject(Window) private window: Window) {}

    @HostListener('mousewheel', ['$event'])
    public onMouseWheelChrome(event: any) {
        this.mouseWheelFunc(event);
    }

    @HostListener('DOMMouseScroll', ['$event'])
    public onMouseWheelFirefox(event: any) {
        this.mouseWheelFunc(event);
    }

    @HostListener('onmousewheel', ['$event'])
    public onMouseWheelIE(event: any) {
        this.mouseWheelFunc(event);
    }

    public mouseWheelFunc(event: any) {
        if (!this.disableMouseWheelEvent) {
            var event = this.window.event || event; // old IE support
            var delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
            if (delta > 0) {
                this.mouseWheelUp.emit(event);
            } else if (delta < 0) {
                this.mouseWheelDown.emit(event);
            }
            // for IE
            event.returnValue = false;
            // for Chrome and Firefox
            if (event.preventDefault) {
                event.preventDefault();
            }
        }
    }
}
