import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { RegionService } from '@obo-main/services/region/region.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';

@Pipe({ name: 'catalogUrl' })
@Injectable()
export class CatalogUrlPipe implements PipeTransform {
    constructor(private regionService: RegionService, private translateService: TranslateService) {}

    public transform(articleNumber: string | number): Observable<string> {
        const languageCode = this.translateService.currentLang;
        const selectedApplicationRegion = this.regionService.selectedApplicationRegion;
        if (selectedApplicationRegion) {
            return of(
                selectedApplicationRegion.catalogUrl
                    .replace('{languageCode}', languageCode)
                    .replace('{articleNumber}', `${articleNumber}`)
            );
        }
        return throwError('Undefined Application Region');
    }
}
