import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'greeting' })
export class GreetingPipe implements PipeTransform {
    private greetingMap = [
        {
            condition: (hour) => hour < 12,
            key: 'DASHBOARD_GREETING_GOOD_MORNING',
            altKey: 'DASHBOARD_GREETING_GOOD_MORNING_ALT'
        },
        {
            condition: (hour) => hour < 17,
            key: 'DASHBOARD_GREETING_WELCOME_BACK',
            altKey: 'DASHBOARD_GREETING_WELCOME_BACK_ALT'
        },
        {
            condition: (hour) => hour < 24,
            key: 'DASHBOARD_GREETING_GOOD_EVENING',
            altKey: 'DASHBOARD_GREETING_GOOD_EVENING_ALT'
        }
    ];

    public transform(date: Date, alternative?: boolean): string {
        let key = 'DASHBOARD_TITLE';

        this.greetingMap.every((greeting) => {
            if (greeting.condition(date.getHours())) {
                key = alternative ? greeting.altKey : greeting.key;
                return false;
            }
            return true;
        });

        return key;
    }
}
