import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Building, Project } from '@obo-dashboard/models/projectManagement.models';
import { BuildingService } from '@obo-dashboard/services/building.service';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { Module } from '@obo-main/services/modules/module.models';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Transition } from '@uirouter/core';
import { concatAll, map, mergeMap, toArray } from 'rxjs/operators';
import { LOCALE_ID } from '@angular/core';
import { LocaleService } from '@obo-main/services/locale/locale.service';
import { Planning } from '@obo-main/services/plannings/plannings.models';

export namespace DashboardResolvers {
    export function modulesResolver(moduleService: ModuleService): Promise<Module[]> {
        return Promise.resolve(moduleService.getModulesWithPlanning());
    }

    export function getProjectIdFromUrl(trans: Transition): number {
        return Number.parseInt(trans.params().projectId);
    }

    export function getProjectList(projectService: ProjectService): Promise<Project[]> {
        return projectService.getProjectList().toPromise();
    }

    export function getProject(trans: Transition, projectService: ProjectService): Promise<Project> {
        return projectService.getProject(Number.parseInt(trans.params().projectId)).toPromise();
    }

    export function getBuildings(
        trans: Transition,
        projectService: ProjectService,
        buildingService: BuildingService
    ): Promise<Building[]> {
        return projectService
            .getBuildingsByProject(Number.parseInt(trans.params().projectId))
            .pipe(
                concatAll(),
                mergeMap((b: Building) =>
                    buildingService.getPlanningsByBuilding(Number.parseInt(trans.params().projectId), b.id).pipe(
                        map((p: Planning[]) => {
                            b.plannings = buildingService.extendPlannings(p);
                            return b;
                        })
                    )
                ),
                toArray()
            )
            .toPromise();
    }

    export function onEnterProjectHook(transition: Transition): Promise<any> {
        const projectId = getProjectIdFromUrl(transition);

        const projectService = transition.injector().get<ProjectService>(ProjectService);
        return projectService.updateProjectsUsageDateTimestamp(projectId).toPromise();
    }

    export function getIsMobileScreenwidth(breakpointObserver: BreakpointObserver): boolean {
        let isMobile = false;
        breakpointObserver.observe(['(max-width: 768px)']).subscribe((state: BreakpointState): void => {
            isMobile = state.matches;
        });
        return isMobile;
    }

    export function loadLocale(transition: Transition): Promise<any> {
        const localeId = transition.injector().get(LOCALE_ID);
        const localeService = transition.injector().get(LocaleService);
        return localeService.loadLocale(localeId);
    }
}
