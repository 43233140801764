import { OverlayConfig } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export const GROUNDING_PRODUCT_SELECTOR_MODAL_CONFIG = new InjectionToken<OverlayConfig>(
    'GROUNDING_PRODUCT_SELECTOR_MODAL_CONFIG'
);
export const CUSTOM_CABLE_MODAL_CONFIG = new InjectionToken<OverlayConfig>('CUSTOM_CABLE_MODAL_CONFIG');
export const ZOOMED_IMAGE_MODAL_CONFIG = new InjectionToken<OverlayConfig>('ZOOMED_IMAGE_MODAL_CONFIG');
export const OVERLAY_XS_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_XS_CONFIG');
export const OVERLAY_S_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_S_CONFIG');
export const OVERLAY_L_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_L_CONFIG');
export const OVERLAY_XL_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_XL_CONFIG');
export const OVERLAY_NOPRODUCTS_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_NOPRODUCTS_CONFIG');
export const OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG = new InjectionToken<OverlayConfig>(
    'OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG'
);

export const OVERLAY_FULL_SCREEN_CONFIG = new InjectionToken<OverlayConfig>('OVERLAY_FULL_SCREEN_CONFIG');
