<div id="user-management">
    <adm-header [title]="'ADMIN_USERSLIST' | translate">
        <ng-template admHeaderToolbar>
            <div class="d-flex align-items-center p-1">
                <shd-multi-filter-select [withBorder]="true"
                                         style="width: 250px"
                                         [filter]="filterFormGroup.controls.regions"
                                         [applyBtnText]="'BTN_APPLY' | translate"
                                         [selectAllBtnText]="'CHECK_ALL' | translate"
                                         [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
                <div class="d-flex align-items-center">
                    <span
                        class="mx-2 text-nowrap font-weight-bold">{{ 'ADMIN_ANALYTICS_REGISTRATION_DATE' | translate }}
                        :</span>
                    <label for="startDate" class="label mb-0 ms-2">
                        {{ 'ADMIN_STARTDATE' | translate }}
                    </label>
                    <input class="ms-2 form-control form-control-border" type="date" id="startDate" name="startDate"
                           [(ngModel)]="range.start" />
                    <label for="endDate" class="label mb-0 ms-2">
                        {{ 'ADMIN_ENDDATE' | translate }}
                    </label>
                    <input class="ms-2 form-control form-control-border" type="date" id="endDate" name="endDate"
                           [(ngModel)]="range.end" />
                    <button
                        class="btn btn-success ms-2"
                        type="button"
                        [disabled]="!(range.start && range.end)"
                        (click)="applyFilter()">
                        {{ 'BTN_APPLY' | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'USERS_SENDINVITATION' | translate" (click)="openUserInvitation()">
                <fa-icon [icon]="['fal', 'user-plus']" size="2x"></fa-icon>
            </button>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="grid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid #grid [selectable]="true"
                   (selectionChange)="selectRow($event)" [data]="dataGridService | async"
                   (excelExport)="onExcelExport($event)">
        <adm-data-grid-column [sortable]="false" [filterable]="false" field="regionName"
                              [title]="'ADMIN_ANALYTICS_REGIONNAME' | translate" [isMulti]="true">
            <ng-template admDataGridCell let-dataItem>
                {{ regionMap[dataItem.regionId] | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isAdmin" [editable]="false" title="Admin">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isAdmin | yesNo | translate }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="email" title="{{ 'USERS_EMAIL' | translate }}">
        </adm-data-grid-column>
        <adm-data-grid-column field="lastName" title="{{ 'USERS_LASTNAME' | translate }}">
        </adm-data-grid-column>
        <adm-data-grid-column field="firstName" title="{{ 'USERS_FIRSTNAME' | translate }}">
        </adm-data-grid-column>
        <adm-data-grid-column field="projectCount" title="{{ 'USERS_PROJECTCOUNT' | translate }}" [editable]="false"
                              [sortable]="false" [filterable]="false">
        </adm-data-grid-column>
        <adm-data-grid-column field="buildingCount" title="{{ 'USERS_BUILDINGCOUNT' | translate }}" [editable]="false"
                              [sortable]="false" [filterable]="false">
        </adm-data-grid-column>
        <adm-data-grid-column field="latestSignInDate" title="{{ 'USERS_LATESTSIGNINDATE' | translate }}"
                              [editable]="false" [filterable]="false">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.latestSignInDate | date : 'shortDate' }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column
            field="registrationDate"
            title="{{ 'USERS_REGISTRATIONDATE' | translate }}"
            [editable]="false"
            [filterable]="false">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.registrationDate | date : 'shortDate' }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isContactable" [editable]="false"
                              title="{{ 'ADMIN_ISCONTACTABLE' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isContactable | yesNo | translate }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="emailConfirmed"
                              [title]="'ADMIN_EMAILCONFIRMED' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.emailConfirmed | yesNo | translate }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-excel fileName="users.xlsx" [fetchData]="fetchData()">
            <adm-data-grid-excel-column field="regionId"
                                        title="{{ 'ADMIN_ANALYTICS_REGIONNAME' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="title"
                                        title="{{ 'USERS_USERTITLE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="email"
                                        title="{{ 'USERS_EMAIL' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="firstName"
                                        title="{{ 'USERS_FIRSTNAME' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="lastName"
                                        title="{{ 'USERS_LASTNAME' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="phone"
                                        title="{{ 'USERS_PHONE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="street"
                                        title="{{ 'SHARED_STREET' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="postalCode"
                                        title="{{ 'SHARED_ZIPCODE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="city"
                                        title="{{ 'SHARED_CITY' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="regionId"
                                        title="{{ 'SHARED_REGION' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="company"
                                        title="{{ 'USERS_COMPANY' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="customerGroup"
                                        title="{{ 'CUSTOMERGROUP' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="isContactable"
                                        title="{{ 'ADMIN_ISCONTACTABLE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="registrationDate"
                                        title="{{ 'USERS_REGISTRATIONDATE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="latestSignInDate"
                                        title="{{ 'USERS_LATESTSIGNINDATE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="projectCount"
                                        title="{{ 'USERS_PROJECTCOUNT' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="buildingCount"
                                        title="{{ 'USERS_BUILDINGCOUNT' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="emailConfirmed"
                                        title="{{ 'ADMIN_EMAILCONFIRMED' | translate }}"></adm-data-grid-excel-column>
        </adm-data-grid-excel>
    </adm-data-grid>
</div>

<ng-template #inviteUserTpl>
    <div class="modal">
        <div class="modal-header">
            <h3 class="mb-0">{{ 'USERS_SENDINVITATION' | translate }}</h3>
            <button class="btn-close" (click)="cancelHandler()"></button>
        </div>
        <div class="modal-body">
            <input
                class="form-control"
                type="email"
                placeholder="Email"
                #inviteUserEmail="ngModel"
                ngModel
                name="email"
                required />
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                <button class="btn btn-success" [disabled]="inviteUserEmail.invalid" type="button"
                        (click)="inviteUser(inviteUserEmail.value)">
                    {{ 'BTN_APPLY' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #formModal>
    <div class="modal">
        <div class="modal-header">
            <h3 class="mb-0">{{ formGroup.controls.email.value }}</h3>
            <button class="btn-close" (click)="cancelHandler()"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formGroup">
                <div class="mb-3">
                    <label for="editForm-title">
                        {{ 'USERS_USERTITLE' | translate }}
                    </label>
                    <shd-single-select formControlName="title" id="editForm-title">
                        <ng-template shdSingleSelectLabel>
                            {{ formGroup.controls.title.value | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'REGISTERFORM_USERTITLE_MR'">
                            {{ 'REGISTERFORM_USERTITLE_MR' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'REGISTERFORM_USERTITLE_MRS'">
                            {{ 'REGISTERFORM_USERTITLE_MRS' | translate }}
                        </ng-template>
                    </shd-single-select>
                </div>
                <div class="mb-3">
                    <label for="editForm-email">
                        {{ 'USERS_EMAIL' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="editForm-email"
                        type="email"
                        formControlName="email"
                        shdValidateInput
                        required />
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-firstName">
                            {{ 'USERS_FIRSTNAME' | translate }}
                        </label>
                        <input class="form-control" id="editForm-firstName" type="text" formControlName="firstName" />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-lastName">
                            {{ 'USERS_LASTNAME' | translate }}
                        </label>
                        <input class="form-control" id="editForm-lastName" type="text" formControlName="lastName" />
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-street">
                            {{ 'SHARED_STREET' | translate }}
                        </label>
                        <input class="form-control" id="editForm-street" type="text" formControlName="street" />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-city">
                            {{ 'SHARED_CITY' | translate }}
                        </label>
                        <input class="form-control" id="editForm-city" type="text" formControlName="city" />
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-postalCode">
                            {{ 'USERS_ZIPCODE' | translate }}
                        </label>
                        <input class="form-control" id="editForm-postalCode" type="text" formControlName="postalCode" />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-region">
                            {{ 'SHARED_REGION' | translate }}
                        </label>
                        <shd-single-select formControlName="regionId">
                            <ng-template shdSingleSelectLabel>
                                {{ (formGroup.controls.regionId.value | region | async)?.displayName | translate }}
                            </ng-template>
                            @for (region of regionList; track region) {
                                <ng-template shdSingleSelectOption [value]="region.id">
                                    {{ (region.id | region | async)?.displayName | translate }}
                                </ng-template>
                            }
                        </shd-single-select>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-company">
                            {{ 'USERS_COMPANY' | translate }}
                        </label>
                        <input class="form-control" id="editForm-company" type="text" formControlName="company" />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-customerGroup">
                            {{ 'CUSTOMERGROUP' | translate }}
                        </label>
                        <shd-single-select formControlName="customerGroup" id="editForm-customerGroup">
                            <ng-template shdSingleSelectLabel>
                                {{ formGroup.controls.customerGroup.value | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_TRADE">
                                {{ 'CUSTOMERGROUP_TRADE' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_INSTALLER">
                                {{ 'CUSTOMERGROUP_INSTALLER' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_GENERALCONTRACTOR">
                                {{ 'CUSTOMERGROUP_GENERALCONTRACTOR' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_ENDUSER">
                                {{ 'CUSTOMERGROUP_ENDUSER' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_CONSULTANT">
                                {{ 'CUSTOMERGROUP_CONSULTANT' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_INSTITUTION">
                                {{ 'CUSTOMERGROUP_INSTITUTION' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_MANUFACTURER">
                                {{ 'CUSTOMERGROUP_MANUFACTURER' | translate }}
                            </ng-template>
                            <ng-template shdSingleSelectOption value="CUSTOMERGROUP_VIP">
                                {{ 'CUSTOMERGROUP_VIP' | translate }}
                            </ng-template>
                        </shd-single-select>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-check">
                        <input
                            id="editForm-isContactable"
                            type="checkbox"
                            formControlName="isContactable"
                            class="form-check-input" />
                        <label class="form-check-label text-muted" for="editForm-isContactable">
                            {{ 'ADMIN_ISCONTACTABLE' | translate }}
                        </label>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-check">
                        <input
                            id="emailConfirmed"
                            type="checkbox"
                            formControlName="emailConfirmed"
                            class="form-check-input" />
                        <label class="form-check-label text-muted" for="emailConfirmed">
                            {{ 'ADMIN_EMAILCONFIRMED' | translate }}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-registrationDate">
                            {{ 'USERS_REGISTRATIONDATE' | translate }}
                        </label>
                        <input
                            class="form-control"
                            id="editForm-registrationDate"
                            type="text"
                            [value]="formGroup!.get('registrationDate')?.value | date : 'short'"
                            readonly />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-latestSignInDate">
                            {{ 'USERS_LATESTSIGNINDATE' | translate }}
                        </label>
                        <input
                            class="form-control"
                            id="editForm-latestSignInDate"
                            type="text"
                            [value]="formGroup!.get('latestSignInDate')?.value | date : 'short'"
                            readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-6">
                        <label for="editForm-buildingCount">
                            {{ 'USERS_BUILDINGCOUNT' | translate }}
                        </label>
                        <input
                            class="form-control"
                            id="editForm-buildingCount"
                            type="text"
                            formControlName="buildingCount"
                            readonly />
                    </div>
                    <div class="mb-3 col-6">
                        <label for="editForm-projectCount">
                            {{ 'USERS_PROJECTCOUNT' | translate }}
                        </label>
                        <input
                            class="form-control"
                            id="editForm-projectCount"
                            type="text"
                            formControlName="projectCount"
                            readonly />
                    </div>
                </div>
                <div class="mb-3">
                    <h4>{{ 'USERS_ROLES' | translate }}</h4>
                    <div class="d-flex flex-column" formGroupName="roles">
                        @for (control of formRoles.controls; track control; let i = $index) {
                            <div class="form-check" [formGroupName]="i">
                                <input
                                    type="checkbox"
                                    formControlName="isMember"
                                    (ngModelChange)="updateIsAdminFlag()"
                                    [attr.id]="'role-' + i"
                                    class="form-check-input" />
                                <label class="form-check-label text-muted" [attr.for]="'role-' + i">
                                    {{ control.value.name }}
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                <button class="btn btn-secondary" type="button" (click)="confirmModalRef.show()">
                    {{ 'BTN_DELETE' | translate }}
                </button>
                <button class="btn btn-success" type="button" (click)="saveHandler()">
                    {{ 'BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<shd-confirm-modal
    #confirmModalRef
    (confirmed)="deleteUser(selectedRow)"
    [title]=" 'ADMIN_ARE_YOU_SURE' | translate"></shd-confirm-modal>
