import { Inject, Injectable } from '@angular/core';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatAll, map, toArray } from 'rxjs/operators';
import { Motd } from '@obo-admin/motdManagement/motd.models';

@Injectable()
export class MotdManagementService {
    private motdUrl: string = `${this.apiPrefix}Administration/Notifications`;

    constructor(private logger: Logger, private http: HttpClient, @Inject('APIPREFIX') private apiPrefix: string) {}

    /**
     * creates a new or updates an existing motd
     * depends on existance of id property
     * @param message
     */
    public createOrUpdateMotd(message: Motd): Observable<Motd> {
        if (typeof message.id === 'number') {
            return this.updateMotd(message);
        } else {
            return this.createMotd(message);
        }
    }

    /**
     * returns the motdlist from api
     */
    public getMotdList(): Observable<Motd[]> {
        return this.http.get<any[]>(this.motdUrl).pipe(
            concatAll(),
            map((motd) => {
                motd.startDate = new Date(Date.parse(motd.startDate));
                motd.endDate = new Date(Date.parse(motd.endDate));
                motd.isActive =
                    (motd.startDate as Date).getTime() <= Date.now() && (motd.endDate as Date).getTime() >= Date.now();
                return motd as Motd;
            }),
            toArray()
        );
    }

    /**
     * deletes a motd on api
     * @param id
     */
    public deleteMotd(id: number): Observable<boolean> {
        return this.http.delete(`${this.motdUrl}/${id}`).pipe(map(() => true));
    }

    /**
     * creates a new motd on api
     * @param message
     */
    private createMotd(message: Motd): Observable<Motd> {
        return this.http.post(this.motdUrl, this.convertMotdToApiData(message)) as Observable<Motd>;
    }

    /**
     * updates a motd on api
     * @param message
     */
    private updateMotd(message: Motd): Observable<Motd> {
        return this.http.put(`${this.motdUrl}/${message.id}`, this.convertMotdToApiData(message)).pipe(map(() => message));
    }

    /**
     * converts a motd to apidata.
     * simply exchanges the date objects by iso date strings
     * @param message
     */
    private convertMotdToApiData(message: Motd): any {
        let data: any = {};
        Object.assign(data, message);
        data.startDate = data.startDate.toISOString();
        data.endDate = data.endDate.toISOString();
        return data;
    }
}
