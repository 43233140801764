import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DesktopOnlyModalComponent } from '@obo-common/shared/components/desktopOnlyModal/desktopOnlyModal.component';
import { ExtendedPlanning } from '@obo-dashboard/models/projectManagement.models';

@Component({
    selector: 'app-plannings',
    templateUrl: './plannings.component.html',
    styleUrls: ['./plannings.component.scss']
})
export class PlanningsComponent implements OnChanges {
    @Input()
    public buildingId: number;
    @Input()
    public projectId: number;
    @Input()
    public plannings: ExtendedPlanning[];
    @Input()
    public isMobile: boolean = false;
    @Output()
    public reloadBuildings: EventEmitter<number> = new EventEmitter<number>();

    public plannedPlannings: ExtendedPlanning[] = [];
    public unplannedPlannings: ExtendedPlanning[] = [];

    @ViewChild('desktopOnlyModalTpl')
    public desktopOnlyModal: DesktopOnlyModalComponent;

    public ngOnChanges(): void {
        this.plannedPlannings = this.plannings.filter((p) => p.isPlanned);
        this.unplannedPlannings = this.plannings.filter((p) => !p.isPlanned);
    }

    public openWarningModal(): void {
        this.desktopOnlyModal.open();
    }
}
