import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class FileSavingService {
    constructor(private fileSaverService: FileSaverService) {}
    public saveFile(file: Blob, fileName: string) {
        this.fileSaverService.save(file, fileName);
    }
}
