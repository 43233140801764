import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Utils } from '@obo-main/utils/utils.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuestExportItem } from '../models/guestExport.models';

@Injectable()
export class GuestExportService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private utils: Utils) {}

    /**
     * Requests the materialList for the given planning from Api
     * @param items
     */
    public getGuestExport(moduleId: number, items: GuestExportItem[]): Observable<{ name: string; blob: Blob }> {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return this.http
            .post(
                `${this.apiPrefix}OrderLists`,
                { moduleId: moduleId, items: items },
                {
                    headers: new HttpHeaders().append('accept', fileType),
                    observe: 'response',
                    responseType: 'arraybuffer'
                }
            )
            .pipe(
                map((response: any) => {
                    return {
                        name: this.utils.parseFileNameFromHeader(response.headers),
                        blob: new Blob([response.body!], {
                            type: fileType
                        })
                    };
                })
            );
    }
}
