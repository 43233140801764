<div id="analyticsManagement">
    <adm-header [title]="'NAVIGATION_ANALYTICS' | translate">
        <ng-template admHeaderToolbar>
            <div class="d-flex align-items-center custom-toolbar p-1">
                <shd-multi-filter-select [withBorder]="true"
                                         style="width: 250px"
                                         [filter]="filterFormGroup.controls.regions"
                                         [applyBtnText]="'BTN_APPLY' | translate"
                                         [selectAllBtnText]="'CHECK_ALL' | translate"
                                         [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
                <div class="d-flex align-items-center">
                    <span class="mx-2 text-nowrap font-weight-bold">{{ 'ADMIN_ANALYTICS_CREATIONDATE' | translate }}
                        :</span>
                    <label for="startDate" class="label mb-0 ms-2">
                        {{ 'ADMIN_STARTDATE' | translate }}
                    </label>
                    <input class="ms-2 form-control form-control-border" type="date" id="startDate"
                           [(ngModel)]="range.start" />
                    <label for="endDate" class="label mb-0 ms-2">
                        {{ 'ADMIN_ENDDATE' | translate }}
                    </label>
                    <input class="ms-2 form-control form-control-border" type="date" id="endDate"
                           [(ngModel)]="range.end" />
                    <button class="btn btn-success ms-2" type="button" [disabled]="!(range.start && range.end)"
                            (click)="applyFilter()">
                        {{ 'BTN_APPLY' | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="grid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid [title]="'NAVIGATION_ANALYTICS' | translate" [data]="dataGridService | async"
                   #grid
                   [selectable]="false" (excelExport)="onExcelExport($event)">
        <adm-data-grid-column [filterable]="false" field="cartId" [title]="'ADMIN_ANALYTICS_CARTID' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column field="planningId" [title]="'ADMIN_ANALYTICS_PLANNINGID' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.planningId ? ('ADMIN_ANALYTICS_LOGGED_IN_USER' | translate) : ('ADMIN_ANALYTICS_GUEST' | translate) }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [field]="field" [filter]="filter"
                                               [data]="userFilterObject"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column [filterable]="false" field="itemNumber"
                              [title]="'ADMIN_ANALYTICS_ITEMNUMBER' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column [filterable]="false" field="type" [title]="'ADMIN_ANALYTICS_TYPE' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column field="labelOne" [title]="'ADMIN_ANALYTICS_LABELONE' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column field="labelTwo" [title]="'ADMIN_ANALYTICS_LABELTWO' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column [filterable]="false" field="quantity" [title]="'ADMIN_ANALYTICS_QUANTITY' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.quantity }} {{ dataItem.unitOfQuantity }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="cartType" [title]="'ADMIN_ANALYTICS_CARTTYPE' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ analyticsItemCategory[dataItem.cartType] }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [field]="field" [filter]="filter" [data]="cartTypeFilterObject">
                </adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column [filterable]="false" field="itemRange" [title]="'ADMIN_ANALYTICS_ITEMRANGE' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ regionMap[dataItem.itemRange] | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column [filterable]="false" field="creationDate"
                              [title]="'ADMIN_ANALYTICS_CREATIONDATE' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.creationDate | date : 'mediumDate' }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-excel
            [fileName]="'OBO-Construct_Carts_' + range.start + '_' + range.end + '.xlsx'" [fetchData]="fetchData()">
            <adm-data-grid-excel-column field="cartId"
                                        title="{{ 'ADMIN_ANALYTICS_CARTID' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="planningId"
                                        title="{{ 'ADMIN_ANALYTICS_PLANNINGID' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="moduleName"
                                        title="{{ 'ADMIN_ANALYTICS_MODULENAME' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="itemRange"
                                        title="{{ 'ADMIN_ANALYTICS_ITEMRANGE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="itemNumber"
                                        title="{{ 'ADMIN_ANALYTICS_ITEMNUMBER' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="type"
                                        title="{{ 'ADMIN_ANALYTICS_TYPE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="labelOne"
                                        title="{{ 'ADMIN_ANALYTICS_LABELONE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="labelTwo"
                                        title="{{ 'ADMIN_ANALYTICS_LABELTWO' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="quantity"
                                        title="{{ 'ADMIN_ANALYTICS_QUANTITY' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="retailQuantity"
                                        title="{{ 'ADMIN_ANALYTICS_RETAIL_QUANTITY' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="unitOfQuantity"
                                        title="{{ 'ADMIN_ANALYTICS_UNIT_OF_QUANTITY' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="unitOfPrice"
                                        title="{{ 'ADMIN_ANALYTICS_UNIT_OF_PRICE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="price"
                                        title="{{ 'ADMIN_PRICE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="totalPrice"
                                        title="{{ 'ADMIN_ANALYTICS_TOTAL_PRICE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="cartType"
                                        title="{{ 'ADMIN_ANALYTICS_CARTTYPE' | translate }}"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column field="creationDate"
                                        title="{{ 'ADMIN_ANALYTICS_CREATIONDATE' | translate }}"></adm-data-grid-excel-column>
        </adm-data-grid-excel>
    </adm-data-grid>
</div>
