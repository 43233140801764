import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Constants } from '../../../constants';

@Directive({
    selector: '[formControlName][shdValidateNaming],[formControl][shdValidateNaming],[ngModel][shdValidateNaming]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ValidateNamingDirective),
            multi: true
        }
    ]
})
export class ValidateNamingDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (control && control.value) {
            const regexNaming = new RegExp(Constants.ValidationPattern.Naming);
            if (!regexNaming.test(control.value)) {
                return {
                    naming: true
                };
            }
        }
        return null;
    }
}
