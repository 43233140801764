import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InAppNavigationService {
    public isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onPreviousClick: BehaviorSubject<(() => any) | undefined> = new BehaviorSubject<(() => any) | undefined>(undefined);
    public onNextClick: BehaviorSubject<() => any> = new BehaviorSubject<() => any>(() => {});
    public nextButtonDisabledFn: BehaviorSubject<() => boolean> = new BehaviorSubject<() => boolean>(() => false);

    constructor() {}
}
