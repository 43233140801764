import { Directive, Host, Inject, forwardRef } from '@angular/core';
import { InlineEditComponent } from '../inlineEdit.component';

@Directive({
    selector: '[shdInlineEditInput]'
})
export class InlineEditInputDirective {
    constructor(
        @Host()
        @Inject(forwardRef(() => InlineEditComponent))
        private inlineEditComponent: InlineEditComponent
    ) {}
}
