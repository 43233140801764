import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { Logger } from '@obo-main/utils/logger/logger.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {
    constructor(private logger: Logger, private seo: SEOService, private translateService: TranslateService) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('CONTACT_META_TITLE'));
    }
}
