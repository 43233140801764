import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AccountService } from '@obo-account/account/account.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';
import { delay, filter, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-profile-completion-reminder',
    templateUrl: './profileCompletionReminder.component.html',
    styleUrls: ['./profileCompletionReminder.component.scss']
})
export class ProfileCompletionReminderComponent implements AfterViewInit {
    @ViewChild('tpl')
    public template: TemplateRef<any>;
    public isActive: boolean = false;

    public timeoutDelay = 100000; // timeout in ms after the toast will disappear automatically

    constructor(
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService,
        private accountService: AccountService,
        public stateService: StateService
    ) {}

    public ngAfterViewInit() {
        this.authService.onAuthenticationStatusChange
            .pipe(
                startWith(!this.authService.isInRole([Constants.Role.Guest])),
                delay(10000),
                filter((loggedIn) => loggedIn === true),
                switchMap(() => this.accountService.getAccount()),
                filter((profile) => !profile.isProfileComplete())
            )
            .subscribe(() => {
                if (!this.isActive) {
                    this.show();
                }
            });

        this.authService.onAuthenticationStatusChange
            .pipe(filter((isLoggedIn) => isLoggedIn === false))
            .subscribe(() => this.hide());
    }

    public show() {
        this.viewContainerRef.createEmbeddedView(this.template);
        this.isActive = true;
    }

    public hide() {
        this.viewContainerRef.clear();
        this.isActive = false;
    }
}
