import { ComponentRef, EmbeddedViewRef, Inject, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Subject, Subscription } from 'rxjs';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { LocationStrategy } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class OverlayService {
    private overlayRef: OverlayRef;
    private overlayConfig: OverlayConfig;
    private viewContainerRef: ViewContainerRef;
    public closed: Subject<any> = new Subject<any>();
    public dismissed: Subject<any> = new Subject<any>();
    private backdropClickSubscription: Subscription;

    constructor(
        @Inject(OVERLAY_S_CONFIG)
        private defaultConfig: OverlayConfig,
        private overlay: Overlay,
        private locationStrategy: LocationStrategy
    ) {}

    public init(viewContainerRef?: ViewContainerRef, overlayConfig?: OverlayConfig) {
        this.overlayConfig = overlayConfig ? overlayConfig : this.defaultConfig;
        this.viewContainerRef = viewContainerRef;
    }

    public open(overlayPortal: OverlayPortal, isBackdropClickable = true): OverlayPortalRef {
        let portal: ComponentPortal<any> | TemplatePortal<any>;
        if (overlayPortal instanceof TemplateRef) {
            portal = new TemplatePortal(overlayPortal, this.viewContainerRef);
        } else {
            portal = overlayPortal;
        }
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        } else {
            this.overlayRef = this.overlay.create(this.overlayConfig);
        }

        this.backdropClickSubscription = this.overlayRef.backdropClick().subscribe((event: MouseEvent) => {
            isBackdropClickable ? this.dismiss() : event.preventDefault();
        });

        this.locationStrategy.onPopState(() => {
            this.close();
        });

        return this.overlayRef.attach(portal);
    }

    public dismiss(): void {
        this.overlayRef.detach();
        this.dismissed.next(1);
        this.backdropClickSubscription.unsubscribe();
    }

    public close(params?: any): void {
        this.overlayRef.detach();
        this.closed.next(params);
        this.backdropClickSubscription.unsubscribe();
    }

    public get isShown(): boolean {
        return this.overlayRef && this.overlayRef.hasAttached();
    }
}

export type OverlayPortal =
    | TemplateRef<any>
    | ComponentPortal<any>
    | TemplatePortal<{ save: () => void; cancel: () => void; delete?: () => void; formGroup?: UntypedFormGroup }>;
export type OverlayPortalRef = ComponentRef<any> | EmbeddedViewRef<any>;
