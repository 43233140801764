<section id="displayError">
    <div class="row align-items-center">
        <div class="col-12 col-md-7">
            <div class="text-container">
                @if (showFallbackErrorText) {
                <div [innerHTML]="translateKey | translate"></div>
                } @else {
                <h1 class="m-0">{{ 'ERROR_HEADLINE' | translate }}</h1>
                <span [innerHTML]="'ERROR_MESSAGE_CONTACT' | translate | safeHTML"></span>
                <div class="d-flex">
                    <button class="btn btn-secondary me-4" [uiSref]="'portal'">
                        {{ 'STATIC_GO_BACK_TO_PORTAL' | translate }}
                    </button>
                    <button class="btn btn-success" [uiSref]="'contact'">
                        {{ 'SHD_GO_TO_CONTACT' | translate }}
                    </button>
                </div>
                }
            </div>
        </div>
        <div class="col-md-5 d-md-flex d-none justify-content-end">
            <img class="img-fluid" [attr.alt]="'ERROR_IMAGE' | translate" src="assets/img/gerd_error.png" />
        </div>
    </div>
</section>

@if (error && showErrorDetails) {
<div class="p-4">
    <shd-expandable-card [isExpanded]="false" #errorDetailCard>
        <ng-template shdExpandableCardHeader>
            <div class="w-100" (click)="errorDetailCard.toggle()">
                <h2>{{ 'ERROR_DETAILS' | translate }}</h2>
            </div>
        </ng-template>
        <ng-template shdExpandableCardContent>
            <div>id: {{ error.$id }}</div>
            <div>type: {{ error.type }}</div>
            <div>message: {{ error.message }}</div>
            @if (error.detail) {
            <div>detail: {{ error.detail | json }}</div>
            }
        </ng-template>
    </shd-expandable-card>
</div>
}
