import { Transition, TransitionService } from '@uirouter/core';

/**
 * redirects logged in users from login page
 * @param transitionService
 */
export function goBackFromOverviewHook(transitionService: TransitionService) {
    const criteria = {
        from: (state: any) => state.name.includes('Overview'),
        to: (state: any) => state.name.includes('FinishWizard')
    };

    const action = (transition: Transition) => {
        const $state = transition.router.stateService;
        const currentModule = getCurrentModuleName(transition);
        return $state.target(`${currentModule}Entry`);
    };
    // add the hook to transitionsservice
    transitionService.onBefore(criteria, action, { priority: 55 });
}

function getCurrentModuleName(transition: Transition): string {
    const transitionFrom = transition.from().name!;
    return transitionFrom.substring(0, transitionFrom.indexOf('Overview'));
}
