<form name="registerForm" id="register-form" (ngSubmit)="register(registerForm.value)" role="form" #registerForm="ngForm">
    <div class="mb-3 required">
        <label class="form-label" for="email">
            {{ 'REGISTERFORM_EMAIL' | translate }}
        </label>
        <input
            ngModel
            type="email"
            email
            name="email"
            id="email"
            tabindex="0"
            class="form-control form-control-border"
            required
            shdValidateInput
            [customErrorMsg]="'REGISTERFORM_EMAIL_ERROR' | translate" />
    </div>

    <div class="mb-3 required">
        <shd-single-select
            [(ngModel)]="selectedPortalRegion"
            [withBorder]="true"
            required
            shdValidateInput
            [ngModelOptions]="{ standalone: true }">
            <ng-template shdSingleSelectLabel>
                <span class="flag-icon flag-icon-{{ selectedPortalRegion?.name | lowercase }} mr-2"></span>
                <span>
                    {{ (selectedPortalRegion ? selectedPortalRegion.displayName : '-') | translate }}
                </span>
            </ng-template>
            @for (region of regionList; track region) {
            <ng-template shdSingleSelectOption [value]="region">
                <span class="flag-icon flag-icon-{{ region.name | lowercase }} mr-2"></span>
                <span>
                    {{ region.displayName | translate }}
                </span>
            </ng-template>
            }
        </shd-single-select>
    </div>

    <div class="mb-3 required">
        <label class="form-label" for="password">
            {{ 'REGISTERFORM_PASSWORD' | translate }}
        </label>
        <div class="position-relative">
            <input
                ngModel
                type="password"
                shdToggle
                name="password"
                id="password"
                tabindex="0"
                class="form-control form-control-border"
                [pattern]="passwordPattern"
                required
                shdValidateEqual="confirmPassword"
                reverse="true"
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDPATTERN_ERROR' | translate" />
        </div>
    </div>
    <div class="mb-3 required">
        <label class="form-label" for="confirmPassword">
            {{ 'REGISTERFORM_PASSWORDCONFIRM' | translate }}
        </label>
        <div class="position-relative">
            <input
                ngModel
                type="password"
                shdToggle
                name="confirmPassword"
                id="confirmPassword"
                tabindex="0"
                class="form-control form-control-border"
                shdValidateEqual="password"
                required
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDCONFIRM_ERROR' | translate" />
        </div>
    </div>
    <div class="mb-3 mt-5">
        <div class="form-check">
            <input
                type="checkbox"
                id="provideAdditionalInfo"
                #provideAdditionalInfo="ngModel"
                ngModel
                [ngModelOptions]="{ standalone: true }"
                name="provideAdditionalInfo"
                class="form-check-input" />
            <label class="form-check-label text-muted tos" for="provideAdditionalInfo">
                {{ 'REGISTERFORM_PROVIDEADDITIONALINFORMATION' | translate }}
                <shd-info-button [text]="'REGISTERFORM_PROVIDEADDITIONALINFORMATION_DESC' | translate"></shd-info-button>
            </label>
        </div>
    </div>
    @if (!!provideAdditionalInfo.value) {
    <div class="mb-4" @shrinkOutGrowIn>
        <div class="row">
            <div class="col-12 required">
                <label class="form-label me-2">
                    {{ 'PROFILE_USERTITLE' | translate }}
                </label>
                <shd-radio-group [(ngModel)]="title" name="title" (ngModelChange)="title = $event">
                    <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MR'">
                        {{ 'REGISTERFORM_USERTITLE_MR' | translate }}
                    </ng-template>
                    <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MRS'">
                        {{ 'REGISTERFORM_USERTITLE_MRS' | translate }}
                    </ng-template>
                </shd-radio-group>
            </div>
        </div>
        <div class="row">
            <div class="mb-3 required col-md-6">
                <label class="form-label" for="firstName">
                    {{ 'REGISTERFORM_FIRSTNAME' | translate }}
                </label>
                <input
                    ngModel
                    type="text"
                    name="firstName"
                    id="firstName"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
            <div class="mb-3 required col-md-6">
                <label class="form-label" for="lastName">
                    {{ 'REGISTERFORM_LASTNAME' | translate }}
                </label>
                <input
                    ngModel
                    type="text"
                    name="lastName"
                    id="lastName"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
        </div>
        <div class="row">
            <div class="mb-3 required col-md-8">
                <label class="form-label" for="street">
                    {{ 'REGISTERFORM_STREET' | translate }}
                </label>
                <input
                    ngModel
                    autocomplete="address-line1"
                    type="text"
                    name="street"
                    id="street"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
            <div class="mb-3 required col-md-4">
                <label class="form-label" for="houseNumber">
                    {{ 'SHARED_HOUSENUMBER' | translate }}
                </label>
                <input
                    ngModel
                    autocomplete="address-line2"
                    type="text"
                    name="houseNumber"
                    id="houseNumber"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
        </div>
        <div class="row">
            <div class="mb-3 required col-md-4">
                <label class="form-label" for="postalCode">
                    {{ 'REGISTERFORM_ZIPCODE' | translate }}
                </label>
                <input
                    ngModel
                    autocomplete="postal-code"
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
            <div class="mb-3 required col-md-8">
                <label class="form-label" for="city">
                    {{ 'SHARED_CITY' | translate }}
                </label>
                <input
                    ngModel
                    type="text"
                    autocomplete="address-level2"
                    name="city"
                    id="city"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput />
            </div>
        </div>
        <div class="mb-3 required">
            <label class="form-label" for="customergroup">
                {{ 'CUSTOMERGROUP' | translate }}
            </label>
            <shd-single-select [withBorder]="true" ngModel name="customerGroup" id="customerGroup" required shdValidateInput>
                <ng-template shdSingleSelectLabel>
                    {{ registerForm.value.customerGroup | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_TRADE'">
                    {{ 'CUSTOMERGROUP_TRADE' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTALLER'">
                    {{ 'CUSTOMERGROUP_INSTALLER' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_GENERALCONTRACTOR'">
                    {{ 'CUSTOMERGROUP_GENERALCONTRACTOR' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_ENDUSER'">
                    {{ 'CUSTOMERGROUP_ENDUSER' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_CONSULTANT'">
                    {{ 'CUSTOMERGROUP_CONSULTANT' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTITUTION'">
                    {{ 'CUSTOMERGROUP_INSTITUTION' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_MANUFACTURER'">
                    {{ 'CUSTOMERGROUP_MANUFACTURER' | translate }}
                </ng-template>
                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_VIP'">
                    {{ 'CUSTOMERGROUP_VIP' | translate }}
                </ng-template>
            </shd-single-select>
        </div>
        <div class="mb-3 required">
            <label class="form-label" for="company">
                {{ 'REGISTERFORM_COMPANY' | translate }}
            </label>
            <input
                ngModel
                autocomplete="organization"
                type="text"
                name="company"
                id="company"
                tabindex="0"
                class="form-control form-control-border"
                required
                shdValidateInput />
        </div>
        <div class="mb-3">
            <label class="form-label" for="phoneNumber">
                {{ 'REGISTERFORM_PHONE' | translate }}
            </label>
            <input
                ngModel
                autocomplete="tel"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                tabindex="0"
                class="form-control form-control-border" />
        </div>
    </div>
    }
    <div class="mb-3">
        <div class="form-check">
            <input type="checkbox" [ngModel]="false" name="isContactable" id="isContactable" class="form-check-input" />
            <label class="form-check-label text-muted" for="isContactable">
                {{ 'REGISTERFORM_ALLOWCONTACT' | translate }}
            </label>
        </div>
    </div>
    <div class="mb-3">
        <div class="form-check required">
            <input type="checkbox" id="acceptTos" ngModel name="acceptTos" class="form-check-input" required />
            <label class="form-check-label text-muted tos" for="acceptTos">
                <span [innerHTML]="tosAcceptText | safeHTML"></span>
                *
            </label>
        </div>
    </div>
    <shd-recaptcha [(valid)]="reCaptchaValid"></shd-recaptcha>
    <small class="form-text text-muted">
        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
    </small>
    <div class="my-3">
        <button
            type="submit"
            name="register-submit"
            id="register-submit"
            tabindex="0"
            class="btn btn-success"
            [disabled]="
                registerForm.invalid || !reCaptchaValid || (!!provideAdditionalInfo.value && title === '')
            ">
            {{ 'REGISTERFORM_BTN_REGISTER' | translate }}
        </button>
    </div>
</form>
