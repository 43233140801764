@if (isActive) {
<div id="in-app-navigation">
    <div class="container d-flex align-items-center justify-content-between h-100">
        @if (onPreviousClick) {
        <button id="in-app-navigation-back-btn" class="btn btn-step-back" (click)="onPreviousClick()">
            <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
            {{ 'BTN_GOBACK' | translate }}
        </button>
        } @else {
        <button class="btn btn-step-back" shdGoBack>
            <fa-icon [icon]="['fal', 'chevron-left']" size="lg"></fa-icon>
            {{ 'BTN_GOBACK' | translate }}
        </button>
        } @if (tourActive) {
        <button class="btn btn-tutorial-info" (click)="toggleTourRestart()">
            <fa-icon class="m-0" [icon]="['fas', 'info']" size="lg"></fa-icon>
        </button>
        }
        <button
            id="in-app-navigation-continue-btn"
            class="btn btn-success"
            (click)="onNextClick()"
            [disabled]="nextButtonDisabledFn()">
            {{ 'BTN_GOAHEAD' | translate }}
        </button>
    </div>
</div>
}
