import { ElbridgeInfo } from '@obo-main/services/elbridge/elbridge.models';
import { ElbridgeService } from '@obo-main/services/elbridge/elbridge.service';
import { Observable, UnaryFunction, identity } from 'rxjs';
import { RegionService } from '@obo-main/services/region/region.service';

export function resultSelector<TO, TI>(
    outerVal: TO,
    innerVal: TI,
    outerIdx: number,
    innerIdx: number
): { outerValue: TO; innerValue: TI; outerIndex: number; innerIndex: number } {
    return {
        outerValue: outerVal,
        innerValue: innerVal,
        outerIndex: outerIdx,
        innerIndex: innerIdx
    };
}

export function loadFileAsDataUrl(file: Blob): Observable<string> {
    return new Observable<string>((obs) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (loadEvent) => {
            if (!loadEvent.target || typeof fileReader.result !== 'string' || fileReader.result === 'data:') {
                obs.error('Error reading File');
            } else {
                obs.next(fileReader.result);
                obs.complete();
            }
        };
        fileReader.onerror = (err) => {
            obs.error(err);
        };
        fileReader.readAsDataURL(file);
    });
}

export function loadImage(data: any): Observable<HTMLImageElement> {
    return new Observable<HTMLImageElement>((obs) => {
        const image = new Image();
        image.onload = () => {
            obs.next(image);
            obs.complete();
        };
        image.onerror = (err: any) => {
            obs.error(err);
        };
        image.src = data;
    });
}

export function arrayFilterDistinct<T>(element: T, idx: number, all: T[]): boolean {
    return all.indexOf(element) === idx;
}

export function createPatchObject(
    path: string,
    value: string,
    op: string = 'replace'
): { op: string; path: string; value: string } {
    return {
        op,
        path,
        value
    };
}

export function roundTo(val: number, exp: number): number {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math.round(val);
    }

    let value: any = +val;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
}

export function ceilTo(val: number, exp: number = 0): number {
    return Math.ceil(val * Math.pow(10, exp)) / Math.pow(10, exp);
}

export function getElbridgeSessionInfoFromService(elbridgeService: ElbridgeService): ElbridgeInfo | undefined {
    return elbridgeService.validElbridgeSessionExists() ? elbridgeService.getInfo() : undefined;
}

export function isElbridgeEnabled(regionService: RegionService): boolean {
    return regionService.isElbridgeEnabled();
}

export function b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function getRouteLanguage(subLanguage: string) {
    return subLanguage?.split('-')[0];
}

export function distinctObjectArrayByProperty(array: any[], propertyKey: string) {
    return [...new Map(array.map((item) => [item[propertyKey], item])).values()];
}

export function moveItemInArray<T>(workArray: T[], fromIndex: number, toIndex: number): T[] {
    if (toIndex === fromIndex) {
        return workArray;
    }
    const target = workArray[fromIndex];
    const increment = toIndex < fromIndex ? -1 : 1;

    for (let k = fromIndex; k !== toIndex; k += increment) {
        workArray[k] = workArray[k + increment];
    }
    workArray[toIndex] = target;
    return workArray;
}

export function pipeFromArray<T, R>(fns: Array<UnaryFunction<T, R>>): UnaryFunction<T, R> {
    if (fns.length === 0) {
        return identity as UnaryFunction<any, any>;
    }

    if (fns.length === 1) {
        return fns[0];
    }

    return function piped(input: T): R {
        return fns.reduce((prev: any, fn: UnaryFunction<T, R>) => fn(prev), input as any);
    };
}

export function getEnumKeyByValue<T>(enumObj: T, value: T[keyof T]): string | undefined {
    return Object.keys(enumObj).find((key) => enumObj[key as keyof T] === value);
}
