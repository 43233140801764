import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarContainerComponent } from './sidebar-container.component';
import { SidebarComponent } from './sidebar.component/sidebar.component';
import { CloseSidebarDirective } from './close.directive';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { SidebarTabsComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabs.component';
import { SidebarTabContentComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabContent/sidebarTabContent.component';
import { SidebarTabComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTab/sidebarTab.component';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SidebarTabsService } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabs.service';

@NgModule({
    declarations: [
        SidebarContainerComponent,
        SidebarComponent,
        CloseSidebarDirective,
        SidebarTabsComponent,
        SidebarTabComponent,
        SidebarTabContentComponent
    ],
    imports: [
        CommonModule,
        SharedComponentsModule,
        SharedPipesModule,
        FontAwesomeModule,
        TranslateModule,
        SharedDirectivesModule
    ],
    exports: [
        SidebarContainerComponent,
        SidebarComponent,
        CloseSidebarDirective,
        SidebarTabComponent,
        SidebarTabContentComponent,
        SidebarTabsComponent
    ],
    providers: [SidebarTabsService]
})
export class SidebarModule {
    static forRoot(): ModuleWithProviders<SidebarModule> {
        return {
            ngModule: SidebarModule
        };
    }
}
