import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    SidebarServiceEvent,
    SidebarTabsService
} from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabs.service';

@Component({
    selector: 'lib-sidebar-tab',
    templateUrl: './sidebarTab.component.html',
    styleUrls: ['./sidebarTab.component.scss']
})
export class SidebarTabComponent implements OnInit {
    @Input()
    iconClass: string;
    @Input()
    tabIndex: number;
    @Input()
    public isActive: boolean = false;
    @Input()
    public showActiveFilter: boolean = false;
    @Output()
    tabClicked: EventEmitter<number> = new EventEmitter<number>();

    activeFilterCount: number;

    constructor(private tabsService: SidebarTabsService, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.showActiveFilter) {
            this.tabsService.registerTabEventByIndex(this.tabIndex).subscribe((tabEvent: SidebarServiceEvent) => {
                this.activeFilterCount = tabEvent.event?.activeFilterCount;
                this.changeDetectorRef.detectChanges();
            });
        }
    }
}
