<div class="quick-start-card card-grow" (click)="forwardIfAllowed(module)" [attr.data-track]="'enterConfigurator_' + module.name">
    <div
        class="quick-start-body card-background-image"
        [ngClass]="module.name"
        [ngStyle]="{ 'background-image': linearGradient + 'url(/assets/img/dashboard/' + module.name + '.jpg)' }">
        <div class="quick-start-header">
            <div class="module-icon">
                <shd-module-icon [moduleName]="module.name" [color]="'secondary-color'"></shd-module-icon>
            </div>
        </div>
        <div class="quick-start-footer">
            <h3 class="quick-start-footer-text">
                {{ 'BUILDING_MODULES_' + module.name | uppercase | translate }}
            </h3>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
