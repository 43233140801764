import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-sidebar-tab-content',
    templateUrl: './sidebarTabContent.component.html',
    styleUrls: ['./sidebarTabContent.component.scss']
})
export class SidebarTabContentComponent {
    @Input() title: string;
    @Input()
    public isActive: boolean = false;
    @Input()
    public tabIndex: number;
}
