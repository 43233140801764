import { NgModule } from '@angular/core';
import { UploadImageComponent } from '@obo-common/uploadImage/uploadImage.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { CommonModule } from '@angular/common';
import { SidebarModule } from '@obo-libraries/ng-sidebar';

@NgModule({
    imports: [FontAwesomeModule, ImageCropperModule, TranslateModule, SharedComponentsModule, CommonModule, SidebarModule],
    declarations: [UploadImageComponent],
    exports: [UploadImageComponent]
})
export class UploadImageModule {}
