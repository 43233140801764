<form name="loginForm" id="login-form" (ngSubmit)="resetPassword()" role="form" #adminConfirmForm="ngForm">
    <div class="mb-3 required">
        <label class="form-label" for="password">
            {{ 'REGISTERFORM_PASSWORD' | translate }}
        </label>
        <div class="position-relative">
            <input
                [(ngModel)]="data.password"
                type="password"
                shdToggle
                name="password"
                id="password"
                tabindex="0"
                class="form-control form-control-border"
                pattern="{{ passwordPattern }}"
                required
                shdValidateEqual="confirmPassword"
                reverse="true"
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDPATTERN_ERROR' | translate" />
        </div>
    </div>
    <div class="mb-3 required">
        <label class="form-label" for="confirmPassword">
            {{ 'REGISTERFORM_PASSWORDCONFIRM' | translate }}
        </label>
        <div class="position-relative">
            <input
                [(ngModel)]="data.passwordConfirmed"
                type="password"
                shdToggle
                name="confirmPassword"
                id="confirmPassword"
                tabindex="0"
                class="form-control form-control-border"
                shdValidateEqual="password"
                required
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDCONFIRM_ERROR' | translate" />
        </div>
    </div>
    <small class="form-text text-muted">
        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
    </small>
    <div class="mb-3">
        <button
            class="btn btn-success"
            [disabled]="adminConfirmForm.invalid"
            type="submit"
            name="submit"
            id="submit"
            tabindex="0">
            {{ 'REGISTERFORM_BTN_RESETPASSWORD' | translate }}
        </button>
    </div>
</form>
