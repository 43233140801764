import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { NG_MAPS_PLACES_DEFAULT_CONFIGURATION } from '@ng-maps/places';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SidebarModule } from '@obo-libraries/ng-sidebar';
import { LocalStorageFactory, SessionStorageFactory } from '@obo-main/factories/localStorage.factory';
import { LocaleFactory } from '@obo-main/factories/locale.factory';
import { OrderedInitializerFactory } from '@obo-main/factories/orderedInitializer.factory';
import { WindowFactory } from '@obo-main/factories/window.factory';
import { MainModule } from '@obo-main/main.module';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { LanguageService } from '@obo-main/services/language/language.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { ServicesModule } from '@obo-main/services/services.module';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { ErrorInterceptor } from '@obo-main/utils/httpInterceptors/errorInterceptor';
import { LanguageInterceptor } from '@obo-main/utils/httpInterceptors/languageInterceptor';
import { NoCacheInterceptor } from '@obo-main/utils/httpInterceptors/noCacheInterceptor';
import { RegionInterceptor } from '@obo-main/utils/httpInterceptors/regionInterceptor';
import { TokenInterceptor } from '@obo-main/utils/httpInterceptors/tokenInterceptor';
import { LocalStorage, SessionStorage } from '@obo-main/utils/localstorage.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { TranslateHttpLoader } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.service';
import { Utils } from '@obo-main/utils/utils.service';
import { Window } from '@obo-main/utils/window.service';
import { UIRouterModule, UIView } from '@uirouter/angular';
import { UIRouter, UrlService } from '@uirouter/core';
import { APP_STATES } from 'app/app.states';
import { Constants } from 'app/constants';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { faImports } from 'polyfills/fontAwesomeImports';
import { secrets } from 'secrets/secrets';
import { HttpPatchInterceptor } from '@obo-main/utils/httpInterceptors/httpPatchInterceptor';
import { Environment } from '@obo-common/shared/models/environment.enum';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        //Librarys
        CommonModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot(),
        UIRouterModule.forRoot({
            states: APP_STATES,
            useHash: false,
            otherwise: { state: 'landing' },
            initial: { state: 'portal' },
            deferIntercept: true,
            deferInitialRender: true
            //uirouterConfigFactory is called via OrderedInitializerFactory!
        }),
        NgbModule,
        SidebarModule.forRoot(),

        // self created Modules
        MainModule,
        ServicesModule.forRoot(),
        NgxPageScrollCoreModule.forRoot(),
        NgxPageScrollModule,
        LazyLoadImageModule,
        NgMapsGoogleModule
    ],
    providers: [
        {
            provide: GOOGLE_MAPS_API_CONFIG,
            useValue: {
                apiKey: secrets.googleCloud,
                libraries: ['places', 'elevation']
            }
        },
        {
            provide: NG_MAPS_PLACES_DEFAULT_CONFIGURATION,
            useFactory: () => NG_MAPS_PLACES_DEFAULT_CONFIGURATION
        },
        {
            provide: 'BASE_URL',
            deps: [AppSettings],
            useFactory: getBaseUrl
        },
        {
            provide: Constants,
            useValue: Constants
        },
        {
            provide: LocalStorage,
            useFactory: LocalStorageFactory
        },
        {
            provide: SessionStorage,
            useFactory: SessionStorageFactory
        },
        {
            provide: Window,
            useFactory: WindowFactory
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: secrets.recaptcha }
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: (locale: string) => locale,
            deps: [LOCALE_ID]
        },
        AppSettings,
        Logger,
        TranslateHttpLoader,
        {
            // manages the application bootstrapping process. Ensures that our AppSettings are loaded before all other Services do their stuff ;)
            provide: APP_INITIALIZER,
            useFactory: OrderedInitializerFactory,
            deps: [
                RegionService,
                LanguageService,
                AuthService,
                Logger,
                TranslateService,
                HttpClient,
                AppSettings,
                TranslateHttpLoader,
                UIRouter,
                Injector,
                LocalStorage
            ],
            multi: true
        },
        Utils,
        {
            provide: 'BUILD_INFO',
            deps: [],
            useFactory: getBuildInfos
        },
        {
            provide: 'DEBUG',
            deps: [UrlService],
            useFactory: getDebugState
        },
        {
            provide: 'ENABLEREGISTER',
            deps: [UrlService],
            useFactory: getEnableRegisterState
        },
        {
            provide: 'INITIALAPPLANGUAGE',
            deps: [UrlService],
            useFactory: getInitialAppLanguage
        },
        {
            provide: 'ENVIRONMENT',
            deps: [AppSettings],
            useFactory: getEnvironment
        },
        {
            provide: 'APIPREFIX',
            deps: [AppSettings],
            useFactory: getAPIPrefix
        },
        {
            provide: 'FALLBACKLANGUAGE',
            deps: [AppSettings],
            useFactory: getFallbackLanguage
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: LocaleFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RegionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpPatchInterceptor, multi: true }
    ],
    bootstrap: [UIView]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(...faImports);
    }
}

export function getBaseUrl(appSettings: AppSettings) {
    return appSettings.getItem('settings.baseUrl');
}

export function getBuildInfos(): any {
    return require('../../package.json').version;
}

export function getDebugState(urlService: UrlService): boolean {
    const debugFlag = urlService.search().debug;
    return debugFlag === '1';
}

export function getEnableRegisterState(urlService: UrlService): boolean {
    const enableRegisterFlag = urlService.search().enableregister;
    return enableRegisterFlag === '1';
}

export function getInitialAppLanguage(urlService: UrlService): string {
    return urlService.search().lang ?? '';
}

export function getAPIPrefix(appSettings: AppSettings): string {
    return appSettings.getItem('settings.apiPrefix');
}

export function getFallbackLanguage(appSettings: AppSettings): string {
    return appSettings.getItem('settings.fallbackLanguage');
}

export function getEnvironment(appSettings: AppSettings): Environment {
    return appSettings.getItem('settings.environment');
}
