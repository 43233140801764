<ng-template #tpl>
    <ngb-toast (hide)="hide()" [delay]="timeoutDelay" class="mt-3">
        <ng-template ngbToastHeader>
            <div>
                <fa-icon class="me-2" [icon]="['fal', 'id-card']"></fa-icon>
                {{ 'MAIN_COMPLETEPROFILE_TITLE' | translate }}
            </div>
        </ng-template>
        <p>
            {{ 'MAIN_COMPLETEPROFILE_CONTENT' | translate }}
        </p>
        <a class="btn btn-more-details-small" uiSref="accountOverview" (click)="hide()">
            <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
            {{ 'MAIN_COMPLETEPROFILE_LINKTEXT' | translate }}
        </a>
    </ngb-toast>
</ng-template>
