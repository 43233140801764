import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private appSettings: AppSettings, private authService: AuthService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appSettings.init) {
            let token = this.authService.getAccessToken();
            //apply bearer
            let newRequest = token
                ? request.clone({
                      headers: request.headers.set('Authorization', `Bearer ${token}`)
                  })
                : request;
            return next.handle(newRequest);
        }
        return next.handle(request);
    }
}
