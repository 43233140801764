import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[shdAutoFocus]',
    exportAs: 'autoFocusDirective'
})
export class AutoFocusDirective implements OnInit {
    @Input()
    public andSelect: boolean = true;

    constructor(private element: ElementRef) {}

    public ngOnInit(): void {
        this.element.nativeElement.focus();
        if (this.andSelect) {
            this.element.nativeElement.select();
        }
    }
}
