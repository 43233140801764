import { Directive, forwardRef, HostBinding, HostListener, Inject } from '@angular/core';
import { ExpandableCardComponent } from '@obo-common/shared/components/expandableCard/expandableCard.component';

@Directive({
    selector: '[shdExpandableCardToggleButton]'
})
export class ExpandableCardToggleButtonDirective {

    constructor(
        @Inject(forwardRef(() => ExpandableCardComponent))
        public readonly expandableCard: ExpandableCardComponent
    ) {}

    @HostBinding('class')
    public class = 'expandable-card-toggle-button';
    @HostBinding('class.expanded')
    public expanded: boolean = this.expandableCard.isExpanded;

    @HostListener('click')
    public togglePanel(): void {
        this.expandableCard.toggle();
        this.expanded = this.expandableCard.isExpanded;
    }
}
