import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { SingleSelectOptionDirective } from '@obo-common/shared/components/singleSelect/singleSelectOption.directive';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SingleSelectLabelDirective } from '@obo-common/shared/components/singleSelect/singleSelectLabel.directive';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from '@obo-main/utils/utils.service';

let singleSelectId: number = 0;

@Component({
    selector: 'shd-single-select',
    templateUrl: './singleSelect.component.html',
    styleUrls: ['./singleSelect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SingleSelectComponent
        }
    ]
})
export class SingleSelectComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    public placeHolder: string;
    @Input()
    public placement: 'top-left' | 'bottom-left' = 'bottom-left';
    @Input()
    public display: 'dynamic' | 'static' = 'static';
    @Input()
    public container: null | 'body' = null;
    @Input()
    public withBorder: boolean;
    @Input()
    public grouped: boolean;
    @Input()
    public disabled: boolean;
    @Output()
    public optionSelected: EventEmitter<any> = new EventEmitter<any>();
    @ContentChild(SingleSelectLabelDirective)
    public labelTpl: SingleSelectLabelDirective;
    @ContentChildren(SingleSelectOptionDirective)
    public optionTemplates: QueryList<SingleSelectOptionDirective>;
    public id: string = `singleSelect_${singleSelectId++}`;
    @ViewChild(NgbDropdown)
    public dropdown: NgbDropdown;
    public selectedOptionTpl: SingleSelectOptionDirective;
    public groupedOptions: Array<{ key: string; values: Array<SingleSelectOptionDirective> }>;
    public selectedValue: any;

    constructor(private utils: Utils, private cdr: ChangeDetectorRef) {}

    onChange = (value: any) => {};

    onTouched = () => {};

    touched = false;

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    selectOption(option?: SingleSelectOptionDirective) {
        this.selectedOptionTpl = option;
        this.onChange(option?.value);
        this.writeValue(option?.value);
        if (option) {
            option.selected.emit();
        }
        this.dropdown.close();
    }

    onOpenChange(open: boolean): void {
        if (!open) {
            this.markAsTouched();
            if (this.selectedValue == undefined) {
                this.selectOption(undefined);
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.selectedValue != undefined) {
            const singleSelectOptionTemplate = this.optionTemplates.find((x) => x.value === this.selectedValue);
            if (singleSelectOptionTemplate) {
                this.selectedOptionTpl = singleSelectOptionTemplate;
            }
        }

        if (this.grouped) {
            this.initGroupedOptions();
        }
    }

    private initGroupedOptions(): void {
        this.groupedOptions = this.utils.groupBy('groupName', Array.from(this.optionTemplates));
        this.cdr.detectChanges();
    }
}
