export class Alert {
    public title?: string;
    public text: string;
    public type: AlertType;
}

export enum AlertType {
    Success = 'success',
    Warning = 'warning',
    Info = 'info',
    Error = 'danger'
}
