import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Module } from '@obo-main/services/modules/module.models';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';

@Component({
    selector: 'app-quick-start-card',
    templateUrl: './quick-start-card.component.html',
    styleUrls: ['./quick-start-card.component.scss']
})
export class QuickStartCardComponent {
    @Input()
    public module: Module;
    @Input()
    public isMobile: boolean = false;
    @Output()
    public configuratorNonMobile: EventEmitter<any> = new EventEmitter();

    public linearGradient = 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0, 0, 0, 0.7)),';

    constructor(private stateService: StateService) {}

    public forwardIfAllowed(module: Module): void {
        if (this.isMobile && Constants.DesktopOnlyModules.some((m) => m === module.id)) {
            this.configuratorNonMobile.emit();
        } else {
            this.stateService.go(module.name);
        }
    }
}
