import { Injectable } from '@angular/core';
import { StateHistoryEntry } from './stateHistory.model';

@Injectable()
export class StateHistoryService {
    private _stateHistory: StateHistoryEntry[] = [];

    public addPreviousStateToHistory(stateName: any, stateParams?: any): void {
        this._stateHistory.push({ state: stateName, stateParams: stateParams });
    }

    public getPreviousStateFromHistory(): StateHistoryEntry | undefined {
        return this._stateHistory.pop();
    }

    constructor() {}
}
