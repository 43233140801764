import { Pipe, PipeTransform } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Constants } from 'app/constants';
import { BreakpointService } from '../services/breakpoint.service';

@Pipe({
    name: 'responsiveValue'
})
export class ResponsiveValuePipe implements PipeTransform {
    constructor(public breakPointService: BreakpointService) {}

    public transform(value: {
        [key in Constants.ResponsiveBreakpoint]: any;
    }): Observable<string> {
        return fromEvent(window, 'resize').pipe(
            startWith(null),
            map(() => {
                const windowWidth = window.innerWidth;
                const breakpoints = this.breakPointService.mediaBreakpoints;

                const returnBreakpoint = Object.entries(breakpoints).reduce((result, [k, v]) => {
                    if (v <= windowWidth && value[k as Constants.ResponsiveBreakpoint] != null) {
                        return k;
                    } else {
                        return result;
                    }
                }, 'xs');

                return value[returnBreakpoint as Constants.ResponsiveBreakpoint];
            })
        );
    }
}
