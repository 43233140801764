import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Font, FontMapping, Language, LanguagesModuleCacheItem } from '@obo-main/services/language/language.models';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Constants } from 'app/constants';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class LanguageService {
    public applicationLanguage: Language = this.setInitialLanguage();
    public currentlyAvailableLanguages: Language[] = new Array<Language>();
    public onAvailableLanguagesChange: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([]);

    private availableLanguagesCache: LanguagesModuleCacheItem[] = new Array<LanguagesModuleCacheItem>();

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private logger: Logger,
        private translateService: TranslateService,
        @Inject(LocalStorage) private localStorage: Storage
    ) {
        this.onAvailableLanguagesChange.subscribe(
            (languages) => {
                const isSelectedLanguageAvailable = languages.some((l) => l.name === this.translateService.currentLang);
                const isDisplayedLanguageAvailable = languages.some((l) => l.name === this.applicationLanguage.name);
                if (this.applicationLanguage && Array.isArray(languages) && languages.length > 0) {
                    if (this.applicationLanguage.name === this.translateService.currentLang && !isSelectedLanguageAvailable) {
                        // case user enters untranslated module
                        this.applicationLanguage = languages.find((l) => l.isFallback) || this.setInitialLanguage();
                    } else if (
                        this.applicationLanguage.name !== this.translateService.currentLang &&
                        this.applicationLanguage.id !== -1
                    ) {
                        // case user went to untranslated module and now enters a translated module again.
                        if (isSelectedLanguageAvailable) {
                            this.applicationLanguage = this.findLanguageByCode(this.translateService.currentLang)!;
                        } else {
                            this.applicationLanguage = languages.find((l) => l.isFallback) || this.setInitialLanguage();
                        }
                    } else if (this.applicationLanguage.id === -1) {
                        // selected language is unknown language with browser language integrated (getUnknownLanguage called with parameter)
                        if (isSelectedLanguageAvailable) {
                            this.applicationLanguage = this.findLanguageByCode(this.translateService.currentLang)!;
                        } else {
                            this.applicationLanguage = languages.find((l) => l.isFallback) || this.setInitialLanguage();
                        }
                    }
                }
            },
            (error) => {
                this.logger.error(`something went wrong while processing onAvailableLanguagesChange. Received: ${error}`);
            }
        );

        this.translateService.onLangChange.subscribe((evt: LangChangeEvent) => {
            this.applicationLanguage =
                this.currentlyAvailableLanguages.find((l) => l.name === evt.lang) || this.setInitialLanguage();
        });
    }

    /**
     * Returns an Observable containing the fallback language of the module with the given Id
     * @param moduleId Id of the module
     */
    public findFallbackLanguage(moduleId: number): Observable<Language> {
        return this.getAvailableLanguagesForModuleById(moduleId).pipe(
            mergeMap((result: Language[]) => {
                const fallbackLanguage = result.find((l) => l.isFallback);
                return fallbackLanguage !== undefined
                    ? of(fallbackLanguage!).pipe(
                          tap(() => this.logger.debug(`Fallback Language for module ${moduleId}:`, fallbackLanguage))
                      )
                    : throwError(`Fallback Language for module ${moduleId} could not be determined`);
            })
        );
    }

    /**
     * Returns a Observable containing an array of languages available for the requested moduleId
     * @param moduleId Id of the module to get availablelanguages for
     */
    public getAvailableLanguagesForModuleById(moduleId: number): Observable<Language[]> {
        this.logger.debug(`Getting Languages for module ${moduleId}`);
        const resultFromCache = this.availableLanguagesCache.find((x) => x.id === moduleId);
        return resultFromCache !== undefined
            ? of(resultFromCache.languages).pipe(
                  tap((result) => {
                      this.logger.debug(`Found Languages for module ${moduleId} in Cache:`, result);
                      this.currentlyAvailableLanguages = result;
                  })
              )
            : this.getLanguagesForModuleById(moduleId).pipe(
                  mergeMap((languages) => {
                      this.logger.debug(`Received Languages for module ${moduleId} from Api:`, languages);
                      const newItem: LanguagesModuleCacheItem = {
                          id: moduleId,
                          languages: languages
                      };
                      this.availableLanguagesCache.push(newItem);
                      this.currentlyAvailableLanguages = newItem.languages;
                      return of(newItem.languages);
                  })
              );
    }

    public findLanguageByCode(langCode: string) {
        return this.currentlyAvailableLanguages.find((l) => l.name === langCode);
    }

    /**
     * Returns a Observable containing the language when it is available for the module
     * @param moduleId id of the module to check
     * @param languageId id of the language which availability shall be checked
     */
    public isLanguageAvailable(moduleId: number, languageId: number): Observable<boolean> {
        return this.getAvailableLanguagesForModuleById(moduleId).pipe(
            mergeMap((availableLanguages: Language[]) => of(availableLanguages.find((l) => l.id === languageId) !== undefined))
        );
    }

    /**
     * returns the class of the font to be set on body for the given languageCode.
     * If no font is found, if till return the fallbackFont
     * @param langCode
     */
    public findFontForLanguage(langCode: string): string {
        const fonts = this.appSettings.getItem('fonts');
        const fontMappings = this.appSettings.getItem('fontMapping');
        if (!fonts || !Array.isArray(fonts)) {
            throw new Error('No Fonts defined in env.json');
        }
        const font = (fontMappings as FontMapping[]).find((fm) => langCode.includes(fm.langCode));
        if (!font) {
            this.logger.info(`No Font specified for language ${langCode}. Using default Font`);
            return this.findFallbackFontFamily();
        } else {
            const fontFamily = (fonts as Font[]).find((f) => f.id === font.fontId);
            if (!fontFamily) {
                this.logger.error(`No font Found for Id ${font.fontId}`);
                return this.findFallbackFontFamily();
            } else {
                const fontName = fontFamily.name;
                this.logger.debug(`Using ${fontName} for language: langCode`);
                return fontName;
            }
        }
    }

    public setLanguage(language: Language) {
        this.localStorage.setItem(Constants.storageTokens.language, language.name);
        this.translateService.use(language.name);
        this.applicationLanguage = language;
    }

    /**
     * returns the fontFamily entry of the fallback font
     */
    private findFallbackFontFamily(): string {
        const fonts = this.appSettings.getItem('fonts');
        if (!fonts || !Array.isArray(fonts)) {
            throw new Error('No Fonts defined in env.json');
        }
        const fallbackFont = (fonts as Font[]).find((f) => f.isFallback);
        if (!fallbackFont) {
            throw new Error('No Fallback Font defined');
        } else {
            return fallbackFont.name;
        }
    }

    private getLanguagesForModuleById(moduleId: number): Observable<Language[]> {
        return this.http.get(`${this.appSettings.getItem('settings.apiPrefix')}Cultures`, {
            params: new HttpParams().set('moduleId', moduleId.toString())
        }) as Observable<Language[]>;
    }

    private setInitialLanguage(): Language {
        return {
            id: -1,
            name: 'Default',
            displayName: 'DefaultLanguage',
            isFallback: false,
            moduleId: -1
        };
    }

    public initialize(): Promise<boolean> {
        return Promise.resolve(true);
    }
}
