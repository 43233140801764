import { Environment } from '@obo-common/shared/models/environment.enum';

const BASE_URL = 'https://obo-construct.cloud.lise.de';
const API_PREFIX = `${BASE_URL}/api/`;

export const environment = {
    production: false,
    settings: {
        baseUrl: BASE_URL,
        apiPrefix: API_PREFIX,
        fallbackLanguage: 'en',
        documentUrlPrefix: `${API_PREFIX}documents/`,
        appVersionUrl: `${BASE_URL}/version.json`,
        downloadUrl: {
            kts: {
                2019: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2019-3.29.4.msi`,
                2020: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2020-3.30.7.msi`,
                2021: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2021-3.30.7.msi`,
                2022: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2022-3.30.7.msi`,
                2023: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2023 3.30.10.msi`,
                2024: `${API_PREFIX}downloads/constructcad/3.0/OBO-Construct-2024 3.30.11.msi`
            },
            cablefilling: `${API_PREFIX}downloads/cablefilling/3.063/OBOConstructCablefillingSetup_v3.063_{lang}.zip`
        },
        logger_level: 5,
        show_buildInfo: true,
        enable_registration: false,
        environment: Environment.STAGING,
        enable_hotjar: true,
        enable_tours: true
    },
    modules: [
        {
            id: 0,
            name: 'main',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 1,
            name: 'admin',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 2,
            name: 'portal',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 443968,
            name: 'grounding',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 444497,
            name: 'underfloor',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 3,
            name: 'static',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 5,
            name: 'shared',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 8,
            name: 'legal',
            isActive: true,
            hasPlanning: false
        },
        {
            id: 453783,
            name: 'incendiary',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 468732,
            name: 'filling',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 443696,
            name: 'overVoltage',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 443837,
            name: 'photovoltaic',
            isActive: true,
            hasPlanning: true
        },
        {
            id: 437445,
            name: 'egress',
            isActive: true,
            hasPlanning: true
        }
    ],
    fonts: [
        {
            id: 1,
            name: 'default',
            isFallback: true,
            fontFamily: 'DIN Next Rounded LT W04'
        },
        {
            id: 2,
            name: 'cyrillic',
            isFallback: false,
            fontFamily: 'DIN Next W10'
        }
    ],
    fontMapping: [
        {
            langCode: 'ru',
            fontId: 2
        }
    ]
};
