export class TranslationItemDto {
    key: string;
    moduleId: number;
    modificationDate: string;
    texts: Array<{ cultureName: string; text?: string }>;
}

export class TranslationItem {
    key: string;
    moduleId: number;
    modificationDate: string;
}
