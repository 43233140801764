import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CableCategoryService } from '@obo-common/shared/services/cableCategory.service';
import { Observable } from 'rxjs';
import { CableCategory } from '@obo-common/shared/models/cable.models';
import { concatAll, first } from 'rxjs/operators';

@Pipe({ name: 'cableCategory' })
@Injectable()
/**
 * Returns the CableCategory for the given CableCategoryId
 */
export class CableCategoryPipe implements PipeTransform {
    constructor(private cableCategoryService: CableCategoryService) {}

    public transform(value: number | string): Observable<CableCategory | undefined> {
        return this.cableCategoryService.getCableCategories().pipe(
            concatAll(),
            first((cableCat: CableCategory) => cableCat.id == value, undefined)
        );
    }
}
