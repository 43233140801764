<div ngbDropdown [display]="'static'" autoClose="outside">
    <button
        class="form-control"
        [ngClass]="{ 'form-control-border': withBorder }"
        type="button"
        [id]="id"
        ngbDropdownToggle
        [disabled]="filter.disabled">
        <span>
            {{ filter.label }}
            @if (showSelectedValue && filter.selectedOption) {
                <span>
                -
                <b>{{ filter.selectedOption.label }}</b>
            </span>
            }
        </span>
        <fa-icon [icon]="['fal', 'angle-down']" size="2x"></fa-icon>
    </button>
    <form ngbDropdownMenu [attr.aria-labelledby]="id">
        <div class="items">
            @if (filter.value) {
                <input class="hide-input dropdown-radio" type="radio" [id]="'resetRadio-'+ id"
                       (click)="filter.reset()" />
                <label class="item" [for]="'resetRadio-'+ id">
                    {{ resetBtnText }}
                </label>
            }
            @for (option of filter.options; track option; let i = $index) {
                <input class="hide-input" [formControl]="filter" [value]="option" [attr.id]="id+'-'+i" type="radio" />
                <label class="item" [attr.for]="id+'-'+i">
                    @if (filterOptionTpl) {
                        <ng-template
                            [ngTemplateOutlet]="filterOptionTpl.templateRef"
                            [ngTemplateOutletContext]="{
                        $implicit: option
                    }"></ng-template>
                    } @else {
                        {{ option.label }}
                    }
                </label>
            }
        </div>
    </form>
</div>
