<div class="building-card" (click)="selectedChange.emit()" [ngClass]="{ selected: selected }">
    <div class="building-card-header">
        <h3 class="m-0">{{ building.name }}</h3>
        <div class="d-none d-lg-block">
            <shd-contextmenu [overflowHidden]="true" size="lg">
                <button class="btn btn-contextmenu" (click)="duplicateBuilding()">
                    <fa-icon class="me-2" [icon]="['fal', 'copy']"></fa-icon>
                    {{ 'BTN_COPY' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="editBuilding()">
                    <fa-icon class="me-2" [icon]="['fal', 'pencil']"></fa-icon>
                    {{ 'BTN_EDIT' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="confirmDeleteModal.show()">
                    <fa-icon class="me-2" [icon]="['fal', 'trash-alt']"></fa-icon>
                    {{ 'BTN_DELETE' | translate }}
                </button>
            </shd-contextmenu>
        </div>
    </div>
    <div class="building-location d-none d-lg-block">
        @if (building.city) {
        <span>
            <fa-icon [icon]="['fal', 'location-dot']"></fa-icon>
            {{ building | addressLine }}
        </span>
        }
    </div>
</div>

<shd-confirm-modal
    #confirmDeleteModal
    [title]="'BTN_DELETE' | translate"
    [question]="'BUILDING_LABEL_DELETECONFIRM' | translate"
    (confirmed)="deleteBuilding()"></shd-confirm-modal>
