import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList
} from '@angular/core';
import { SidebarTabComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTab/sidebarTab.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lib-sidebar-tabs',
    templateUrl: './sidebarTabs.component.html',
    styleUrls: ['./sidebarTabs.component.scss']
})
export class SidebarTabsComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() position: 'start' | 'end' | 'left' | 'right' | 'top' | 'bottom' = 'start';
    @Output() toggleClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() tabClicked: EventEmitter<number> = new EventEmitter<number>();
    @ContentChildren(SidebarTabComponent) public tabs: QueryList<SidebarTabComponent>;
    public sidebarOpened: boolean = true;
    public onDestroy = new Subject<any>();
    public iconClass: string;
    private isMobile: boolean = false;

    constructor(private breakpointObserver: BreakpointObserver) {}

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(min-width: 1200px)'])
            .pipe(takeUntil(this.onDestroy))
            .subscribe((state: BreakpointState) => {
                state.matches ? (this.isMobile = false) : (this.isMobile = true);
            });

        if (this.isMobile) {
            this.iconClass = 'arrow-down';
        } else {
            this.iconClass = 'arrows-left-right';
        }
    }

    /*
    initialize tab functionality if its activated by parameter
     */
    ngAfterContentInit(): void {
        this.tabs.forEach((tab) => {
            tab.tabClicked.subscribe((index) => {
                tab.isActive = true;
                this.deactivateOtherTabs(index);
                this.tabClicked.emit(index);
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    /*
    deactivate other tabs when its index is not the current one
     */
    private deactivateOtherTabs(index: number) {
        this.tabs.forEach((tab) => {
            if (tab.tabIndex != index) {
                tab.isActive = false;
            }
        });
    }
}
