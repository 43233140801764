import { TransitionService } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { from, timer } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

export function preLoadStatesHook(transitionService: TransitionService) {
    const deregisterPreloadLazyLoadedRouteHook = transitionService.onSuccess({}, (transition) => {
        from(transition.router.stateRegistry.get())
            .pipe(
                filter((state: Ng2StateDeclaration) => !!state.loadChildren && state.data.preLoad),
                concatMap((state) => timer(5000).pipe(map(() => state)))
            )
            .subscribe((state: Ng2StateDeclaration) => {
                // JiT
                if (typeof state.loadChildren === 'function') {
                    state.lazyLoad = (t, s) => (state.loadChildren as Function)() as Promise<any>;
                    transition.router.stateService.lazyLoad(state);
                }
                // AoT
                else if (typeof state.loadChildren === 'string') {
                    state.lazyLoad = (t, s) => import(state.loadChildren as unknown as string).then((x) => x.default);
                    transition.router.stateService.lazyLoad(state);
                }
            });
        deregisterPreloadLazyLoadedRouteHook();
    });
}
