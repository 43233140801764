import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { ContactComponent } from '@obo-static/contact/contact.component';
import { ContactFormComponent } from '@obo-static/contact/contactForm/contactForm.component';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbModule,
        FormsModule,
        UIRouterModule.forChild(),
        SharedDirectivesModule,
        SharedComponentsModule,
        FontAwesomeModule
    ],
    declarations: [ContactComponent, ContactFormComponent],
    exports: [ContactComponent, ContactFormComponent]
})
export class ContactModule {}
