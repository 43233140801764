import { GuidedTourService } from './guided-tour.service';
import { GuidedTourComponent } from './guided-tour.component';
import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowRefService } from './windowref.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';

@NgModule({
    declarations: [GuidedTourComponent],
    imports: [CommonModule, FontAwesomeModule, TranslateModule, SharedComponentsModule],
    providers: [WindowRefService],
    exports: [GuidedTourComponent]
})
export class GuidedTourModule {
    public static forRoot(): ModuleWithProviders<GuidedTourModule> {
        return {
            ngModule: GuidedTourModule,
            providers: [ErrorHandler, GuidedTourService]
        };
    }
}
