import { StateDeclaration, Transition, TransitionService } from '@uirouter/core';
import { ModuleActionBarService } from '@obo-main/services/moduleActionBar/moduleActionBar.service';
import { ModuleActionBarStep } from '@obo-main/components/moduleActionBar/moduleActionBarStep.model';

/**
 * checks if there is a feature set, for which a tutorial should be shown
 * @param transitionService
 */
export function moduleActionBarHook(transitionService: TransitionService) {
    const moduleActionBarCriteria = {
        to: (state: any) => true
    };

    const action = (transition: Transition, state: StateDeclaration) => {
        const moduleActionBarService = transition.injector().get(ModuleActionBarService) as ModuleActionBarService;
        if (state.data?.mab) {
            moduleActionBarService.projectId = transition.params().projectId;
            moduleActionBarService.currentStep = <ModuleActionBarStep>{
                name: state.name,
                icon: state.data.mab.icon,
                label: state.data.mab.label,
                isProductSelection: state.data.mab.isProductSelection
            };
        } else {
            moduleActionBarService.projectId = undefined;
            moduleActionBarService.currentStep = undefined;
        }
    };

    transitionService.onEnter(moduleActionBarCriteria, action, {
        priority: 55
    });
}
