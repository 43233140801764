import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpPatchInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method === 'PATCH' && !request.headers.has('Content-Type')) {
            //apply contentType
            const newRequest = request.clone({
                headers: request.headers.append('Content-Type', 'application/json-patch+json')
            });
            return next.handle(newRequest);
        }
        return next.handle(request);
    }
}
