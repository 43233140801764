import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@obo-main/services/language/language.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { TranslateHttpLoader } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.service';

/**
 * Factory to setup the httpLoader for Translations
 * @param appSettings needed to determine the api path
 * @param http dependency to httpclient to load the files
 */
export function TranslateLoaderFactory(
    logger: Logger,
    translateService: TranslateService,
    languageService: LanguageService,
    http: HttpClient,
    appSettings: AppSettings,
    translateLoader: TranslateHttpLoader,
    localStorage: Storage
) {
    return translateLoader.initialize(
        logger,
        translateService,
        languageService,
        http,
        appSettings.getItem('modules'),
        0,
        appSettings.getItem('settings.apiPrefix') + 'Localizations?moduleId={part}&lang={lang}',
        true,
        localStorage
    );
}
