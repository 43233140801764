import { AfterViewInit, Component, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ProductModel } from '@obo-admin/admin.models';
import { concatAll, concatMap, debounceTime, distinctUntilChanged, finalize, map, take, tap } from 'rxjs/operators';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { OverlayConfig } from '@angular/cdk/overlay';
import { OVERLAY_FULL_SCREEN_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';

@Component({
    selector: 'adm-router-database-lookup',
    templateUrl: './databaseLookup.component.html',
    styleUrls: ['./databaseLookup.component.scss']
})
export class DatabaseLookupComponent implements AfterViewInit {
    public selectedRow?: ProductModel;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    dataGridService: DataGridService;

    @ViewChild('modal')
    modal: TemplateRef<any>;

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private overlayService: OverlayService,
        @Inject(OVERLAY_FULL_SCREEN_CONFIG)
        private overlayConfig: OverlayConfig,
        private dataGridServiceFactory: DataGridServiceFactory,
        private viewContainerRef: ViewContainerRef
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Products`);
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
    }

    public selectRow(row: any): void {
        this.selectedRow = row;
        this.overlayService.open(this.modal);
    }

    public closeModal(): void {
        this.overlayService.close();
    }

    public deleteRow(row: ProductModel): void {
        this.dataGridService.remove(row).subscribe(() => {
            this.selectedRow = undefined;
        });
    }

    public removePage(): void {
        this.dataGridService
            .pipe(
                take(1),
                map((data) => data.items),
                concatAll(),
                concatMap((row) => this.dataGridService.remove(row)),
                finalize(() => this.dataGridService.read(this.dataGridState))
            )
            .subscribe();
    }

    public delete(): void {
        this.selectedRow ? this.deleteRow(this.selectedRow) : this.removePage();
    }

    public ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
        this.dataGridService.read(this.dataGridState);
    }
}
