<div class="w-100 confirm-dialog">
    @if (!confirmationShowing) {
    <div class="w-100 d-flex justify-content-between align-items-center" @growIn>
        @if (saveBtnTpl) {
        <ng-template [ngTemplateOutlet]="saveBtnTpl"></ng-template>
        } @if (activateBtnTpl) {
        <ng-template [ngTemplateOutlet]="activateBtnTpl"></ng-template>
        } @else {
        <button class="btn btn-fa ps-0" (click)="showConfirmation()" type="button">
            <fa-icon [icon]="['fal', 'trash-alt']" size="2x"></fa-icon>
            {{ 'BTN_DELETE' | translate }}
        </button>
        }
    </div>
    } @else {
    <div class="confirmation" @growIn>
        <div class="confirm-label">
            @if (labelTpl) {
            <ng-template
                [ngTemplateOutlet]="labelTpl"
                [ngTemplateOutletContext]="{
                    $implicit: item
                }"></ng-template>
            } @else {
            <label>{{ questionKey | translate }}</label>
            }
        </div>
        <div class="confirm-buttons">
            @if (abortBtnTpl) {
            <ng-template
                [ngTemplateOutlet]="abortBtnTpl"
                [ngTemplateOutletContext]="{
                    $implicit: hideConfirmation
                }"></ng-template>
            } @else {
            <button class="btn btn-fa" (click)="hideConfirmation()" type="button">
                <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                {{ 'BTN_ABORT' | translate }}
            </button>
            } @if (confirmBtnTpl) {
            <ng-template
                [ngTemplateOutlet]="confirmBtnTpl"
                [ngTemplateOutletContext]="{
                    $implicit: confirm
                }"></ng-template>
            } @else {
            <button class="btn btn-fa" (click)="confirm()" type="button">
                <fa-icon [icon]="['fal', 'trash-alt']" size="2x"></fa-icon>
                {{ 'BTN_YES' | translate }}
            </button>
            }
        </div>
    </div>
    }
</div>
