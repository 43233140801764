import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';

@Injectable()
export class DataGridServiceFactory {
    constructor(
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {}

    public getService(url: string): DataGridService {
        let service = new DataGridService(this.http, this.spinnerService, this.alertService, this.translateService);
        service.initialize(url);
        return service;
    }
}
