import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@obo-main/services/seo/seo.service';

@Component({
    selector: 'adm-router-abstract',
    templateUrl: './adminAbstract.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AdminAbstractComponent implements OnInit {
    constructor(private seo: SEOService, private translateService: TranslateService) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('ADMIN_META_TITLE'));
    }
}
