<lib-sidebar-container>
    <div lib-sidebar-content class="h-100">
        <app-debug-overlay></app-debug-overlay>
        <app-spinner></app-spinner>
        <app-alert></app-alert>
        <lib-ngx-guided-tour></lib-ngx-guided-tour>

        <div class="toasts">
            <app-motd></app-motd>
            <app-profile-completion-reminder></app-profile-completion-reminder>
        </div>

        <app-nav>
            <app-elbridge-banner></app-elbridge-banner>
        </app-nav>
        <app-module-action-bar></app-module-action-bar>
        <main #main id="main" [ngClass]="containerClass">
            <ui-view></ui-view>
        </main>

        <app-navigation></app-navigation>
        <app-tour></app-tour>
    </div>
</lib-sidebar-container>
