export class TranslateStoreItem {
    constructor(partId: number, langCode: string, translations: any) {
        this.partId = partId;
        this.langCode = langCode;
        this.translations = translations;
    }
    public partId: number;
    public langCode: string;
    public translations: { [key: string]: string };
}
