import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class ToggleService {
    public isToggled = new BehaviorSubject<boolean>(false);

    public toggle() {
        this.isToggled.next(!this.isToggled.value);
    }

    public subscribe(subscriber) {
        return this.isToggled.subscribe(subscriber);
    }
}
