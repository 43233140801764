import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@obo-main/services/auth/auth.service';

@Directive({
    selector: '[shdCheckRoles]'
})
export class CheckUserPermissionsDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService
    ) {}

    @Input()
    public shdCheckRoles: string[];

    public ngOnInit(): void {
        this.authService.onPermissionUpdate.subscribe(() => {
            this.updateVisibility();
        });
        this.updateVisibility();
    }

    private updateVisibility() {
        this.viewContainerRef.clear();
        if (this.isAllowedToAccess()) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

    private isAllowedToAccess() {
        if (Array.isArray(this.shdCheckRoles) && this.shdCheckRoles.length > 0) {
            if (!this.authService.isInRole(this.shdCheckRoles)) {
                return false;
            }
        }
        return true;
    }
}
