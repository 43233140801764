import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@obo-account/models/account.models';
import { YesNoPipe } from '@obo-common/shared/pipes/yesNo.pipe';
import { Region } from '@obo-main/services/region/region.models';
import { OboMultiSelectFilter, OboMultiSelectFilterOption } from '@obo-common/filter/models/multiSelect';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { RegionService } from '@obo-main/services/region/region.service';
import { DatePipe } from '@angular/common';
import { OverlayConfig } from '@angular/cdk/overlay';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Observable, Subject } from 'rxjs';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import {
    DataGridExcelExportEvent,
    DataGridFilterOperator,
    DataGridResult,
    DataGridState
} from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';

@Component({
    selector: 'adm-router-user-management',
    templateUrl: './userManagement.component.html'
})
export class UserManagementComponent implements OnDestroy, AfterViewInit {
    @Input()
    public regionList: Array<Region>;
    public regionMap: { [key: string]: string } = {};
    public filterFormGroup = new UntypedFormGroup({
        regions: new OboMultiSelectFilter(this.translateService.instant('ADMIN_ANALYTICS_REGIONNAME'), false, [])
    });
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public dataGridService: DataGridService;
    public formGroup: UntypedFormGroup;

    private onDestroy = new Subject<any>();

    @ViewChild('formModal')
    formModal: TemplateRef<any>;

    @ViewChild('inviteUserTpl')
    inviteUserModal: TemplateRef<any>;

    public range: { start: string; end: string };

    public selectedRow?: any;

    public get formRoles() {
        return <UntypedFormArray>this.formGroup!.controls.roles;
    }

    private yesNoPipe = new YesNoPipe();

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private formBuilder: UntypedFormBuilder,
        public translateService: TranslateService,
        private http: HttpClient,
        private changeDetectorRef: ChangeDetectorRef,
        private regionService: RegionService,
        private datePipe: DatePipe,
        private alertService: AlertService,
        private logger: Logger,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig,
        private dataGridServiceFactory: DataGridServiceFactory,
        private overlayService: OverlayService,
        private viewContainerRef: ViewContainerRef
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Users`);
        this.range = {
            start: this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'),
            end: this.datePipe.transform(new Date(), 'yyyy-MM-dd')!
        };

        this.regionService.getRegions().subscribe((regions) => {
            this.regionList = regions;
            this.regionMap = this.regionList.reduce((obj, m) => {
                obj[m.id] = m.displayName;
                return obj;
            }, {} as any);

            this.regionList.forEach((r) => {
                (this.filterFormGroup.controls.regions as OboMultiSelectFilter<any>).push(
                    new OboMultiSelectFilterOption(this.translateService.instant(r.displayName), r.id, () => true, true)
                );
            });

            this.changeDetectorRef.detectChanges();
        });
    }

    public ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
        this.dataGridService.read(this.dataGridState);

        this.filterFormGroup.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => this.applyFilter());
    }

    public ngOnDestroy() {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public applyFilter(): void {
        const regionFormControl = this.filterFormGroup.controls.regions as OboMultiSelectFilter<any>;

        this.dataGridState.filterState = [
            {
                filters: regionFormControl.controls
                    .filter((o) => o.isActive)
                    .map((o) => ({
                        id: 'regionId',
                        value: o.filterValue,
                        operator: DataGridFilterOperator.IsEqualTo
                    })),
                logic: 'or'
            }
        ];

        if (this.range.start && this.range.end) {
            this.checkDateRange();
            const dateFilter = [
                {
                    filters: [
                        {
                            id: 'registrationDate',
                            value: `datetime'${this.range.start}'`,
                            operator: DataGridFilterOperator.GreaterThanOrEqualTo
                        },
                        {
                            id: 'registrationDate',
                            value: `datetime'${this.range.end + 'T23:59:59.999'}'`,
                            operator: DataGridFilterOperator.LessThanOrEqualTo
                        }
                    ],
                    logic: 'and'
                }
            ];

            this.dataGridState.filterState.push(...dateFilter);
        }

        this.dataGridService.read(this.dataGridState);
    }

    public createFormGroup(args: any): UntypedFormGroup {
        const item = (args.isNew ? new User() : args.dataItem) as UserManagementUser;

        this.formGroup = this.formBuilder.group({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: [item.email, Validators.required],
            title: item.title,
            street: item.street,
            houseNumber: item.houseNumber,
            postalCode: item.postalCode,
            city: item.city,
            regionId: [item.regionId, Validators.required],
            phoneNumber: item.phoneNumber,
            company: item.company,
            isContactable: item.isContactable,
            customerGroup: item.customerGroup,
            registrationDate: item.registrationDate,
            latestSignInDate: item.latestSignInDate,
            buildingCount: item.buildingCount || 0,
            projectCount: item.projectCount || 0,
            isAdmin: item.isAdmin,
            emailConfirmed: item.emailConfirmed,
            roles: this.formBuilder.array(
                item.roles.map((r) =>
                    Object.entries(r).reduce((formGroup, [key, value]) => {
                        formGroup.addControl(key, new UntypedFormControl(value));
                        return formGroup;
                    }, new UntypedFormGroup({}))
                )
            )
        });
        return this.formGroup;
    }

    public inviteUser(email: string): void {
        this.http.post<any>(`${this.apiPrefix}Accounts/Invite`, { email }).subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('ADMIN_EMAIL_SEND'));
            },
            error: (err) => {
                this.logger.debug('Email could not be send, see error below: \n' + err);
                this.alertService.danger(this.translateService.instant('ADMIN_EMAIL_SEND_FAIL'));
            },
            complete: () => this.overlayService.close()
        });
    }

    public saveHandler(): void {
        const value = this.formGroup!.value;
        this.dataGridService.update(value);
        this.overlayService.close();
    }

    public openUserInvitation(): void {
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
        this.overlayService.open(this.inviteUserModal);
    }

    public updateIsAdminFlag(): void {
        const user: UserManagementUser = this.formGroup!.value;
        this.formGroup!.controls.isAdmin.patchValue(user.roles.filter((r) => r.isMember).some((r) => r.name.match(/admin/i)));
    }

    public selectRow(event: any): void {
        this.selectedRow = event;
        this.editHandler({ dataItem: this.selectedRow });
    }

    public editHandler({ dataItem }: any) {
        this.formGroup = this.createFormGroup({ isNew: false, dataItem });
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
        this.overlayService.open(this.formModal);
    }

    public cancelHandler() {
        this.overlayService.close();
        this.formGroup = undefined;
    }

    public deleteUser(user: UserManagementUser): void {
        this.dataGridService.remove(user);
    }

    public fetchData(): () => Observable<DataGridResult> {
        return () => this.dataGridService.getAllEntries();
    }

    public onExcelExport(e: DataGridExcelExportEvent): void {
        const rows = [...e.rows];
        rows.forEach((row) => {
            if (row[0]) {
                row[0] = this.translateService.instant(this.regionMap[row[0] as number]); // RegionName
            }
            if (row[1] && (row[1] as string).length > 0) {
                row[1] = this.translateService.instant(row[1] as string); // Title
            }
            if (row[9]) {
                row[9] = this.translateService.instant(this.regionMap[row[9] as number]); // region
            }
            if (row[11]) {
                row[11] = this.translateService.instant(row[11] as string); // customergroup
            }
            if (row[12] !== undefined) {
                row[12] = this.translateService.instant(this.yesNoPipe.transform(row[12] as boolean)); // iscontactable
            }
            if (row[17] !== undefined) {
                row[17] = this.translateService.instant(this.yesNoPipe.transform(row[17] as boolean)); // emailConfirmed
            }
        });
        e.updatedRows.next(rows);
    }

    private checkDateRange() {
        if (this.range.end < this.range.start) {
            this.range.end = this.range.start;
        }
    }
}

type UserManagementUser = User & {
    projectCount: number;
    buildingCount: number;
    roles: { id: number; name: string; isMember: boolean }[];
    isAdmin: boolean;
};
