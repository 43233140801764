<ng-template #desktopOnlyModal>
    <div class="modal">
        <div class="modal-header border-bottom-0">
            <h4 class="modal-title">{{ 'SHD_MODULE_DESKTOP_ONLY' | translate }}</h4>
            <button type="button" class="btn-close" (click)="close()"></button>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="close()">
                {{ 'BTN_GOBACK' | translate }}
            </button>
        </div>
    </div>
</ng-template>
