import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenRequest, TokenResponse } from '@obo-main/services/auth/auth.models';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { LocalStorage, SessionStorage } from '@obo-main/utils/localstorage.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Utils } from '@obo-main/utils/utils.service';
import { Constants } from 'app/constants';
import { BehaviorSubject, Observable, Subject, interval, timer } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';

const STORAGE_KEYS_TO_KEEP = [
    Constants.storageTokens.elbridge_sessionInfo,
    Constants.storageTokens.admin_translation_columns,
    Constants.storageTokens.portal_region,
    Constants.storageTokens.language,
    Constants.storageTokens.seen_tour_list
];

@Injectable()
export class AuthService {
    public onPermissionUpdate: Subject<void> = new Subject<void>();
    public onAuthenticationStatusChange: BehaviorSubject<boolean>; // logIn: true, logOut: false

    private tokenInterval: Subject<number> = new Subject(); // Interval which is used to renew the token
    private currentUserRoles: string[];
    private jwtHelper: JwtHelperService = new JwtHelperService({});

    /**
     *
     * @param baseUrl baseUrl of the Application
     * @param localStorage reference to localStorage object
     * @param sessionStorage reference to sessionStorage object
     * @param appSettings reference to appSettings singleton
     * @param logger reference to logger singleton
     * @param utils collection of helper methods
     * @param accountService reference to accountService singleton
     */
    constructor(
        @Inject(LocalStorage) private localStorage: any,
        @Inject(SessionStorage) private sessionStorage: any,
        private appSettings: AppSettings,
        private logger: Logger,
        private http: HttpClient,
        private utils: Utils
    ) {
        this.onPermissionUpdate.subscribe(() => {
            this.logger.debug("onPermissionUpdateObservable's next method activated");
        });
    }

    public initializeAuth(): Promise<void> {
        this.tokenInterval
            .pipe(
                distinctUntilChanged(),
                map((expiresIn) => {
                    const interval = expiresIn * 1000 * 0.95; // create interval of seconds * 0.95 to renew it a bit early
                    this.logger.debug(`RefreshToken Interval Initialized with ${interval}`);
                    return interval;
                }),
                switchMap((s) => interval(s)),
                mergeMap(() => this.refreshAccessToken())
            )
            .subscribe((x) => {
                this.logger.debug('RefreshToken Interval called');
            });

        return new Promise<void>((resolve) => {
            const tokenExpired = this.isTokenExpired();
            if (!tokenExpired) {
                this.applyUserPermissions(this.accessToken); // applies user permissions after appplicationboot
                this.onAuthenticationStatusChange = new BehaviorSubject<boolean>(true);
                if (this.refreshToken) {
                    //shedule refresh of accesstoken shortly before it expired
                    const expireDate = this.jwtHelper.getTokenExpirationDate(this.accessToken);
                    const expiresInSeconds = (expireDate!.getTime() - new Date().getTime()) / 1000;
                    this.logger.info(
                        `InitializeAuth: Already logged in with valid token. Refreshing accessToken for the first time in ${expiresInSeconds} seconds`
                    );
                    timer(expiresInSeconds * 1000 * 0.9)
                        .pipe(mergeMap(() => this.refreshAccessToken()))
                        .subscribe(() => {});
                    return resolve();
                } else {
                    this.logout();
                    return resolve();
                }
            } else if (tokenExpired && this.refreshToken) {
                // refreshed the token when it is expired but an refreshtoken is available
                this.onAuthenticationStatusChange = new BehaviorSubject<boolean>(true);
                return this.refreshAccessToken()
                    .pipe(finalize(() => resolve()))
                    .subscribe(() => {});
            } else {
                this.onAuthenticationStatusChange = new BehaviorSubject<boolean>(false);
                return resolve();
            }
        });
    }

    /**
     * Refreshes the AccessToken.
     * Returns a promise which resolves to true when the refresh was successful, and false when there occured an error
     */
    public refreshAccessToken(): Observable<TokenResponse> {
        this.logger.debug('Requesting new AccessToken');
        let data: TokenRequest = {
            grant_type: 'refresh_token',
            refresh_token: this.refreshToken,
            client_id: Constants.clientId
        };
        return this.callTokenEndpoint(data);
    }

    /**
     * Performed the LoginAction
     */
    public login(username: string, password: string): Observable<TokenResponse> {
        this.logger.debug(`Attempting to Login User: ${username}`);
        this.clearStorage(this.localStorage);
        let data: TokenRequest = {
            grant_type: 'password',
            client_id: Constants.clientId,
            scope: 'offline_access',
            username: username,
            password: password
        };
        return this.callTokenEndpoint(data).pipe(
            tap((res) => {
                this.onAuthenticationStatusChange.next(true); // triggers the observable
            })
        );
    }

    /**
     * Performes the Logout Action
     * This will delete all tokens from Storage
     */
    public logout(): void {
        this.logger.debug('Logout');
        this.clearStorage(this.localStorage);
        this.clearStorage(this.sessionStorage);
        this.applyUserPermissions('');
        this.onAuthenticationStatusChange.next(false);
    }

    /**
     * Checks if the AccessToken is expired or not
     * returns true if token is expired
     * @param token
     */
    public isTokenExpired(token?: string): boolean {
        if (!token) {
            token = this.accessToken;
        }
        if (!token) {
            return true;
        }
        return this.jwtHelper.isTokenExpired(token);
    }

    /**
     * returns true if user is Admin
     */
    public isAdmin(): boolean {
        return this.isInRole([
            Constants.Role.AccountAdmin,
            Constants.Role.ProductAdmin,
            Constants.Role.ContentAdmin,
            Constants.Role.AnalyticsAdmin
        ]);
    }

    /**
     * Check if the User is in at least one of the roles specified in roles array
     */
    public isInRole(roles: string[]) {
        if (this.isTokenExpired()) {
            // user is guest
            return roles.some((r) => r === Constants.Role.Guest);
        } else {
            return roles.some((r) => this.userRoles.some((x) => x === r));
        }
    }

    /**
     * returns the accesstoken
     */
    public getAccessToken(): string {
        return this.accessToken;
    }

    private callTokenEndpoint(data: TokenRequest): Observable<TokenResponse> {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };
        return this.http
            .post(
                `${this.appSettings.getItem('settings.apiPrefix')}connect/token`,
                Object.entries(data)
                    .reduce((params, [key, value]) => params.set(key, value), new HttpParams({ encoder: new CustomURLEncoder() }))
                    .toString(),
                options
            )
            .pipe(
                tap(
                    (result: TokenResponse) => {
                        this.logger.debug('Contact to TokenEndpoint sucessfull');
                        this.accessToken = result.access_token!; // stores accesstoken
                        this.refreshToken = result.refresh_token!; // stores refreshtoken
                        this.applyUserPermissions(result.access_token!); // updates user permissions from token claim
                        this.tokenInterval.next(result.expires_in!); // starts the tokenrefreshinterval if not already done
                    },
                    (err) => {
                        this.logout();
                        this.logger.error('TokenEndpoint Error:', err);
                    }
                )
            );
    }

    private applyUserPermissions(token: string): void {
        if (!token) {
            this.userRoles = new Array<string>();
        } else {
            let decodedToken = this.jwtHelper.decodeToken(token); // decodes the token
            this.userRoles = Array.isArray(decodedToken.role) ? decodedToken.role : [decodedToken.role];
        }
        this.onPermissionUpdate.next(); // notifies all subscriber of the Observable about a possible change. Used by directives to hide elements on DOM
    }

    /**
     * Gets the RefreshToken from Localstorage
     */
    private get refreshToken(): string {
        return this.localStorage.getItem(Constants.storageTokens.refresh_token);
    }

    /**
     * Sets the RefreshToken from Localstorage
     */
    private set refreshToken(refreshToken: string) {
        this.logger.debug('Setting RefreshToken', refreshToken);
        this.localStorage.setItem(Constants.storageTokens.refresh_token, refreshToken);
    }

    /**
     * Gets the AccessToken from Localstorage
     */
    private get accessToken(): string {
        return this.localStorage.getItem(Constants.storageTokens.access_token);
    }

    /**
     * Sets the AccessToken from Localstorage
     */
    private set accessToken(accessToken: string) {
        this.logger.debug('Setting AccessToken', accessToken);
        this.localStorage.setItem(Constants.storageTokens.access_token, accessToken);
    }

    public get userRoles() {
        return this.currentUserRoles;
    }

    public set userRoles(roles: string[]) {
        this.logger.debug(`Setting Roles: ${roles} to Acount`);
        this.currentUserRoles = roles;
    }

    private clearStorage(storage: Storage): boolean {
        let keysToKeep = this.getStorageKeysToKeep();
        Object.keys(storage).forEach((key) => {
            if (!keysToKeep.includes(key)) {
                storage.removeItem(key);
            }
        });
        return true;
    }

    private getStorageKeysToKeep() {
        const getKeysFn = (obj: any, keyList: Array<string>) => {
            for (let k in obj) {
                if (typeof obj[k] == 'object' && obj[k] !== null) {
                    getKeysFn(obj[k], keyList);
                } else {
                    keyList.push(obj[k]);
                }
            }
        };
        let keysToKeep = [];
        getKeysFn(STORAGE_KEYS_TO_KEEP, keysToKeep);
        return keysToKeep;
    }
}

export class CustomURLEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(key: string): string {
        return encodeURIComponent(key);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(key: string) {
        return decodeURIComponent(key);
    }
}
