import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'shd-error-field',
    templateUrl: './errorField.component.html',
    styleUrls: ['./errorField.component.scss']
})
export class ErrorFieldComponent {
    @Input()
    control: UntypedFormControl | AbstractControl;
    @Input() customErrorMsg!: string;
}
