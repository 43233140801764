import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BuildingEditModalComponent } from '@obo-dashboard/components/dashboard/project/modals/building-edit-modal.component';
import { Building } from '@obo-dashboard/models/projectManagement.models';
import { BuildingService } from '@obo-dashboard/services/building.service';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Region } from '@obo-main/services/region/region.models';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-building-card',
    templateUrl: './building-card.component.html',
    styleUrls: ['./building-card.component.scss']
})
export class BuildingCardComponent implements OnInit {
    @Input()
    public building: Building;
    @Input()
    public projectId: number;
    @Input()
    public regions: Observable<Region[]>;
    @Input()
    public selected: boolean;

    @Output()
    public selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public buildingChange: EventEmitter<number> = new EventEmitter<number>();

    public editBuildingOverlay: OverlayRef;
    public regionIdReadonly: boolean;
    public language;

    constructor(
        private translateService: TranslateService,
        private buildingService: BuildingService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private overlay: Overlay,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig,
        private viewContainerRef: ViewContainerRef
    ) {
        this.language = this.translateService.currentLang;
    }

    public ngOnInit(): void {
        this.regionIdReadonly = this.building?.plannings.some((p) => p.isPlanned);
    }

    public editBuilding(): void {
        const buildingClone = Object.assign({}, this.building);
        this.editBuildingOverlay = this.overlay.create(this.overlayConfig);
        const buildingEditModalPortal = new ComponentPortal(
            BuildingEditModalComponent,
            this.viewContainerRef,
            Injector.create({
                providers: [
                    {
                        provide: 'building',
                        useValue: buildingClone
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('EDIT_BUILDING')
                    },
                    {
                        provide: 'saveFn',
                        useValue: (building: Building) => {
                            this.saveBuilding(building);
                            this.dismissModal();
                        }
                    },
                    {
                        provide: 'dismissFn',
                        useValue: () => this.dismissModal()
                    },
                    {
                        provide: 'regionIdReadonly',
                        useValue: this.regionIdReadonly
                    },
                    {
                        provide: 'regions',
                        useValue: this.regions
                    }
                ]
            })
        );
        this.editBuildingOverlay.attach(buildingEditModalPortal);
    }

    public deleteBuilding(): void {
        this.spinnerService.startSpinner();
        this.buildingService
            .deleteBuilding(this.projectId, this.building)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (): void => {
                    this.alertService.success(this.translateService.instant('BUILDINGCTRL_DELETEBUILDINGSUCCESS'));
                    this.buildingChange.emit();
                },
                error: () => this.alertService.danger(this.translateService.instant('BUILDINGCTRL_DELETEBUILDINGFAILURE'))
            });
    }

    /**
     * duplicates a building
     */
    public duplicateBuilding(): void {
        this.spinnerService.startSpinner();
        this.buildingService
            .duplicateBuilding(this.projectId, this.building)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (): void => {
                    this.alertService.success(this.translateService.instant('MAIN_DUPLICATEBUILDING_SUCCESS'));
                    this.buildingChange.emit();
                },
                error: () => this.alertService.danger(this.translateService.instant('MAIN_DUPLICATEBUILDING_FAILURE'))
            });
    }

    private saveBuilding(building: Building) {
        this.spinnerService.startSpinner();
        this.buildingService
            .createOrUpdateBuilding(this.projectId, building)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (building: Building): void => {
                    this.building = building;
                    this.alertService.success(this.translateService.instant('BUILDINGCTRL_SAVEBUILDINGSUCCESS'));
                    this.buildingChange.emit();
                },
                error: () => this.alertService.danger(this.translateService.instant('BUILDINGCTRL_SAVEBUILDINGFAILURE'))
            });
    }

    private dismissModal() {
        this.editBuildingOverlay.detach();
        this.editBuildingOverlay = undefined;
    }
}
