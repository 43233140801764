import { Constants } from 'app/constants';

export class Admin {
    public id?: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public regionId: number;
    public NewPassword: string;
    public OldPassword: string;
    public CONFIRMPASSWORD: string;
}

export class User extends Admin {
    public title: string;
    public street: string;
    public houseNumber: string;
    public postalCode: string;
    public city: string;
    public phoneNumber: string;
    public company: string;
    public password: string;
    public confirmPassword: string;
    public acceptTOS: string;
    public isContactable: boolean;
    public registrationDate: string;
    public latestSignInDate: string;
    public customerGroup: string;
    public emailConfirmed: boolean;

    public isProfileComplete(): boolean {
        return [this.firstName, this.lastName, this.customerGroup, this.company, this.title].every(
            (value) => value != null && value !== ''
        );
    }
}

export class PasswordPattern {
    public pattern: string;
}

export class LoginData {
    public username: string;
    public password: string;
}

export class ResetPasswordRequest {
    public password: string;
    public passwordConfirmed: string;
    public id: string;
    public token: string;
}
