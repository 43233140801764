import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayService } from '@obo-common/shared/services/overlay.service';

@Component({
    selector: 'shd-desktop-only-modal',
    templateUrl: './desktopOnlyModal.component.html',
    exportAs: 'DesktopOnlyModalComponent'
})
export class DesktopOnlyModalComponent {

    @ViewChild('desktopOnlyModal') modal: TemplateRef<any>;

    constructor(private overlayService: OverlayService, private viewContainerRef: ViewContainerRef) {}

    public open(): void {
        this.overlayService.init(this.viewContainerRef);
        this.overlayService.open(this.modal);
    }

    public close(): void {
        this.overlayService.close();
    }
}
