import { OverlayRef } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@obo-account/account/account.service';
import { DownloadTypeEnum } from '@obo-dashboard/models/download.models';
import { DownloadService } from '@obo-dashboard/services/download.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { PortalFeatureEnum } from '@obo-portal/models/portal.models';

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
    public downloadType = DownloadTypeEnum;
    public featureEnum = PortalFeatureEnum;

    public cfOldIsInFeatures: boolean = false;

    public versions: DownloadVersionItem[] = [
        { title: 'AutoCAD 2019', value: '2019' },
        { title: 'AutoCAD 2020', value: '2020' },
        { title: 'AutoCAD 2021', value: '2021' },
        { title: 'AutoCAD 2022', value: '2022' },
        { title: 'AutoCAD 2023', value: '2023' },
        { title: 'AutoCAD 2024', value: '2024' }
    ];

    public cadIsInFeatures: boolean = false;
    public autoCADVersionOverlay: OverlayRef | undefined;

    @ViewChild('selectAutoCADVersionModalTpl')
    public selectAutoCADVersionModalTpl: TemplateRef<any>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private regionService: RegionService,
        private accountService: AccountService,
        private downloadService: DownloadService,
        private translateService: TranslateService,
        private seo: SEOService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('DOWNLOADS_META_TITLE'));

        this.updateInFeatures();
    }

    public downloadFile(module: DownloadTypeEnum, version: string = ''): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(this.downloadService.findDownloadUrl(this.translateService.currentLang, module, version), '_self');
        }
    }

    private updateInFeatures(): void {
        this.accountService.getAccount().subscribe((user) => {
            const features = this.regionService.portalRegions.find((r) => r.id === user.regionId)!.features;

            this.cadIsInFeatures = features.some((f) => f.id === this.featureEnum.AutoCad);
            this.cfOldIsInFeatures = features.some((f) => f.id === this.featureEnum.CablefillingOld);
        });
    }
}

interface DownloadVersionItem {
    title: string;
    value: string;
}
