import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { StateService } from '@uirouter/core';
import { ElbridgeService } from '@obo-main/services/elbridge/elbridge.service';
import { TranslateService } from '@ngx-translate/core';
import { ElbridgeConfiguratorRedirect } from '@obo-main/services/elbridge/elbridge.models';

@Component({
    selector: 'app-elbridge-entry',
    template: ''
})
export class ElbridgeEntryComponent implements AfterViewInit {
    @Input()
    public sessionId: string | undefined;
    @Input()
    public version: string | undefined;
    @Input()
    public target: string | undefined;
    @Input()
    public hookUrl: string | undefined;
    @Input()
    public configurator: string | undefined;

    public elbridgeConfiguratorRedirectEnum: ElbridgeConfiguratorRedirect;
    constructor(
        private alertSerice: AlertService,
        private stateService: StateService,
        private elbridgeService: ElbridgeService,
        private translateService: TranslateService
    ) {}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if ([this.sessionId, this.version, this.hookUrl].every((member) => typeof member === 'string')) {
                this.enableElbridgeMode(this.sessionId!, this.hookUrl!, this.target!, this.version!);
                //redirects to given configurator, if none is set redirects to portal
                if (this.configurator) {
                    this.redirectToConfigurator(this.configurator);
                } else {
                    this.stateService.go('portal');
                }
            } else {
                this.alertSerice.danger(this.translateService.instant('ELBRIDGE_SESSION_ERROR'));
                this.stateService.go('portal');
            }
        });
    }

    /**
     * activates the elbridge Service
     */
    private enableElbridgeMode(sessionId: string, hookUrl: string, target: string, version: string): void {
        this.elbridgeService.storeElbridgeInfoInCache(sessionId, hookUrl, target, version);
        this.alertSerice.success(this.translateService.instant('ELBRIDGE_SESSION_SUCCESS'));
    }

    private redirectToConfigurator(configurator: string) {
        let redirectStateName: string = 'portal';
        Object.entries(ElbridgeConfiguratorRedirect).forEach((entry) => {
            if (entry[1] === configurator) {
                redirectStateName = entry[0];
            }
        });

        this.stateService.go(redirectStateName);
    }
}
