import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
    constructor() {}

    public transform(value: boolean): string {
        return value ? `MAIN_BOOL_YES` : `MAIN_BOOL_NO`;
    }
}
