<div class="project-details">
    <div class="project-caption">
        <div class="project-heading">
            <h2 class="project-name no-wrap">
                {{ project.name }}
            </h2>
            @if (project.abbreviation) {
            <div class="project-abbreviation no-wrap">({{ project.abbreviation }})</div>
            } @if (project.customerName) {
            <h3 class="customer-name no-wrap">{{ project.customerName }}</h3>
            }
        </div>

        <shd-contextmenu [overflowHidden]="true">
            <button class="btn btn-contextmenu" (click)="duplicateProject()">
                <fa-icon class="me-2" [icon]="['fal', 'copy']"></fa-icon>
                {{ 'BTN_COPY' | translate }}
            </button>
            <button class="btn btn-contextmenu" (click)="editProject()">
                <fa-icon class="me-2" [icon]="['fal', 'pencil']"></fa-icon>
                {{ 'BTN_EDIT' | translate }}
            </button>
            <button class="btn btn-contextmenu" (click)="confirmDeleteModal.show()">
                <fa-icon class="me-2" [icon]="['fal', 'trash-alt']"></fa-icon>
                {{ 'BTN_DELETE' | translate }}
            </button>
        </shd-contextmenu>
    </div>

    <div class="project-info">
        <div class="no-wrap">
            <strong>{{ 'PROJECT_NUMBER' | translate }}:</strong>
            {{ project.number }}
        </div>
        <div class="no-wrap">
            <strong>{{ 'PROJECT_CUSTOMERNUMBER' | translate }}:</strong>
            {{ project.customerNumber }}
        </div>
        <div class="no-wrap">
            <strong>{{ 'PROJECT_ORDERDATE' | translate }}:</strong>
            {{ project.orderDate | date : 'dd.MM.yyyy' }}
        </div>
    </div>
</div>

<shd-confirm-modal
    #confirmDeleteModal
    [title]="'BTN_DELETE' | translate"
    [question]="'PROJECT_LABEL_DELETECONFIRM' | translate"
    (confirmed)="deleteProject()"></shd-confirm-modal>
