import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DebugService {
    public onOverlayEvent: Subject<{ title: string; error: any }> = new Subject();
    constructor() {}

    /**
     * creates an overlay message
     * @param title
     * @param error
     */
    public addOverlayEvent(title: string, error: any): void {
        this.onOverlayEvent.next({
            title: title,
            error: error
        });
    }
}
