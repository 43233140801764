<section id="motd-management">
    <div class="container">
        <button
            class="btn btn-fa mb-3"
            (click)="addNewMotd()"
            [disabled]="motdList.length > 0 && motdList[motdList.length - 1]?.id === undefined">
            <fa-icon [icon]="['fal', 'plus']" size="lg" class="me-2"></fa-icon>
            {{ 'BTN_ADD' | translate }}
        </button>
        <div ngbAccordion #acc="ngbAccordion" [closeOthers]="true" (show)="onPanelChange($event)">
            @for (message of motdList; track message) {
            <div [ngbAccordionItem]="'motd-' + message.id!">
                <div ngbAccordionHeader>
                    <div ngbAccordionToggle class="d-flex justify-content-between position-relative p-3">
                        <h4 class="mb-0">{{ message.name }}</h4>
                        <span>
                            {{ message.startDate | date : 'medium' }} -
                            {{ message.endDate | date : 'medium' }}
                            <span
                                class="ms-3 active-indicator pointer"
                                [title]="message.isActive ? 'active' : 'inactive'"
                                [ngClass]="message.isActive ? 'active' : 'inactive'"></span>
                        </span>
                    </div>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            @if (currentFormGroup !== undefined) {
                            <form [formGroup]="currentFormGroup">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">
                                                {{ 'ADMIN_MOTD_NAME' | translate }}
                                            </label>
                                            <input class="form-control form-control-border" formControlName="name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="mb-3 required">
                                            <label class="form-label">
                                                {{ 'ADMIN_STARTDATE' | translate }}
                                            </label>
                                            <input
                                                class="form-control form-control-border"
                                                type="datetime-local"
                                                formControlName="startDate"
                                                required
                                                shdValidateInput />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="mb-3 required">
                                            <label class="form-label">
                                                {{ 'ADMIN_ENDDATE' | translate }}
                                            </label>
                                            <input
                                                class="form-control form-control-border"
                                                type="datetime-local"
                                                formControlName="endDate"
                                                required
                                                shdValidateInput />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ul ngbNav #languageNav="ngbNav" class="nav-tabs">
                                            @for (language of availableLanguages; track language) {
                                            <li [ngbNavItem]="language.id">
                                                <a ngbNavLink class="bg-white">
                                                    {{ language.displayName }}
                                                </a>
                                                <ng-template ngbNavContent>
                                                    <div class="mt-3">
                                                        <label class="form-label">
                                                            {{ 'ADMIN_MOTD_TITLE' | translate }}
                                                        </label>
                                                        <input
                                                            class="form-control form-control-border"
                                                            [formControl]="
                                                                currentFormGroup.get('lang-' + language.name + '-title')
                                                            " />
                                                    </div>
                                                    <div class="my-3">
                                                        <editor
                                                            [init]="{
                                                                entity_encoding: 'raw',
                                                                height: 500,
                                                                menubar: false,
                                                                plugins:
                                                                    'lists advlist autolink link preview image preview wordcount',
                                                                toolbar:
                                                                    'blocks |\
                                                                    bold italic underline strikethrough removeformat |\
                                                                    forecolor backcolor |\
                                                                    bullist numlist outdent indent |\
                                                                    link image |\
                                                                    preview',
                                                                color_map: [
                                                                    '#F39B00',
                                                                    'Orange',
                                                                    '#7694A4',
                                                                    'Blue',
                                                                    '#000000',
                                                                    'Black',
                                                                    '#ffffff',
                                                                    'White',
                                                                    '#D9232B',
                                                                    'Red',
                                                                    '58B146',
                                                                    'Green'
                                                                ],
                                                                color_default_foreground: 'Orange',
                                                                color_default_background: 'Orange'
                                                            }"
                                                            [formControl]="
                                                                currentFormGroup.get('lang-' + language.name + '-message')
                                                            "></editor>
                                                    </div>
                                                </ng-template>
                                            </li>
                                            }
                                        </ul>
                                        <div [ngbNavOutlet]="languageNav"></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <shd-confirm-dialog
                                            class="d-flex"
                                            questionKey="ADMIN_ARE_YOU_SURE"
                                            (confirmed)="removeMotd(message)"
                                            [saveBtnTpl]="saveBtnTpl">
                                            <ng-template #saveBtnTpl>
                                                <button
                                                    class="btn btn-success me-1"
                                                    type="button"
                                                    (click)="saveMotd()"
                                                    [disabled]="currentFormGroup.invalid">
                                                    {{ 'BTN_SAVE' | translate }}
                                                </button>
                                            </ng-template>
                                        </shd-confirm-dialog>
                                    </div>
                                </div>
                            </form>
                            }
                        </ng-template>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</section>
