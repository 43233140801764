export interface ElbridgeInfo {
    sessionId: string;
    timestamp: string; // Unix timestamp
    hookUrl: string;
    version: string;
    target: string;
}

export interface ElbridgeApiResponse {
    target: string;
    hookUrl: string;
    result: string;
    version: string;
    country: string;
    language: string; //BMEcat_ETIM_ISO_6392
}

export class ElbridgeData {
    constructor(
        hookUrl: string,
        version: string,
        country: string,
        language: string,
        target: string,
        result: any,
        configurationUrl?: string
    ) {
        this.hookUrl = hookUrl;
        this.version = version;
        this.country = country;
        this.language = language;
        this.target = target;
        this.result = JSON.stringify(
            version === '1.0'
                ? result
                : {
                      CONFIGURATION_URL: configurationUrl,
                      ITEM: result
                  }
        );
    }
    public hookUrl: string;
    public version: string;
    public json: string = 'result';
    public result: string;
    public target: string;
    public country: string;
    public language: string;
}

export class ElbridgeCartRequest {
    sessionId?: string;
    moduleId: number;
    items: {
        productId: number;
        quantity: number;
    }[];
}

export enum ElbridgeConfiguratorRedirect {
    underfloor = 'underfloor',
    incendiary = 'fireProtection',
    filling = 'cableFilling',
    grounding = 'grounding',
    overVoltage = 'overVoltage'
}
