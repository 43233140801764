@if (showTourToggle) {
    <div class="tour-toggle" [class.inAppNavActive]="inAppNavActive" (click)="toggleRestartModal()"
         [ngbTooltip]="'TOUR_RESTART_TITLE' | translate">
        <fa-icon class="m-auto" [icon]="['fas', 'info']" size="lg"></fa-icon>
    </div>
}

<shd-confirm-modal
    #confirmModalRef
    (confirmed)="restartTour()"
    [title]="'TOUR_RESTART_TITLE' | translate"
    [question]="'TOUR_RESTART_QUESTION' | translate"></shd-confirm-modal>
