<ng-template #overlay>
    <div id="debug-buttons">
        <div id="debug-buttons-left" class="d-flex flex-column" style="position: fixed; z-index: 10000; left: 0; top: 0">
            <button (click)="showSpinner()">Show Spinner</button>
            <button (click)="hideSpinner()">Hide Spinner</button>
            <button (click)="refreshAccessToken()">Refresh AccessToken</button>
            <button (click)="clearAlerts()">Clear Alerts</button>
            <button (click)="clearLocalStorage()">Clear Localstorage</button>
            <button (click)="exportTranslationsJson()">Export current translations as JSON</button>
            <button (click)="showTestAlert()">Show Test Alert</button>
        </div>
        <div id="debug-buttons-right" class="d-flex flex-column" style="position: fixed; z-index: 10000; right: 0; top: 0">
            <button (click)="closeComponent()">Close Debug Component</button>
        </div>
        <div
            id="debug-buttons-left-bottom"
            class="d-flex flex-column"
            style="position: fixed; z-index: 10000; left: 0; bottom: 0">
            <span>State: {{ getCurrentState() }}</span>
            <span>Params: {{ getCurrentStateParams() | json }}</span>
            <span>Roles: {{ getUserRoles() | json }}</span>
        </div>
    </div>
</ng-template>
