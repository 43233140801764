import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { LoggerLevel } from '@obo-main/utils/logger/loggerLevel.enum';
import { Window } from '@obo-main/utils/window.service';

const MAXHISTORYSIZE = 100;

@Injectable()
export class Logger {
    private loggerLevel: LoggerLevel;
    private history = new Array<{
        timestamp: number;
        level: string;
        entry: any;
    }>();
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(Window) private readonly window: Window,
        private readonly http: HttpClient,
        @Optional() @Inject('DEBUG') private debugMode: boolean
    ) {
        this.initializeLogger();
    }

    /**
     * Sets the LoggerLevel
     * @param loggerLevel
     */
    public setLoggerLevel(loggerLevel: LoggerLevel): void {
        this.loggerLevel = loggerLevel;
    }

    public info(text: string, exc?: any): void {
        this.writeLog(LoggerLevel.info, text, exc);
    }

    public debug(text: string, exc?: any): void {
        this.writeLog(LoggerLevel.debug, text, exc);
    }

    public warn(text: string, exc?: any): void {
        this.writeLog(LoggerLevel.warn, text, exc);
    }

    public error(text: string, exc?: any): void {
        this.writeLog(LoggerLevel.error, text, exc);
    }

    private writeLog(level: LoggerLevel, text: string, exc?: any): void {
        if (isPlatformBrowser(this.platformId)) {
            ((this.window as any).console as any)[(LoggerLevel as any)[level]](text, exc || '');
        }
    }

    private initializeLogger(): void {
        if (isPlatformBrowser(this.platformId)) {
            LoggerLevel.getLevels().forEach((elem) => {
                const originalFunction = ((this.window as any).console as any)[elem.key];
                if (!originalFunction) {
                    return;
                }
                const getHistory = () => this.history;
                const getLoggerLevel = () => this.loggerLevel;
                ((this.window as any).console as any)[elem.key] = function () {
                    if (getLoggerLevel() >= elem.value) {
                        getHistory().unshift({
                            timestamp: Date.now(),
                            level: elem.key,
                            entry: arguments
                        });
                        getHistory().length = Math.min(getHistory().length, MAXHISTORYSIZE);
                        originalFunction.apply(this, arguments);
                    }
                };
            });
        }
    }
}
