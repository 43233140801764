import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Module } from '@obo-main/services/modules/module.models';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { TranslateStoreItem } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.models';
import { Constants } from 'app/constants';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { getRouteLanguage } from '../staticHelperFunctions';
import { Language } from '@obo-main/services/language/language.models';
import { LanguageService } from '@obo-main/services/language/language.service';

/**
 * This class loads Translation from Api and Stores them in the Translationstore of TranslateService
 * Call initialize method before use!
 */
@Injectable()
export class TranslateHttpLoader {
    private translateStore: TranslateStoreItem[];
    private logger: Logger;
    private translateService: TranslateService;
    private languageService: LanguageService;
    private http: HttpClient;
    private allParts: Module[];
    private defaultPart: number;
    private urlPattern: string;
    private isInititialized: boolean;
    private registerIntlFile: boolean; // downloads and registers the needed locale files

    constructor(
        @Inject('FALLBACKLANGUAGE') private fallbackLanguage: string,
        @Inject('INITIALAPPLANGUAGE') private initialLanguage: string
    ) {
        this.translateStore = new Array<TranslateStoreItem>();
        this.isInititialized = false;
    }

    /**
     * Gets all translations for the desired module from the server
     * @param lang
     * @returns <boolean>
     */

    public retrieveTranslationsForAllModules(lang?: string): Observable<boolean> {
        lang = lang || this.translateService.currentLang;
        return from(this.allParts).pipe(
            filter((part) => !this.isAlreadyInStore(part.id, lang!)),
            mergeMap((part) =>
                this.getItem(part.id, lang!).pipe(catchError((err) => of(`Failed to load translations for lang: ${lang}`)))
            ),
            tap((translations) => {
                if (typeof translations === 'string') {
                    this.logger.info(translations);
                } else {
                    this.translateStore.push(translations);
                    this.logger.info(`Adding translation for lang: ${lang}, module: ${translations.partId}`);
                    this.translateService.setTranslation(translations.langCode, translations.translations, true);
                }
            }),
            toArray(),
            map(() => true)
        );
    }

    /**
     * fetches the desired Part from api.
     * resolved immediatly when the part is already there
     * @param lang
     * @param part
     */
    public addPart(part: number, lang?: string): Observable<TranslateStoreItem> {
        lang = lang || this.translateService.currentLang;
        const existing = this.translateStore.find((x) => x.langCode === lang && x.partId === part);
        return existing !== undefined
            ? of(existing!)
            : this.getItem(part, lang!).pipe(
                  mergeMap((translations) => {
                      this.translateStore.push(translations);
                      this.translateService.setTranslation(translations.langCode, translations.translations, true);
                      if (lang !== this.translateService.getDefaultLang()) {
                          return this.addPart(part, this.translateService.getDefaultLang());
                      } else {
                          return of(translations);
                      }
                  }),
                  catchError((err) => of(new TranslateStoreItem(part, lang!, {}))) // we resolve this even when this fails. otherwise our app does not bootstrap when translationloading was unsuccessful
              );
    }

    /**
     * Removes a part from the loaderstore
     * this is a little bit hacky atm :/
     * @param part
     */
    public removePart(part: number): void {
        let items = this.translateStore.filter((x) => x.partId == part);
        items.forEach((i) => {
            this.logger.debug(`Removing Translation part ${part} of module ${i.partId}`);
            let index = this.translateStore.findIndex((x) => x.langCode === i.langCode && x.partId === i.partId);
            this.translateStore.splice(index, 1);
            let newTranslationSet = this.translateStore
                .filter((x) => x.langCode === i.langCode)
                .map((m) => m.translations)
                .reduce((a, b) => {
                    return Object.assign(a, b);
                });
            this.translateService.setTranslation(i.langCode, newTranslationSet, false);
        });
    }

    /**
     * Returns the translations of the desired language
     * if the translationlist for the desired language is empty, it gets module defaultpart from api
     * @param lang code of the language
     * @returns {any} Object containing all the translations for the desired language
     */
    public getTranslation(lang: string): Observable<any> {
        const existing = this.translateStore.filter((x) => x.langCode === lang);
        return existing.length > 0
            ? of(
                  existing
                      .map((m) => m.translations)
                      .reduce((a, b) => {
                          return Object.assign(a, b);
                      })
              )
            : this.addPart(this.defaultPart, lang).pipe(
                  map((result: TranslateStoreItem) => result.translations),
                  catchError((error) => {
                      this.logger.error('Something went wrong while getting translations', error);
                      return of({});
                  })
              );
    }

    /**
     * checks if a part is already in translation store
     * @param lang
     * @param part
     */
    public isAlreadyInStore(part: number, lang?: string) {
        lang = lang || this.translateService.currentLang;
        return this.translateStore.some((x) => x.langCode === lang && x.partId === part);
    }

    public getTranslateStore(langCode: string = this.translateService.currentLang): TranslateStoreItem[] {
        return this.translateStore.filter((s) => s.langCode === langCode);
    }

    private getItem(part: number, lang: string): Observable<TranslateStoreItem> {
        if (this.urlPattern === undefined) {
            throw new Error('Translationloader must be initialized before first Use. Call initialize method');
        }
        return this.http.get(this.urlPattern.replace('{part}', part.toString()).replace('{lang}', lang)).pipe(
            map((result) => {
                if (this.registerIntlFile && lang.length === 2) {
                    import(
                        /* webpackInclude: /(\/|\\)(cs|en|nl|pt|de|fr|es|ru|fi|hr|hu|lt|lv|pl|sr)\.mjs$/ */
                        /* webpackChunkName: "ng-locales" */
                        `/node_modules/@angular/common/locales/${getRouteLanguage(lang)}.mjs`
                    ).then((module: any) => {
                        registerLocaleData(module.default);
                    });
                }
                return new TranslateStoreItem(part, lang, result);
            })
        );
    }

    /**
     * returns an array consisting of partnumbers which are needed to load on langChange
     */
    private getPartsNeeded(): number[] {
        return this.translateStore
            .map((m) => m.partId)
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
    }

    public initialize(
        logger: Logger,
        translateService: TranslateService,
        languageService: LanguageService,
        http: HttpClient,
        allParts: Module[],
        defaultPart: number,
        urlPattern: string,
        registerIntlFile: boolean,
        localStorage: Storage
    ): Promise<any> {
        if (this.isInititialized) {
            return Promise.reject('TranslateLoader is already Initialized!');
        } else {
            // Init variables
            this.http = http;
            this.defaultPart = defaultPart;
            this.logger = logger;
            this.translateService = translateService;
            this.languageService = languageService;
            this.urlPattern = urlPattern;
            this.allParts = allParts;
            this.registerIntlFile = registerIntlFile;

            // loads needed parts when language is changed
            this.translateService.onLangChange.subscribe((evt: LangChangeEvent) => {
                from(this.getPartsNeeded())
                    .pipe(
                        mergeMap((x) => {
                            return this.addPart(x, evt.lang);
                        })
                    )
                    .subscribe();
            });

            //clears loader store on translationreset event
            this.translateService.onTranslationChange.subscribe((evt: TranslationChangeEvent) => {
                if (evt.translations === undefined) {
                    this.translateStore = this.translateStore.filter((item) => item.langCode !== evt.lang);
                }
            });

            //loads all needed parts of default language when it changes
            this.translateService.onDefaultLangChange.subscribe((evt: DefaultLangChangeEvent) => {
                from(this.getPartsNeeded())
                    .pipe(
                        mergeMap((x) => {
                            return this.addPart(x, evt.lang);
                        })
                    )
                    .subscribe();
            });

            // Will use Fallback Language if the Language provided in Url Parameter does not exist
            if (this.initialLanguage) {
                this.translateService.use(this.initialLanguage);
            } else {
                // get the language name from the local storage and sets the application language if the language is currently availlable
                const languageNameFromLocalStorage = localStorage.getItem(Constants.storageTokens.language);
                if (languageNameFromLocalStorage) {
                    this.translateService.use(languageNameFromLocalStorage);
                } else {
                    //Sets default languages
                    this.translateService.use(this.translateService.getBrowserLang() ?? this.fallbackLanguage);
                }
            }

            this.translateService.setDefaultLang('default');
            return this.addPart(this.defaultPart)
                .pipe(
                    tap(() => {
                        this.isInititialized = true;
                    })
                )
                .toPromise();
        }
    }
}
