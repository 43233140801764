import { Pipe, PipeTransform } from '@angular/core';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';

@Pipe({ name: 'appSettings' })
export class AppSettingsPipe implements PipeTransform {
    constructor(private appSettings: AppSettings, private logger: Logger) {}

    public transform(appsettingsPath: string): any {
        let result = this.appSettings.getItem(appsettingsPath);
        this.logger.debug(`Delivering Settingspath: ${appsettingsPath} with result ${result} through appSettings Pipe`);
        return result;
    }
}
