<div class="container">
    <shd-headline [headline]="formTitle" [subHeadline]="formSubTitle"></shd-headline>
</div>

<section class="bg-blue stage py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 offset-0 offset-lg-6 col-lg-6">
                <div>
                    <h2 class="mb-4">{{ 'LANDING_ADVANTAGES' | translate }}</h2>
                    @for (advantage of advantages; track advantage) {
                    <div class="d-flex align-items-baseline mt-2">
                        <span class="advantage-circle me-2">
                            <fa-icon [icon]="['fal', 'check']"></fa-icon>
                        </span>
                        <h3 class="h4">{{ advantage | translate }}</h3>
                    </div>
                    }
                </div>
                <div class="mt-5 d-none d-lg-block">
                    <h2>{{ 'LANDING_REGISTRATION_PROCESS' | translate }}</h2>
                    <div class="d-flex mt-4 steps">
                        @for (step of registrationProcess; track step; let last = $last) {
                        <div class="d-flex align-items-center">
                            <div class="d-flex flex-column align-items-center">
                                <fa-icon size="2x" [icon]="step.icon"></fa-icon>
                                <span class="mt-3 text-center">
                                    {{ step.text | translate }}
                                </span>
                            </div>
                            @if (!last) {
                            <fa-icon class="mx-2" [icon]="['fal', 'chevron-right']"></fa-icon>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="registration container">
    <div class="row flex-lg-row flex-column-reverse">
        <div class="col-12 col-lg-6">
            <div class="panel panel-login animated form-panel d-flex flex-column bg-white p-3">
                <div class="panel-heading mb-4">
                    <div class="row">
                        <div class="col-12">
                            <a uiSref="login" uiSrefActive="active" role="button" class="text-uppercase" id="login-form-link">
                                {{ 'LOGINFORM_TITLE' | translate }}
                            </a>
                            <a
                                uiSref="register"
                                uiSrefActive="active"
                                role="button"
                                class="text-uppercase"
                                id="register-form-link">
                                {{ 'REGISTERFORM_TITLE' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-12">
                            <ui-view name="form"></ui-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
