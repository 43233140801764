<div class="data-grid-filter" [class.active]="active">
    <div ngbDropdown [id]="'data-grid-filter-'+field">
        <button
            class="data-grid-filter-toggle"
            type="button"
            ngbDropdownToggle>
            <fa-icon [icon]="['fas', 'filter']" size="sm"></fa-icon>
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
            <div class="data-grid-filter-menu">
                @if (filterMenuTemplate) {
                    <ng-template [ngTemplateOutlet]="filterMenuTemplate"
                                 [ngTemplateOutletContext]="{field: field, filter: filterService, type: type}"></ng-template>
                } @else {
                    <adm-data-grid-filter-dropdown [field]="field"
                                                   [filter]="filterService"
                                                   [type]="type"></adm-data-grid-filter-dropdown>
                }
            </div>
        </div>
    </div>
</div>
