import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayService } from '@obo-common/shared/services/overlay.service';

@Component({
    selector: 'shd-info-button',
    templateUrl: './infoButton.component.html',
    styleUrls: ['./infoButton.component.scss']
})
export class InfoButtonComponent {
    @Input()
    public text: string;
    @Input()
    public title: string;
    @Input()
    public placement: string = 'auto';
    @Input()
    public color: string = '#000000';

    @ViewChild('infoModal')
    modal: TemplateRef<any>;

    constructor(private overlayService: OverlayService, private viewContainerRef: ViewContainerRef) {}

    public openModal(): void {
        this.overlayService.init(this.viewContainerRef);
        this.overlayService.open(this.modal);
    }

    public closeModal(): void {
        this.overlayService.close();
    }
}
