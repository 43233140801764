import { Component, Input } from '@angular/core';

@Component({
    selector: 'adm-data-grid-excel-column',
    template: ''
})
export class DataGridExcelColumnComponent {
    @Input()
    public field: string;
    @Input()
    public title: string;
}
