import { Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { growInAnimation, shrinkOutAnimation } from '@obo-common/shared/animations/shrinkOutGrowIn.animation';

@Component({
    selector: 'shd-confirm-dialog',
    templateUrl: './confirmDialog.component.html',
    styleUrls: ['./confirmDialog.component.scss'],
    animations: [shrinkOutAnimation, growInAnimation]
})
export class ConfirmDialogComponent {
    @Output()
    public confirmed = new EventEmitter<void>();
    @Output()
    public aborted = new EventEmitter<void>();
    @Input()
    public questionKey: string; // translationkey of the question to be displayed on confirmation
    @Input()
    public abortBtnTpl: TemplateRef<any> | undefined; // button template of the abort button when the confirmationdialog is showing
    @Input()
    public confirmBtnTpl: TemplateRef<any> | undefined; // template for the confirm button
    @Input()
    public activateBtnTpl: TemplateRef<any> | undefined; // template of the button whichis needed to be clicked to activate the confirmationdialog
    @Input()
    public labelTpl: TemplateRef<any> | undefined; // template of the question
    @Input()
    public item: any; // item to use as context for this confirmationDialog e.g. if you want to delete a building, this should be the building which can be deleted using this dialog Useful in combination with the labelTpl to show the name or other information in the dialog
    @Input()
    public saveBtnTpl: TemplateRef<any> | undefined;
    public confirmationShowing: boolean = false;
    constructor() {}

    public showConfirmation(): void {
        this.confirmationShowing = true;
    }

    public hideConfirmation(): void {
        this.confirmationShowing = false;
        this.aborted.emit();
    }

    public confirm(): void {
        this.confirmationShowing = false;
        this.confirmed.emit();
    }
}
