import { animate, group, state, style, transition, trigger } from '@angular/animations';

const growIn = transition('void => *', [
    style({ overflow: 'hidden', height: 0, opacity: 0 }),
    animate(400, style({ height: '*', opacity: 1 }))
]);

const shrinkOut = transition('* => void', [
    style({ overflow: 'hidden', height: '!', opacity: 1 }),
    animate(400, style({ height: 0, opacity: 0 }))
]);

export const shrinkOutGrowInAnimation = [trigger('shrinkOutGrowIn', [state('in', style({})), shrinkOut, growIn])];

export const growInAnimation = [trigger('growIn', [state('in', style({})), growIn])];

export const shrinkOutAnimation = [trigger('shrinkOut', [state('in', style({})), shrinkOut])];
