import { Component, Input } from '@angular/core';

@Component({
    selector: 'shd-module-icon',
    templateUrl: './moduleIcon.component.html',
    styleUrls: ['./moduleIcon.component.scss']
})

export class ModuleIconComponent {
    @Input()
    public moduleName: string;
    @Input()
    public color: 'white'
                | 'gray'
                | 'primary-color'
                | 'secondary-color'
                | 'obo-blue-dark'
                | 'black';
}