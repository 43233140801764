<section id="static-contactform">
    <div class="row">
        <form
            name="contactForm"
            id="contact-form"
            (ngSubmit)="submit()"
            #contactForm="ngForm"
            role="form"
            class="col-12 col-md-8">
            <div class="row">
                <div class="col-12 required">
                    <label class="form-label  mr-1">
                        {{ 'PROFILE_USERTITLE' | translate }}
                    </label>
                    <shd-radio-group [(ngModel)]="user.title" [ngModelOptions]="{ standalone: true }">
                        <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MR' | translate">
                            {{ 'REGISTERFORM_USERTITLE_MR' | translate }}
                        </ng-template>
                        <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MRS' | translate">
                            {{ 'REGISTERFORM_USERTITLE_MRS' | translate }}
                        </ng-template>
                    </shd-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 required col-md-6">
                    <label class="form-label" for="firstName">
                        {{ 'REGISTERFORM_FIRSTNAME' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.firstName"
                        type="text"
                        autocomplete="given-name"
                        name="firstName"
                        id="firstName"
                        tabindex="0"
                        class="form-control"
                        value=""
                        required
                        shdValidateInput />
                </div>
                <div class="mb-3 required col-md-6">
                    <label class="form-label" for="lastName">
                        {{ 'REGISTERFORM_LASTNAME' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.lastName"
                        type="text"
                        autocomplete="family-name"
                        name="lastName"
                        id="lastName"
                        tabindex="0"
                        class="form-control"
                        value=""
                        required
                        shdValidateInput />
                </div>
            </div>
            <div class="mb-3 required">
                <label class="form-label" for="email">
                    {{ 'REGISTERFORM_EMAIL' | translate }}
                </label>
                <input
                    [(ngModel)]="user.email"
                    type="email"
                    autocomplete="email"
                    email
                    name="email"
                    id="email"
                    tabindex="0"
                    class="form-control"
                    value=""
                    required
                    shdValidateInput
                    [customErrorMsg]="'PORTAL_DOWNLOADFORM_EMAIL_ERROR' | translate" />
            </div>
            <div class="row">
                <div class="mb-3 col-md-8">
                    <label class="form-label" for="street">
                        {{ 'SHARED_STREET' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.street"
                        autocomplete="address-line1"
                        type="text"
                        name="street"
                        id="street"
                        tabindex="0"
                        class="form-control"
                        value="" />
                </div>
                <div class="mb-3 col-md-4">
                    <label class="form-label" for="housenumber">
                        {{ 'SHARED_HOUSENUMBER' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.houseNumber"
                        autocomplete="address-line2"
                        type="text"
                        name="housenumber"
                        id="housenumber"
                        tabindex="0"
                        class="form-control"
                        value="" />
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-md-4">
                    <label class="form-label" for="postalCode">
                        {{ 'REGISTERFORM_ZIPCODE' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.postalCode"
                        autocomplete="postal-code"
                        type="text"
                        name="postalCode"
                        id="postalCode"
                        tabindex="0"
                        class="form-control"
                        value="" />
                </div>
                <div class="mb-3 col-md-8">
                    <label class="form-label" for="city">
                        {{ 'SHARED_CITY' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.city"
                        type="text"
                        autocomplete="address-level2"
                        name="city"
                        id="city"
                        tabindex="0"
                        class="form-control"
                        value="" />
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label" for="country">
                    {{ 'SHARED_REGION' | translate }}
                </label>
                <input
                    [(ngModel)]="user.country"
                    type="text"
                    autocomplete="country-name"
                    name="country"
                    id="country"
                    tabindex="0"
                    class="form-control"
                    value="" />
            </div>
            <div class="mb-3">
                <label class="form-label" for="phone">
                    {{ 'REGISTERFORM_PHONE' | translate }}
                </label>
                <input
                    [(ngModel)]="user.phoneNumber"
                    autocomplete="tel-national"
                    type="text"
                    name="phone"
                    id="phone"
                    tabindex="0"
                    class="form-control"
                    value="" />
            </div>
            <div class="row">
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label" for="company">
                        {{ 'REGISTERFORM_COMPANY' | translate }}
                    </label>
                    <input
                        [(ngModel)]="user.company"
                        autocomplete="organization"
                        type="text"
                        name="company"
                        id="company"
                        tabindex="0"
                        class="form-control"
                        value="" />
                </div>
                <div class="mb-3 col-12 col-md-6">
                    <label class="form-label" for="customerGroup">
                        {{ 'CUSTOMERGROUP' | translate }}
                    </label>
                    <shd-single-select [(ngModel)]="user.customerGroup" name="customerGroup" id="customerGroup">
                        <ng-template shdSingleSelectLabel>
                            {{ user.customerGroup | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_TRADE'">
                            {{ 'CUSTOMERGROUP_TRADE' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTALLER'">
                            {{ 'CUSTOMERGROUP_INSTALLER' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_GENERALCONTRACTOR'">
                            {{ 'CUSTOMERGROUP_GENERALCONTRACTOR' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_ENDUSER'">
                            {{ 'CUSTOMERGROUP_ENDUSER' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_CONSULTANT'">
                            {{ 'CUSTOMERGROUP_CONSULTANT' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTITUTION'">
                            {{ 'CUSTOMERGROUP_INSTITUTION' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_MANUFACTURER'">
                            {{ 'CUSTOMERGROUP_MANUFACTURER' | translate }}
                        </ng-template>
                        <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_VIP'">
                            {{ 'CUSTOMERGROUP_VIP' | translate }}
                        </ng-template>
                    </shd-single-select>
                </div>
            </div>
            <div class="mb-3 required">
                <label class="form-label" for="message">
                    {{ 'ABOUT_CONTACTFORM_MESSAGE' | translate }}
                </label>
                <textarea
                    [(ngModel)]="user.message"
                    type="textfield"
                    name="Message"
                    id="messagearea"
                    rows="5"
                    minlength="20"
                    maxlength="3000"
                    tabindex="0"
                    class="form-control"
                    value=""
                    required
                    shdValidateInput
                    [customErrorMsg]="'ABOUT_CONTACT_MESSAGEAREA_ERROR' | translate"></textarea>
            </div>
            <div class="mb-3 required">
                <div class="form-check">
                    <input
                        #allowAdvertising="ngModel"
                        type="checkbox"
                        [ngModel]="false"
                        name="allowAdvertising"
                        id="allowAdvertising"
                        class="form-check-input"
                        required />
                    <label class="form-check-label text-muted" for="allowAdvertising">
                        {{ 'CONTACTFORM_ADVERTISING' | translate }}
                    </label>
                    <shd-error-field [control]="allowAdvertising.control"></shd-error-field>
                </div>
            </div>
            <div class="mb-3 required">
                <div class="form-check">
                    <input
                        #dataProtectionAgreement="ngModel"
                        type="checkbox"
                        [ngModel]="false"
                        name="dataProtectionAgreement"
                        id="dataProtectionAgreement"
                        class="form-check-input"
                        required />
                    <label class="form-check-label text-muted" for="dataProtectionAgreement">
                        {{ 'CONTACTFORM_DATA_PROTECTION_AGREEMENT_1' | translate }}
                        <a
                            uiSref="privacy"
                            uiSrefActive="active"
                            class="data-protection-link"
                            [attr.data-track]="'contact_form_privacy'">
                            {{ 'FOOTER_PRIVACYAGREEMENT' | translate }}
                        </a>
                        {{ 'CONTACTFORM_DATA_PROTECTION_AGREEMENT_2' | translate }}
                    </label>
                    <shd-error-field [control]="dataProtectionAgreement.control"></shd-error-field>
                </div>
            </div>
            <p>
                {{ 'CONTACTFORM_MESSAGE' | translate }}
            </p>
            <shd-recaptcha [(valid)]="reCaptchaValid"></shd-recaptcha>
            <small class="form-text text-muted">
                {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
            </small>
            <div class="mb-3 btn-container d-flex justify-content-between mt-3">
                <button shdGoBack class="btn btn-fa" type="button">
                    <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                    {{ 'BTN_GOBACK' | translate }}
                </button>
                <button
                    [disabled]="contactForm.invalid || !reCaptchaValid"
                    type="submit"
                    name="contactform-submit"
                    id="contactForm-submit"
                    tabindex="0"
                    class="btn btn-success">
                    {{ 'ABOUT_CONTACTFORM_SUBMIT' | translate }}
                </button>
            </div>
        </form>
    </div>
</section>
