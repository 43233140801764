import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '@obo-main/services/image/image.service';

@Pipe({
    name: 'imageId'
})
export class ImageIdPipe implements PipeTransform {
    constructor(private readonly imageService: ImageService) {}

    public transform(imageId: string, maxWidth: number = 300, maxHeight: number = 300): string {
        return this.imageService.getImagePath(imageId, maxWidth, maxHeight);
    }
}
