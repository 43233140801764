import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'shd-recaptcha',
    templateUrl: './recaptcha.component.html',
    styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent {
    @Input()
    valid: boolean = false;
    @Output()
    validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    onValidChange($event: any): void {
        this.validChange.emit($event != undefined);
    }
}
