<div id="cableManagement">
    <adm-header>
        <ng-template admHeaderCustomTitle>
            <div class="d-flex admin-header-custom-title">
                <a class="h2 me-3" [class.active]="showCableCategory"
                   (click)="showCableCategory = true">{{ 'ADMIN_CABLELIST_CATEGORIES' | translate }}</a>
                <a class="h2" (click)="showCableCategory = false"
                   [class.active]="!showCableCategory">{{ 'ADMIN_CABLELIST_TITLE' | translate }}</a>
            </div>
        </ng-template>
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_ADD_NEW' | translate"
                    (click)="create(showCableCategory)">
                <fa-icon [icon]="['fal', 'plus']" size="2x"></fa-icon>
            </button>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="cableGrid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid [data]="cableCategoryEditService | async" #cableCategoryGrid
                   (selectionChange)="selectRow($event, true)"
                   [selectable]="true" [class.d-none]="!showCableCategory">
        <adm-data-grid-column field="name" [filterable]="false"
                              [title]="'CABLELIST_CABLE_NAME' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.name }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="wireType" [filterable]="false"
                              [title]="'CABLECATEGORY_WIRETYPE' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ 'CABLECATEGORY_WIRETYPE_' + cableCategoryWireTypeEnum[dataItem.wireType] | uppercase | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="color" [filterable]="false"
                              [title]="'CABLELIST_CABLE_COLOR' | translate">
            <ng-template admDataGridCell let-dataItem>
                <div [style.background-color]="dataItem.color"
                     class="text-center d-flex align-items-center justify-content-center"
                     style="height: 40px">
                    {{ dataItem.color }}
                </div>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="hasImage" [filterable]="false"
                              [title]="'CABLELIST_CABLE_IMAGE' | translate">
            <ng-template
                admDataGridCell
                let-dataItem>
                <div class="text-center">
                    <img
                        [shdApiImageSrc]="'Administration/CableCategories/' + dataItem.id + '/Images'" />
                </div>
            </ng-template>
        </adm-data-grid-column>
    </adm-data-grid>
    <adm-data-grid
        #cableGrid
        id="cable-grid"
        (excelExport)="onExcelExport($event)" [data]="cableEditService | async" [selectable]="true"
        (selectionChange)="selectRow($event)" [class.d-none]="showCableCategory">
        <adm-data-grid-column field="categoryId" [filterable]="false"
                              [title]="'CABLELIST_CABLE_CATEGORY' | translate">
            <ng-template admDataGridCell let-dataItem>
                {{ getCategoryById(dataItem?.categoryId)?.name }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="type" [filterable]="false"
                              [title]="'CABLELIST_CABLE_TYPE' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column field="color" [filterable]="false"
                              [title]="'CABLELIST_CABLE_COLOR' | translate">
            <ng-template admDataGridCell let-dataItem>
                            <span
                                [style.background-color]="dataItem.color || getCategoryById(dataItem?.categoryId)?.color"
                                class="text-center"
                                style="display: block; width: 100%; height: 40px; line-height: 40px">
                                {{ dataItem.color || getCategoryById(dataItem?.categoryId)?.color }}
                            </span>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="diameter" [filterable]="false"
                              [title]="'CABLELIST_CABLE_DIAMETER' | translate">
        </adm-data-grid-column>
        <adm-data-grid-column field="load" [filterable]="false"
                              [title]="'CABLELIST_CABLE_LOAD' | translate">
        </adm-data-grid-column>
        <adm-data-grid-excel fileName="cables.xlsx"
                             [fetchData]="getAllCablesForExcelExport">
            <adm-data-grid-excel-column [title]="'CABLELIST_CABLE_CATEGORY' | translate"
                                        field="categoryId"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column [title]="'CABLELIST_CABLE_TYPE' | translate"
                                        field="type"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column [title]="'CABLELIST_CABLE_COLOR' | translate"
                                        field="color"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column [title]="'CABLELIST_CABLE_DIAMETER' | translate"
                                        field="diameter"></adm-data-grid-excel-column>
            <adm-data-grid-excel-column [title]="'CABLELIST_CABLE_LOAD' | translate"
                                        field="load"></adm-data-grid-excel-column>
        </adm-data-grid-excel>
    </adm-data-grid>
</div>

<ng-template #cableCategoryModal>
    <div class="modal">
        <div class="modal-header">
            <h3 class="mb-0">{{ 'ADMIN_CABLELIST_CATEGORIES' | translate }}</h3>
            <button class="btn-close" (click)="cancelHandler()"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="cableCategoryFormGroup">
                <div class="mb-3 required">
                    <label class="form-label" for="name">
                        {{ 'CABLELIST_CABLE_NAME' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="name"
                        type="text"
                        formControlName="name"
                        shdValidateInput
                        required />
                </div>
                <div class="mb-3 required">
                    <label class="form-label" for="cableCategory">
                        {{ 'CABLELIST_CABLE_CATEGORY' | translate }}
                    </label>
                    <shd-single-select formControlName="wireType" id="cableCategory">
                        <ng-template
                            shdSingleSelectLabel>{{ 'CABLECATEGORY_WIRETYPE_' + cableCategoryWireTypeEnum[cableCategoryFormGroup.controls.wireType.value] | uppercase | translate }}
                        </ng-template>
                        @for (category of cableCategoryWireTypes; track $index) {
                            <ng-template shdSingleSelectOption [value]="category">
                                {{ 'CABLECATEGORY_WIRETYPE_' + cableCategoryWireTypeEnum[category] | uppercase | translate }}
                            </ng-template>
                        }
                    </shd-single-select>
                </div>
                <div class="mb-3 required">
                    <label class="form-label">
                        {{ 'CABLELIST_CABLE_COLOR' | translate }}
                    </label>
                    <ng-template [ngTemplateOutlet]="colorPicker"
                                 [ngTemplateOutletContext]="{$implicit: cableCategoryFormGroup}"></ng-template>
                </div>
                @if (cableCategoryFormGroup.controls.id.value) {
                    <div class="mb-3">
                        <label class="form-label" for="cableCategory">
                            {{ 'CABLELIST_CABLE_IMAGE' | translate }}
                        </label>
                        <div class="text-center">
                            <img
                                class="img-fluid"
                                [shdApiImageSrc]="'Administration/CableCategories/' + cableCategoryFormGroup.controls.id.value + '/Images'" />
                        </div>

                        <div class="btn" (click)="uploadImage.click()">
                            <fa-icon [icon]="['fas', 'camera-retro']" class="me-2"></fa-icon>
                            {{ 'ADMINS_PORTAL_SELECTIMAGE' | translate }}
                            <input
                                #uploadImage
                                type="file"
                                name="file"
                                class="d-none"
                                (click)="$event.target.value = null"
                                (change)="uploadCableCategoryImage($event, cableCategoryFormGroup.value)"
                                accept=".jpeg, .gif, .jpg, .png" />
                        </div>
                    </div>
                }
            </form>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                @if (cableCategoryFormGroup.controls.id.value) {
                    <button class="btn btn-secondary" type="button" (click)="confirmModalRef.show()">
                        {{ 'BTN_DELETE' | translate }}
                    </button>
                }
                <button class="btn btn-success" type="button" [disabled]="cableCategoryFormGroup.invalid"
                        (click)="saveHandler(true)">
                    {{ 'BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cableModal>
    <div class="modal">
        <div class="modal-header">
            <h3 class="mb-0">{{ 'ADMIN_CABLELIST_CATEGORIES' | translate }}</h3>
            <button class="btn-close" (click)="cancelHandler()"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="cableFormGroup">
                <div class="mb-3 required">
                    <label class="form-label" for="cableCategoryId">
                        {{ 'CABLELIST_CABLE_CATEGORY' | translate }}
                    </label>
                    <shd-single-select required formControlName="categoryId" id="cableCategoryId">
                        <ng-template
                            shdSingleSelectLabel>{{ getCategoryById(cableFormGroup.controls.categoryId.value)?.name }}
                        </ng-template>
                        @for (category of cableCategoryEditService?.data.items; track $index) {
                            <ng-template (selected)="setCableColorFromCategory(category)" shdSingleSelectOption
                                         [value]="category.id">{{ category.name }}
                            </ng-template>
                        }
                    </shd-single-select>
                </div>
                <div class="mb-3 required">
                    <label class="form-label">
                        {{ 'CABLELIST_CABLE_COLOR' | translate }}
                    </label>
                    <ng-template [ngTemplateOutlet]="colorPicker"
                                 [ngTemplateOutletContext]="{$implicit: cableFormGroup}"></ng-template>
                </div>
                <div class="mb-3 required">
                    <label class="form-label" for="type">
                        {{ 'CABLELIST_CABLE_TYPE' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="type"
                        type="text"
                        formControlName="type"
                        shdValidateInput
                        required />
                </div>
                <div class="mb-3 required">
                    <label class="form-label" for="diameter">
                        {{ 'CABLELIST_CABLE_DIAMETER' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="diameter"
                        type="number"
                        formControlName="diameter"
                        shdValidateInput
                        required />
                </div>
                <div class="mb-3 required">
                    <label class="form-label" for="load">
                        {{ 'CABLELIST_CABLE_LOAD' | translate }}
                    </label>
                    <input
                        class="form-control"
                        id="load"
                        type="number"
                        formControlName="load"
                        shdValidateInput
                        required />
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                @if (cableFormGroup.controls.id.value) {
                    <button class="btn btn-secondary" type="button" (click)="confirmModalRef.show()">
                        {{ 'BTN_DELETE' | translate }}
                    </button>
                }
                <button class="btn btn-success" type="button" (click)="saveHandler()"
                        [disabled]="cableFormGroup.invalid">
                    {{ 'BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #colorPicker let-formGroup>
    <input
        class="form-control"
        colorPicker
        cpOutputFormat="rgb"
        cpWidth="auto"
        cpAlphaChannel="disabled"
        [cpUseRootViewContainer]="false"
        [cpOKButton]="true"
        cpOKButtonClass="btn btn-success"
        cpOKButtonText="{{ 'BTN_SAVE' | translate }}"
        [cpCancelButton]="true"
        cpCancelButtonClass="btn"
        cpCancelButtonText="{{ 'BTN_ABORT' | translate }}"
        [style.background]="formGroup.controls.color.value"
        cpPresetLabel=""
        [cpPresetColors]="[
                                    '#E13C18',
                                    '#80F01D',
                                    '#0F8EE7',
                                    '#E61DF3',
                                    '#F31D61',
                                    '#fff500',
                                    '#1DE9F3',
                                    '#F3BC1D',
                                    '#1B1A18',
                                    '#ffffff',
                                    '#A29E96'
                                ]"
        cpPosition="bottom"
        (colorPickerSelect)="setColor(formGroup,$event)" />
</ng-template>

<shd-confirm-modal
    #confirmModalRef (confirmed)="delete()"
    [title]=" 'ADMIN_ARE_YOU_SURE' | translate"></shd-confirm-modal>

