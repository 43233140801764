import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { flyOutFlyInAnimation } from '@obo-common/shared/animations/flyInOut.animation';
import { NavElement } from '@obo-main/components/nav/nav.models';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { PortalFeatureEnum, PortalRegion } from '@obo-portal/models/portal.models';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegionService } from '@obo-main/services/region/region.service';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { LanguageService } from '@obo-main/services/language/language.service';
import { Language } from '@obo-main/services/language/language.models';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [flyOutFlyInAnimation]
})
export class NavComponent implements OnInit, OnDestroy {
    public navMyConstruct: NavElement[];
    public navAboutUs: NavElement[];
    public navbarOpen: boolean = false;
    public isLoggedIn: boolean = false;
    public currentYear: string;
    public selectedRegion: PortalRegion;

    private onDestroy = new Subject<any>();

    @ViewChild('toggleSideBarButton')
    public toggleSideBarButton: ElementRef;

    constructor(
        @Inject('BUILD_INFO') public buildInfo: any,
        @Inject('DEBUG') public debugMode: boolean,
        private authService: AuthService,
        private stateService: StateService,
        private regionService: RegionService,
        private languageService: LanguageService,
        public overlayService: OverlayService
    ) {
        this.createNav();
    }

    public ngOnInit(): void {
        this.currentYear = new Date().getFullYear().toString();

        this.isLoggedIn = !this.authService.isTokenExpired(); // sets status of logout button initially
        this.authService.onAuthenticationStatusChange.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
            this.isLoggedIn = status;
        });

        this.selectedRegion = this.regionService.selectedPortalRegion;

        this.regionService.onPortalRegionChange.pipe(takeUntil(this.onDestroy)).subscribe((portalRegion) => {
            this.selectedRegion = portalRegion;
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public openLanguageSelector() {
        const portalRegions: PortalRegion[] = this.regionService.portalRegions;
        const preSelectedRegion: PortalRegion = this.regionService.selectedPortalRegion;
        const preSelectedLanguage: Language = this.languageService.applicationLanguage;
        const languages: Language[] = this.languageService.onAvailableLanguagesChange.getValue();
        this.regionService.openRegionLanguageSelectorModal(
            portalRegions,
            preSelectedRegion,
            (region: PortalRegion) => this.regionService.setPortalRegion(region),
            preSelectedLanguage,
            languages,
            true
        );
    }

    public closeNavbar(): void {
        if (!this.overlayService.isShown) {
            this.navbarOpen = false;
        }
    }

    private createNav() {
        let navMyConstruct: NavElement[] = new Array<NavElement>();

        navMyConstruct.push({
            name: 'login',
            key: 'NAVIGATION_LOGIN',
            uiSref: 'login',
            activeMarker: 'landing',
            faIcon: ['fal', 'sign-in'],
            roles: [Constants.Role.Guest],
            features: []
        });
        navMyConstruct.push({
            name: 'dashboard',
            key: 'NAVIGATION_DASHBOARD',
            uiSref: 'dashboard',
            faIcon: ['fal', 'window-restore'],
            roles: [Constants.Role.User],
            features: [PortalFeatureEnum.BSS, PortalFeatureEnum.Cablefilling, PortalFeatureEnum.GRD, PortalFeatureEnum.UFS]
        });
        navMyConstruct.push({
            name: 'downloadsOverwiew',
            key: 'NAVIGATION_DOWNLOADS_OVERVIEW',
            uiSref: 'downloadsOverwiew',
            faIcon: ['fal', 'download'],
            roles: [Constants.Role.User],
            features: [PortalFeatureEnum.AutoCad, PortalFeatureEnum.CablefillingOld]
        });
        navMyConstruct.push({
            name: 'admin',
            key: 'NAVIGATION_ADMINISTRATION',
            activeMarker: 'admin',
            uiSref: 'admin',
            faIcon: ['fal', 'user-cog'],
            roles: [
                Constants.Role.AccountAdmin,
                Constants.Role.ContentAdmin,
                Constants.Role.ProductAdmin,
                Constants.Role.AnalyticsAdmin
            ],
            features: []
        });

        this.navMyConstruct = navMyConstruct;

        let navAboutUs: NavElement[] = new Array<NavElement>();

        navAboutUs.push({
            name: 'contact',
            key: 'FOOTER_CONTACT',
            uiSref: 'contact',
            faIcon: ['fal', 'user-headset'],
            roles: []
        });
        navAboutUs.push({
            name: 'imprint',
            uiSref: 'imprint',
            key: 'FOOTER_IMPRINT',
            faIcon: ['fal', 'info-square'],
            roles: []
        });
        navAboutUs.push({
            name: 'termsOfService',
            uiSref: 'termsOfService',
            key: 'FOOTER_TERMS_OF_SERVICE',
            faIcon: ['fal', 'file-signature'],
            roles: []
        });
        navAboutUs.push({
            name: 'privacy',
            uiSref: 'privacy',
            key: 'FOOTER_PRIVACYAGREEMENT',
            faIcon: ['fal', 'shield'],
            roles: []
        });

        this.navAboutUs = navAboutUs;
    }

    /**
     * checks if the given stateName or a childstate is active
     * @param item
     */
    public checkActiveMarker(item: NavElement): boolean {
        let name: string = item.activeMarker || item.uiSref;
        return this.stateService.includes(name);
    }

    /**
     * uses authservice to perform the logout
     */
    public logout() {
        this.authService.logout();
        this.navbarOpen = false;
        this.stateService.go('login');
    }
}
