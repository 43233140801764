import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@obo-main/services/language/language.service';
import { Motd, MotdMessage } from '@obo-main/services/motd/motd.models';
import { MotdService } from '@obo-main/services/motd/motd.service';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { Constants } from 'app/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-motd',
    templateUrl: './motd.component.html',
    styleUrls: ['./motd.component.scss']
})
export class MotdComponent implements OnInit, OnDestroy {
    public seenIds: Array<number> = new Array();
    public activeMotdList: Motd[] = new Array();

    public toasts: any[] = [];

    private motds: MotdMessage[][];
    private onDestroy = new Subject<any>();

    constructor(
        @Inject(LocalStorage) private localStorage: Storage,
        private viewContainerRef: ViewContainerRef,
        private translateService: TranslateService,
        private motdService: MotdService,
        private globalizationService: LanguageService
    ) {}

    public ngOnInit(): void {
        const seenList = this.getSeenList();
        if (seenList != null) {
            this.seenIds = seenList.split(',').map((id) => Number.parseInt(id));
        }
        this.motdService.getMotdMessages().subscribe((motdList) => {
            this.motds = motdList;
            this.translateService.onLangChange.pipe(takeUntil(this.onDestroy)).subscribe(() => this.fillArray());
            this.fillArray();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public dismiss(id: number): void {
        this.seenIds.push(id);
        this.localStorage.setItem(Constants.storageTokens.seen_motd_list, this.seenIds.join(','));
        this.activeMotdList = this.activeMotdList.filter((motd) => motd.id !== id);
    }

    private getSeenList(): string | null {
        return this.localStorage.getItem(Constants.storageTokens.seen_motd_list);
    }

    private fillArray(): void {
        this.globalizationService.findFallbackLanguage(0).subscribe((fallBackLang) => {
            this.activeMotdList = this.motds
                .filter((motdGroup) => !this.seenIds.includes(motdGroup[0].id))
                .map((motdGroup) => {
                    const messageObj = motdGroup.find((m) => m.cultureName === this.translateService.currentLang);
                    const backupObj = motdGroup.find((m) => m.cultureName === fallBackLang.name);
                    return {
                        id: motdGroup[0].id,
                        title: messageObj ? messageObj.title : backupObj ? backupObj.title : '',
                        message: messageObj ? messageObj.message : backupObj ? backupObj.message : ''
                    } as Motd;
                })
                .filter((motd) => motd.message !== '');
        });
    }
}
