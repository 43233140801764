import { Component, OnInit } from '@angular/core';
import { ElbridgeService } from '@obo-main/services/elbridge/elbridge.service';
import { ElbridgeInfo } from '@obo-main/services/elbridge/elbridge.models';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-elbridge-banner',
    templateUrl: './elbridgeBanner.component.html',
    styleUrls: ['./elbridgeBanner.component.scss']
})
export class ElbridgeBannerComponent implements OnInit {
    public elbridgeInfo: Observable<ElbridgeInfo | null>;
    constructor(private elbridgeService: ElbridgeService) {}

    public ngOnInit() {
        this.elbridgeInfo = this.elbridgeService.elbridgeInfo.asObservable();
    }

    public elbridgeAbortFn = () => {
        return this.elbridgeInfo.pipe(
            take(1),
            tap(() => this.elbridgeService.removeElbridgeInfoFromCache())
        );
    };
}
