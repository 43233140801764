import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '@obo-main/services/image/image.service';

@Pipe({
    name: 'apiImage'
})
@Injectable()
export class ApiImagePipe implements PipeTransform {
    constructor(private imageService: ImageService) {}

    public transform(value?: string, args?: any): string | undefined {
        return value ? this.imageService.getImagePath(value) : undefined;
    }
}
