import { Component, Inject, OnDestroy, Output } from '@angular/core';
import { AdminNavElement } from '@obo-admin/admin.models';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { StateDeclaration, UIRouter } from '@uirouter/core';
import { Constants } from 'app/constants';
import { Subject, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileSavingService } from '@obo-common/shared/services/fileSaving.service';

@Component({
    selector: 'adm-router-overview',
    templateUrl: './adminOverview.component.html',
    styleUrls: ['./adminOverview.component.scss']
})
export class AdminOverviewComponent implements OnDestroy {
    @Output()
    public closed = new Subject<any>();
    public adminNav: AdminNavElement[] = new Array<AdminNavElement>();
    constructor(
        @Inject('BASE_URL') private baseUrl: string,
        private router: UIRouter,
        private spinnerService: SpinnerService,
        private fileSaver: FileSavingService
    ) {
        this.initializeNav();
    }

    public ngOnDestroy(): void {
        this.closed.next(1);
        this.closed.complete();
    }

    private initializeNav(): void {
        this.adminNav = <AdminNavElement[]>[
            {
                key: 'NAVIGATION_USERS',
                name: 'UserManagement',
                uiSref: 'userManagement',
                description: 'NAVIGATION_USERS_DESC',
                icon: ['fal', 'users'],
                roles: [Constants.Role.AccountAdmin]
            },
            {
                key: 'NAVIGATION_LANGUAGES',
                name: 'languageManagement',
                uiSref: 'cultureManagement',
                description: 'NAVIGATION_LANGUAGES_DESC',
                icon: ['fal', 'comment-alt-lines'],
                roles: [Constants.Role.ContentAdmin]
            },
            {
                key: 'NAVIGATION_TRANSLATION',
                name: 'translationManagement',
                uiSref: 'translationManagement',
                description: 'NAVIGATION_TRANSLATION_DESC',
                icon: ['fal', 'language'],
                roles: [Constants.Role.ContentAdmin]
            },
            {
                key: 'NAVIGATION_PORTALCONFIG',
                name: 'portalManagement',
                uiSref: 'portalManagement',
                description: 'NAVIGATION_PORTALCONFIG_DESC',
                icon: ['fal', 'cogs'],
                roles: [Constants.Role.ContentAdmin]
            },
            {
                key: 'NAVIGATION_MOTDMANAGEMENT',
                name: 'motdManagement',
                uiSref: 'motdManagement',
                description: 'NAVIGATION_MOTDMANAGEMENT_DESC',
                icon: ['fal', 'newspaper'],
                roles: [Constants.Role.ContentAdmin]
            },
            {
                key: 'NAVIGATION_REGIONMANAGEMENT',
                name: 'regionManagement',
                uiSref: 'regionManagement',
                description: 'NAVIGATION_REGIONMANAGEMENT_DESC',
                icon: ['fal', 'globe-americas'],
                roles: [Constants.Role.ContentAdmin]
            },
            {
                key: 'NAVIGATION_PRODUCTMANAGEMENT',
                name: 'productManagement',
                uiSref: 'productManagement',
                description: 'NAVIGATION_PRODUCTMANAGEMENT_DESC',
                icon: ['fal', 'truck-loading'],
                roles: [Constants.Role.ProductAdmin]
            },
            {
                key: 'NAVIGATION_PRODUCTMANAGEMENT_DATABASELOOKUP',
                name: 'productManagement',
                uiSref: 'databaseLookup',
                description: 'NAVIGATION_PRODUCTMANAGEMENT_DATABASELOOKUP_DESC',
                icon: ['fal', 'database'],
                roles: [Constants.Role.ProductAdmin]
            },
            {
                key: 'NAVIGATION_ANALYTICS',
                name: 'analyticsManagement',
                uiSref: 'analyticsManagement',
                description: 'NAVIGATION_ANALYTICS_DESC',
                icon: ['fal', 'analytics'],
                roles: [Constants.Role.AnalyticsAdmin]
            }
        ];
    }

    public downloadSitemap(): void {
        this.spinnerService.startSpinner();
        timer(15000)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe(() => {
                const states = this.router.stateRegistry.get();
                const buildUrl = (parentState: string | StateDeclaration | undefined, url: string): string => {
                    if (!parentState) {
                        return url;
                    }
                    const parent = this.router.stateRegistry.get(parentState);
                    if (parent.name === 'app') {
                        return url;
                    }
                    return buildUrl(parent.parent, `${parent.url}${url}`);
                };
                const urls = states
                    .filter((s) => !!s.url)
                    .map((s) => buildUrl(s.parent, s.url!))
                    .filter((u) => !!u && u !== '^') as string[];
                const baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1);
                const content = urls.reduce((acc, curr) => `${acc}${baseUrl}${curr}\n`, '');
                this.fileSaver.saveFile(
                    new Blob([content], { type: 'text/plain' }),
                    `Construct_SiteMap_${new Date().toLocaleDateString()}.txt`
                );
            });
    }
}
