import { Component, Input } from '@angular/core';

@Component({
    selector: 'shd-dashed-headline',
    template: '<div [class.whiteBg]="isWhiteBg"><ng-content></ng-content></div>',
    styleUrls: ['./dashed-headline.component.scss']
})
export class DashedHeadlineComponent {
    @Input()
    isWhiteBg: boolean = false;
}
