import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
    constructor(private title: Title, private meta: Meta) {}

    public updateTitle(title: string) {
        this.title.setTitle(title);
    }

    public updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc });
    }
}
