<section id="downloads">
    <shd-headline [headline]="'DOWNLOADS_OVERVIEW_TITLE'"></shd-headline>

    @if (cadIsInFeatures) {
    <div>
        <h3>{{ 'DASHBOARD_KTSCAD_DISPLAYNAME' | translate }}</h3>
        <ul class="list-unstyled">
            @for (version of versions; track version) {
            <li>
                <a
                    class="btn btn-link p-0"
                    [attr.data-track]="'dashboard_download_autocad'"
                    (click)="downloadFile(downloadType.Kts, version.value)">
                    {{ version.title }}
                </a>
            </li>
            }
        </ul>
    </div>
    } @if (cfOldIsInFeatures) {
    <div>
        <h3>{{ 'DASHBOARD_CABLEFILLING_OLD_DISPLAYNAME' | translate }}</h3>
        <ul class="list-unstyled">
            <li>
                <a
                    class="btn-link"
                    [attr.data-track]="'dashboard_download_cablefilling'"
                    (click)="downloadFile(downloadType.CableFilling)">
                    OBOConstructCablefillingSetup_v3
                </a>
            </li>
        </ul>
    </div>
    }
</section>
