import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegionService } from '@obo-main/services/region/region.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Observable } from 'rxjs';

@Injectable()
export class RegionInterceptor implements HttpInterceptor {
    constructor(private appSettings: AppSettings, private regionService: RegionService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.appSettings.init) {
            return next.handle(request); // do nothing while not initialized :/
        }
        const currentRegion = this.regionService.selectedApplicationRegion;
        if (currentRegion != null) {
            const newRequest = request.clone({
                headers: request.headers.set('Accept-Region', currentRegion!.name)
            });
            return next.handle(newRequest);
        } else {
            return next.handle(request);
        }
    }
}
