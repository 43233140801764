import { LanguageService } from '@obo-main/services/language/language.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { TranslateHttpLoader } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.service';
import { UIRouter } from '@uirouter/core';
import { Injector } from '@angular/core';
import { AppSettingsFactory } from '@obo-main/factories/appSettings.factory';
import { LoggerFactory } from '@obo-main/factories/logger.factory';
import { AuthServiceFactory } from '@obo-main/factories/authService.factory';
import { TranslateLoaderFactory } from '@obo-main/factories/translateLoader.factory';
import { RouterConfigFactory } from '@obo-main/factories/uiRouterConfig.factory';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { RegionService } from '@obo-main/services/region/region.service';

export function OrderedInitializerFactory(
    regionService: RegionService,
    languageService: LanguageService,
    authService: AuthService,
    logger: Logger,
    translateService: TranslateService,
    http: HttpClient,
    appSettings: AppSettings,
    translateLoader: TranslateHttpLoader,
    uiRouter: UIRouter,
    injector: Injector,
    localStorage: Storage
) {
    return function () {
        return AppSettingsFactory(appSettings).then(() => {
            return LoggerFactory(logger, appSettings.getItem('settings.logger_level')).then(() => {
                return languageService.initialize().then(() => {
                    return Promise.all([
                        // run these parallel
                        AuthServiceFactory(authService),
                        TranslateLoaderFactory(
                            logger,
                            translateService,
                            languageService,
                            http,
                            appSettings,
                            translateLoader,
                            localStorage
                        )
                    ]).then(() => {
                        regionService.initialize().then();
                        return RouterConfigFactory(uiRouter, injector);
                    });
                });
            });
        });
    };
}
