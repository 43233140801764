import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { ElbridgeData, ElbridgeInfo } from '@obo-main/services/elbridge/elbridge.models';

@Injectable({
    providedIn: 'root'
})
export class ElbridgeFormService {
    public getElbridgeInfoForm(renderer: Renderer2, elementRef: ElementRef, elbridgeInfo: ElbridgeInfo): HTMLFormElement {
        const form = ElbridgeFormService.createForm(renderer, elementRef, elbridgeInfo.hookUrl, elbridgeInfo.target);
        ElbridgeFormService.appendLineToForm(renderer, form, 'version', elbridgeInfo.version);
        ElbridgeFormService.appendLineToForm(renderer, form, 'value', '');
        return form;
    }

    public getElbridgeDataForm(renderer: Renderer2, elementRef: ElementRef, elbridgeData: ElbridgeData): HTMLFormElement {
        return Object.entries(elbridgeData).reduce(
            (form, [name, value]) => ElbridgeFormService.appendLineToForm(renderer, form, name, value),
            ElbridgeFormService.createForm(renderer, elementRef, elbridgeData.hookUrl, elbridgeData.target)
        );
    }

    private static appendLineToForm(renderer: Renderer2, form: HTMLFormElement, name: string, value: string): HTMLFormElement {
        const input = renderer.createElement('input');
        renderer.setAttribute(input, 'type', 'text');
        renderer.setAttribute(input, 'name', name);
        renderer.setAttribute(input, 'value', value);
        renderer.appendChild(form, input);
        return form;
    }

    private static createForm(renderer: Renderer2, elementRef: ElementRef, actionUrl: string, target: string): HTMLFormElement {
        const form = renderer.createElement('form') as HTMLFormElement;
        form.action = actionUrl;
        form.method = 'POST';
        form.target = target;
        renderer.setStyle(form, 'display', 'none'); // hide the form
        renderer.appendChild(elementRef.nativeElement, form);
        return form;
    }
}
