import { Transition, TransitionService } from '@uirouter/core';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Logger } from '@obo-main/utils/logger/logger.service';

/**
 * Checks is the user has all of the permissions defined in states data -> permissions array
 * @param transitionService
 */
export function moduleActiveHook(transitionService: TransitionService) {
    const moduleActiveCriteria = {
        entering: (state: any) => state.data && state.data.module
    };

    const action = (transition: Transition) => {
        const moduleService = transition.injector().get(ModuleService) as ModuleService;
        const logger = transition.injector().get(Logger) as Logger;
        const $state = transition.router.stateService;
        if (!moduleService.getModuleByName(transition.to().data.module).isActive) {
            logger.error(`The Route you want to enter is currently disabled: ${transition.to().name}.`);
            return false;
        }
    };

    // add the hook to transitionsservice
    transitionService.onBefore(moduleActiveCriteria, action, { priority: 40 });
}
