import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringFormat'
})
/**
 * replaces placesholders from the input with given placeholder elements.
 */
export class StringFormatPipe implements PipeTransform {
    private StringFormat = (str: string, args: string[]) => str.replace(/{(\d+)}/g, (match, index) => args[index] || match);

    public transform(template: string, placeHolders: string[]): string {
        return this.StringFormat(template, placeHolders);
    }
}
