import { Inject, Injectable } from '@angular/core';
import { CableCategory } from '@obo-common/shared/models/cable.models';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '@obo-main/services/image/image.service';
import { Observable, of } from 'rxjs';
import { catchError, concatAll, map, mergeMap, tap, toArray } from 'rxjs/operators';

@Injectable()
export class CableCategoryService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private imageService: ImageService) {}

    public getCableCategories(): Observable<CableCategory[]> {
        return this.retrieveCableCategories();
    }

    private retrieveCableCategories(): Observable<CableCategory[]> {
        return this.http.get<CableCategory[]>(`${this.apiPrefix}Filling/CableCategories`).pipe(
            concatAll(),
            mergeMap((cableCategoryData) =>
                (cableCategoryData.hasImage ? this.retrieveCableCategoryImage(cableCategoryData) : of('')).pipe(
                    map((image) => {
                        const category = CableCategory.fromApiData(cableCategoryData);
                        category.image = image;
                        return category;
                    })
                )
            ),
            toArray()
        );
    }

    private retrieveCableCategoryImage(category: CableCategory): Observable<string> {
        return this.imageService.loadApiImage(`Filling/CableCategories/${category.id}/Images`).pipe(catchError(() => of('')));
    }
}
