<section class="no-padding m-0">
    <div class="infinite-carousel" (mouseenter)="cycleActive = false">
        <div
            class="viewport my-4"
            [ngClass]="{ 'viewport-with-grid': useGrid }"
            shdMouseWheel
            (shdMouseWheelUp)="prev()"
            (shdMouseWheelDown)="next()"
            [disableMouseWheelEvent]="!showControls"
            #viewPort>
            <div class="infinite-carousel-items row" [attr.style]="useGrid ? '' : 'gap: ' + gap + 'px'" #itemContainer>
                @for (item of itemsArray; track trackByFn($index, item)) {
                <div
                    [id]="item.identifier"
                    class="infinite-carousel-item position-relative"
                    [ngClass]="useGrid ? gridClasses : ''"
                    #itemRef>
                    <ng-template
                        [ngTemplateOutlet]="item.templateRef"
                        [ngTemplateOutletContext]="{
                            $implicit: this
                        }"></ng-template>
                </div>
                }
            </div>
        </div>
        @if (showControls) {
        <div class="controls">
            <button class="btn btn-fa text-white" type="button" (click)="next()">
                <fa-icon [icon]="['fal', 'chevron-left']" size="3x"></fa-icon>
            </button>
            <button class="btn btn-fa text-white" type="button" (click)="prev()">
                <fa-icon [icon]="['fal', 'chevron-right']" size="3x"></fa-icon>
            </button>
        </div>
        }
    </div>
</section>
