<h2>{{ 'BUILDING_MODULES_PLANNED' | translate }}</h2>
<div class="plannings-grid mb-5">
    @if (plannedPlannings.length > 0) { @for (planning of plannedPlannings; track planning) {
    <app-planning-card
        [planning]="planning"
        [buildingId]="buildingId"
        [projectId]="projectId"
        [isMobile]="isMobile"
        (configuratorNonMobile)="openWarningModal()"
        (reloadPlannings)="reloadBuildings.emit(buildingId)"></app-planning-card>
    }
    <!-- pseudo elements to keep grid flow, if the planning count is lower than 3 -->
    <div class="pseudo"></div>
    <div class="pseudo"></div>
    } @else {
    {{ 'BUILDING_NO_PLANNING_YET' | translate }}
    }
</div>

@if (unplannedPlannings.length > 0) {
<h2>{{ 'BUILDING_MODULES_UNPLANNED' | translate }}</h2>
<div class="plannings-grid">
    @for (planning of unplannedPlannings; track planning) {
    <app-planning-card
        [planning]="planning"
        [buildingId]="buildingId"
        [projectId]="projectId"
        [isMobile]="isMobile"
        (configuratorNonMobile)="openWarningModal()"></app-planning-card>
    }
    <!-- pseudo elements to keep grid flow, if the planning count is lower than 3 -->
    <div class="pseudo"></div>
    <div class="pseudo"></div>
</div>
}

<shd-desktop-only-modal #desktopOnlyModalTpl></shd-desktop-only-modal>
