<input type="text" required class="form-control form-control-border"
       [(ngModel)]="filterValue" />
<div class="data-grid-filter-buttons">
    <button type="button" class="btn btn-secondary" (click)="clear()">Clear</button>
    <button type="button" class="btn btn-success"
            [disabled]="!filterValue"
            (click)="applyFilter()">Filter
    </button>
</div>

               
