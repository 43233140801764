import { Inject, Injectable } from '@angular/core';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Module } from '@obo-main/services/modules/module.models';

@Injectable()
export class ModuleService {
    constructor(private appSettings: AppSettings, private logger: Logger) {}

    /**
     * returns the requested module by its id
     * @param id
     */
    public getModuleById(id: number): Module {
        let module = this.modules.find((m) => m.id === id);
        if (!module) {
            throw new Error(`No Module found for Id ${id}`);
        }
        return module;
    }

    /**
     * returns the requested module by its name
     * @param name
     */
    public getModuleByName(name: string): Module {
        let module = this.modules.find((m) => m.name === name);
        if (!module) {
            throw new Error(`No Module found for Name ${name}`);
        }
        return module;
    }

    /**
     * returns all Modules which are configurable and therefore have a summarysection
     */
    public getModulesWithPlanning(): Module[] {
        return this.modules.filter((m) => m.isActive && m.hasPlanning);
    }

    /**
     * Returns an Array filled with all modules
     */
    public get modules(): Module[] {
        var modules = this.appSettings.getItem('modules');
        if (!Array.isArray(modules)) {
            throw new Error(`Error Reading Modules from AppSettings. Expected Array, got ${modules}`);
        }
        return modules as Module[];
    }
}
