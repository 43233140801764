import { Pipe, PipeTransform } from '@angular/core';

export const metaProductNamePipeDisable = 'disableProductNamePipe';

@Pipe({ name: 'productName' })
export class ProductNamePipe implements PipeTransform {
    private readonly allowedAppends = [',', '.', ';'];
    private readonly defaultPropsToUse = ['header'];

    public transform(product: any, ...propsToUse: string[]) {
        if (Reflect.hasMetadata(metaProductNamePipeDisable, product.constructor)) {
            return '';
        }
        if (!Array.isArray(propsToUse) || propsToUse.length === 0 || propsToUse.some((p) => p === undefined)) {
            propsToUse = this.defaultPropsToUse;
        }
        return propsToUse.reduceRight((result, currentProp) => {
            const lastLetter = currentProp[currentProp.length - 1];
            const append = this.allowedAppends.includes(lastLetter) ? lastLetter : '';
            const part = append !== '' ? product[currentProp.slice(0, currentProp.length - 1)] : product[currentProp];
            return part ? `${part}${result !== '' ? append : ''} ${result}` : result;
        }, '');
    }
}
