import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Address, Building, ExtendedPlanning } from '@obo-dashboard/models/projectManagement.models';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { PlanningService } from '@obo-main/services/plannings/planning.service';
import { Planning } from '@obo-main/services/plannings/plannings.models';
import { RegionService } from '@obo-main/services/region/region.service';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class BuildingService {
    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private http: HttpClient,
        private moduleService: ModuleService,
        private planningService: PlanningService,
        private regionService: RegionService
    ) {}

    public getEmptyBuilding(): Building {
        const building = new Building();
        building.regionId = this.regionService.selectedApplicationRegion.id;
        return building;
    }

    /**
     * retrieves a building from api or creates a new building when buildingId is -1
     * @param buildingId
     */
    public getBuilding(projectId: number, buildingId: number): Observable<Building> {
        if (buildingId === -1) {
            return of(this.getEmptyBuilding());
        } else {
            return this.http.get(this.buildingEndpointUrl(projectId, buildingId)) as Observable<Building>;
        }
    }

    /**
     * retrieves the building plannings from api or returns an empty array when buildingId is -1
     * @param buildingId
     */
    public getPlanningsByBuilding(projectId: number, buildingId: number): Observable<Planning[]> {
        if (buildingId === -1) {
            return of([]);
        } else {
            return this.planningService.getPlannings(projectId, buildingId);
        }
    }

    /**
     * Use this function to save a building. Depending on the existance of the id property, the service will use post or put
     * @param project
     */
    public createOrUpdateBuilding(projectId: number, building: Building) {
        if (building.id > 0) {
            return this.updateBuilding(projectId, building);
        } else {
            return this.createBuilding(projectId, building);
        }
    }

    /**
     * extends the given planningArray to an Array of Type ExtendedPlanning
     * @param plannings
     */
    public extendPlannings(plannings: Planning[]): ExtendedPlanning[] {
        return plannings.reduce((result, planning) => {
            const extPlanning = new ExtendedPlanning();
            Object.assign(extPlanning, planning);
            extPlanning.planningState = this.planningService.getPlanningState(planning);
            extPlanning.planningTileUrl = this.planningService.getPlanningTileUrl(planning);
            extPlanning.name = this.moduleService.getModuleById(planning.moduleId).name;
            return [...result, extPlanning];
        }, new Array<ExtendedPlanning>());
    }

    /**
     * Updates an existing Building
     * @param building
     */
    private updateBuilding(projectId: number, building: Building): Observable<Building> {
        return this.http.put<Building>(this.buildingEndpointUrl(projectId, building.id), building);
    }

    /**
     * Creates a new Building
     * @param building
     */
    private createBuilding(projectId: number, building: Building): Observable<Building> {
        return this.http.post<Building>(`${this.apiPrefix}Projects/${projectId}/Buildings`, building);
    }

    /**
     * Delete a Building
     * @param building
     */
    public deleteBuilding(projectId: number, building: Building): Observable<any> {
        return this.http.delete(this.buildingEndpointUrl(projectId, building.id));
    }

    /**
     * duplicates the given building of the provided projectId and returns the newly created building
     * @param projectId
     * @param building
     */
    public duplicateBuilding(projectId: number, building: Building): Observable<Building> {
        return this.http.request('copy', this.buildingEndpointUrl(projectId, building.id)) as Observable<Building>;
    }

    private buildingEndpointUrl(projectId: number, buildingId: number): string {
        return `${this.apiPrefix}Projects/${projectId}/Buildings/${buildingId}`;
    }

    public static convertPlaceResultToAddress(placeResult: google.maps.places.PlaceResult): Address {
        const address = new Address();

        address.latitude = placeResult.geometry?.location.lat();
        address.longitude = placeResult.geometry?.location.lng();

        placeResult.address_components.forEach((c) => {
            if (c.types.some((t) => t === 'locality')) address.city = c.long_name;
            else if (c.types.some((t) => t === 'postal_code')) address.postalCode = c.long_name;
            else if (c.types.some((t) => t === 'route')) address.street = c.long_name;
            else if (c.types.some((t) => t === 'street_number')) address.houseNumber = c.long_name;
        });

        return address;
    }
}
