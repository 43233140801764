import { UntypedFormControl } from '@angular/forms';
import { FilterPrediction, OboFilter, OboFilterTagGroup, OboFilterTagGroupAccessor } from '@obo-common/filter/models/Filter';
import { Observable, empty, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * SingleFilter. Use this class as FormControl on SingleSelectDropdownFilterComponent
 * @extends FormControl
 * @implements OboFilter, OboFilterTagGroupAccessor, FilterPrediction
 */
export class OboSingleSelectFilter<T>
    extends UntypedFormControl
    implements OboFilter<T>, OboFilterTagGroupAccessor<T>, FilterPrediction<T>
{
    // @ts-ignore
    get value(): OboSingleSelectFilterOption<T> {
        return this.selectedOption;
    }

    set value(option: OboSingleSelectFilterOption<T>) {
        this.selectedOption = option;
    }
    get isActive(): boolean {
        return this.selectedOption != null;
    }
    initialValue: OboSingleSelectFilterOption<T>;
    selectedOption: OboSingleSelectFilterOption<T>;
    hasPrediction: boolean;
    hasFilterTag: boolean;
    label: string;
    options: Array<OboSingleSelectFilterOption<T>> = [];

    constructor(
        label: string,
        hasPrediction: boolean,
        options: Array<OboSingleSelectFilterOption<T>>,
        hasFilterTag: boolean = true,
        initialvalue?: OboSingleSelectFilterOption<T>
    ) {
        super(initialvalue);
        this.options = options;
        this.hasFilterTag = hasFilterTag;
        this.label = label;
        this.hasPrediction = hasPrediction;
    }

    reset(value?: any, opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
        super.reset(value || this.initialValue, opts);
    }

    /**
     *
     * @param item checks if the item fulfills the filter
     */
    checkItem(item: T): boolean {
        const hasPredictionCalculationActive = this.options.find((o) => o.predictionCalcMode);
        if (hasPredictionCalculationActive) {
            return hasPredictionCalculationActive.filterFn(item, hasPredictionCalculationActive.filterValue);
        } else {
            return this.isActive ? this.selectedOption.filterFn(item, this.selectedOption.filterValue) : true;
        }
    }
    /**
     * returns the tags for this filter
     */
    getTags(): OboFilterTagGroup {
        return {
            label: this.label,
            tags: this.isActive
                ? [
                      {
                          label: this.selectedOption.label,
                          reset: this.reset.bind(this)
                      }
                  ]
                : []
        };
    }

    /**
     *
     * @param itemList the productsList at the current time. Since the calculation should be performed after filtering, it should the the currently displaying list
     * @param applyFilterFn the function which will deliver the filtered ProductsList
     */
    calculatePrediction(itemList: T[], applyFilterFn: () => Observable<T[]>): Observable<boolean> {
        if (!this.hasPrediction) {
            return empty();
        }
        return from(this.options).pipe(
            mergeMap((c) => {
                c.predictionCalcMode = true;
                return applyFilterFn().pipe(
                    mergeMap((filteredItemList) => {
                        const count = filteredItemList.filter((item) => c.filterFn(item, c.filterValue)).length;
                        c.prediction = `(${count})`;
                        c.predictionCalcMode = false;
                        return empty();
                    })
                );
            })
        );
    }
}

/**
 * Option for the SingleSelectDropdown
 */
export class OboSingleSelectFilterOption<T> {
    label: string;
    prediction?: string;
    filterFn: (item: T, filterValue: any) => any;
    filterValue: any;
    predictionCalcMode: boolean = false;

    constructor(label: string, filterValue: any, filterFn: (item: T, filterValue: any) => boolean) {
        this.label = label;
        this.filterValue = filterValue;
        this.filterFn = filterFn;
    }
}
