import { Component, Input, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

let id: number = 0;

@Component({
    selector: 'shd-contextmenu',
    templateUrl: './contextmenu.component.html',
    styleUrls: ['./contextmenu.component.scss']
})
export class ContextmenuComponent {
    public contextMenuId: string = `contextMenu-${id++}`;

    @Input()
    public title: string | undefined;
    @Input()
    public hasDivider: boolean = false;
    @Input()
    overflowHidden: boolean = false;
    @Input()
    disabled: boolean = false;
    @Input()
    size: string = '2x';

    @ViewChild(NgbDropdown)
    public dropdown: NgbDropdown;
}
