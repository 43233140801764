import { OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectorRef,
    Component,
    Inject,
    Injector,
    OnDestroy,
    OnInit,
    QueryList,
    signal,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Culture } from '@obo-admin/admin.models';
import { TranslationManagementService } from '@obo-admin/translationManagement/translationManagement.service';
import { OboMultiSelectFilter, OboMultiSelectFilterOption } from '@obo-common/filter/models/multiSelect';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { Constants } from 'app/constants';
import { forkJoin, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { GridRow } from './models/translationManagementGridRow.type';
import { TranslationExportModalComponent } from './translationExportModal/translationExportModal.component';
import { OVERLAY_XL_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { DataGridFilterOperator, DataGridResult } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { DataGridColumnComponent } from '@obo-admin/dataGrid/components/dataGridColumn/dataGridColumn.component';
import { TranslationItem, TranslationItemDto } from '@obo-admin/translationManagement/models/translationManagementItem.model';
import { DataGridFilterInputComponent } from '@obo-admin/dataGrid/components/dataGridFilterInput/dataGridFilterInput.component';

@Component({
    selector: 'adm-router-translate-management',
    templateUrl: './translationManagement.component.html',
    styleUrls: ['./translationManagement.component.scss']
})
export class TranslationManagementComponent implements OnInit, OnDestroy {
    public modules: { id: number; name: string }[];
    public cultures: Culture[] = [];
    public filteredCultures: (Culture & { active: boolean })[];
    public selectedRow?: GridRow;
    public moduleMap: { [key: number]: string } = {};
    public cultureMap: { [key: string]: string } = {};
    public enableHTML: boolean = false;
    public xmlCharactersPattern = `^[^"<>&]*`;
    public formGroup?: UntypedFormGroup;
    public readonly data = signal<DataGridResult>({ items: [], count: 0 });
    @ViewChild('grid')
    public grid: DataGridComponent;
    public filterFormGroup = new UntypedFormGroup({
        modules: new OboMultiSelectFilter(this.translateService.instant('ADMIN_TRANSLATION_MODULE'), false, []),
        languages: new OboMultiSelectFilter(this.translateService.instant('ADMIN_TRANSLATION_LANGUAGES'), false, [])
    });

    public get languageColumns(): DataGridColumnComponent[] {
        return this.grid.columns.filter(
            (c) => !['key', 'moduleId', 'modificationDate'].includes(c.field)
        ) as DataGridColumnComponent[];
    }

    public get visibleColumnCodes(): string[] {
        return this.languageColumns.filter((c) => !c.hidden).map((c) => c.field);
    }

    @ViewChild('translationLanguagesNav')
    public translationLanguagesNav: NgbNav;
    @ViewChild('editTranslationModalTpl')
    public editTranslationModalTpl: TemplateRef<any>;
    @ViewChildren('languageColumnFilter')
    public languageColumnFilterComponents: QueryList<DataGridFilterInputComponent>;

    private onDestroy = new Subject();

    constructor(
        @Inject(LocalStorage) private localStorage: Storage,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private translationManagementService: TranslationManagementService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        public viewContainerRef: ViewContainerRef,
        @Inject(OVERLAY_XL_CONFIG)
        private overlayConfig: OverlayConfig
    ) {
        forkJoin([
            this.translationManagementService.getTranslatableModules(),
            this.translationManagementService.getTranslatableCultures()
        ]).subscribe(([modules, cultures]) => {
            this.modules = modules;
            this.moduleMap = modules.reduce((obj, m) => {
                obj[m.id] = m.name;
                return obj;
            }, {} as any);

            this.modules.forEach((m) => {
                (this.filterFormGroup.controls.modules as OboMultiSelectFilter<any>).push(
                    new OboMultiSelectFilterOption(m.name, m.id, () => true, true)
                );
            });

            this.cultures = cultures;
            this.filteredCultures = cultures.map((c) => c as Culture & { active: boolean });
            this.cultureMap = cultures.reduce((obj, m) => {
                obj[m.name] = m.displayName;
                return obj;
            }, {} as any);

            this.cultures.forEach((c) => {
                (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).push(
                    new OboMultiSelectFilterOption(`${c.displayName} (${c.name})`, c.name, () => true, true)
                );
            });

            this.changeDetectorRef.detectChanges();
            this.restoreColumns();

            this.filterFormGroup.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                const filter = {
                    filters: (this.filterFormGroup.controls.modules as OboMultiSelectFilter<any>).controls
                        .filter((o) => o.isActive)
                        .map((o) => ({
                            id: 'moduleId',
                            value: o.filterValue,
                            operator: DataGridFilterOperator.IsEqualTo
                        })),
                    logic: 'or'
                };

                this.grid.filterService.addFilter(filter);

                (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).controls.forEach((o) => {
                    const column = this.grid.columns.find((c) => c.field === o.filterValue);
                    if (column) {
                        column.hidden = !o.isActive;
                    }
                });

                const columnVisibilityIds = (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).controls
                    .filter((o) => !o.isActive)
                    .map((o) => o.filterValue);

                this.grid.setColumnVisibility(columnVisibilityIds);

                this.changeDetectorRef.detectChanges();
                this.storeColumns();
            });

            this.loadLocalizations();
        });
    }

    public ngOnInit() {
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
    }

    public ngOnDestroy(): void {
        this.storeColumns();
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public updateFormGroup(cultureName: string, event: any) {
        const item = (this.selectedRow ? this.selectedRow : {}) as GridRow;
        if (event) {
            this.formGroup?.addControl(cultureName, new UntypedFormControl(item[cultureName]));
            if (this.enableHTML) this.translationLanguagesNav.select(cultureName);
        } else {
            this.formGroup?.removeControl(cultureName);
            if (this.enableHTML) this.translationLanguagesNav.select(this.translationLanguagesNav.items.first.domId);
        }
    }

    public removeRow(row: any): void {
        this.deleteLocalization(row).then(() => this.cancelHandler());
    }

    public selectRow(event: any): void {
        this.selectedRow = event;
        const htmlEntities = ['"', '&', '<', '>'];
        this.enableHTML = Object.values(this.selectedRow!).some((v) =>
            v ? htmlEntities.some((e) => v.toString().includes(e)) : false
        );
        this.editHandler({ dataItem: this.selectedRow });
    }

    public editHandler({ dataItem }: any) {
        this.formGroup = this.createFormGroup({ isNew: false, dataItem });
        this.openTranslationEditModal();
    }

    public addHandler(): void {
        this.selectedRow = undefined;
        this.formGroup = this.createFormGroup({ isNew: true });
        this.openTranslationEditModal();
    }

    public cancelHandler() {
        this.formGroup = undefined;
        this.overlayService.dismiss();
        this.enableHTML = false;
    }

    public saveHandler(): void {
        const value = this.formGroup?.value as TranslationItem;
        if (!this.selectedRow) {
            this.createLocalization(value);
        } else {
            if (this.selectedRow!.key === value.key && this.selectedRow!.moduleId === value.moduleId) {
                // key and moduleId unchanged. Can use update method :P
                this.updateLocalization(value);
            } else {
                // key or moduleId was changes. Update row by delete and create
                this.deleteLocalization(this.selectedRow).then(() => {
                    this.createLocalization(Object.assign(this.selectedRow!, value));
                });
            }
        }
        this.enableHTML = false;
        this.overlayService.close();
    }

    /**
     * sets the selected File
     * @param evt
     */
    public selectFiles({ target }: { target: { files: FileList } }) {
        const files = target.files;
        if (files.length > 0) {
            if (
                confirm(
                    this.translateService.instant('ADMIN_TRANSLATIONUPLOAD_ALERT') +
                        '\n' +
                        Array.from(files)
                            .map((f) => f.name)
                            .join('\n')
                )
            ) {
                this.importFiles(files);
            }
        }
    }

    public importFiles(files: FileList): void {
        this.spinnerService.startSpinner();
        this.translationManagementService
            .importTranslationFile(Array.from(files))
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    this.alertService.success(this.translateService.instant('ADMINS_FILEUPLOAD_COMPLETE'));
                },
                error: () => this.alertService.danger('Error')
            });
    }

    public openTranslationExportModal() {
        const translationExportPortal = new ComponentPortal(
            TranslationExportModalComponent,
            null,
            Injector.create({
                providers: [
                    {
                        provide: 'allLanguages',
                        useValue: this.cultures
                    },
                    {
                        provide: 'selectedLanguages',
                        useValue: this.filterFormGroup.controls.languages
                    },
                    {
                        provide: 'selectedModules',
                        useValue: this.filterFormGroup.controls.modules
                    },
                    {
                        provide: 'closeModal',
                        useValue: () => {
                            this.overlayService.close();
                        }
                    }
                ]
            })
        );
        this.overlayService.open(translationExportPortal);
    }

    public openTranslationEditModal() {
        this.filteredCultures.map((fc) => {
            fc.active = (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).controls.find(
                (c) => c.filterValue === fc.name
            )!.value;
            return fc;
        });

        this.overlayService.open(this.editTranslationModalTpl);
    }

    public filterCultures(searchTerm: string) {
        this.filteredCultures = this.cultures
            .filter((culture) => {
                return culture.displayName.toLowerCase().includes(searchTerm.toLowerCase());
            })
            .map((c) => c as Culture & { active: boolean });
    }

    private createFormGroup(args: any): UntypedFormGroup {
        const item = (args.isNew ? {} : args.dataItem) as GridRow;
        let modificationDate = item.modificationDate;
        if (!modificationDate) {
            const date = new Date();
            modificationDate = date.toUTCString();
        }

        const formGroup = this.formBuilder.group({
            key: [item.key, Validators.required],
            moduleId: [item.moduleId, Validators.required],
            modificationDate: [modificationDate, Validators.required]
        });
        this.visibleColumnCodes.forEach((field) => {
            formGroup.addControl(field, new UntypedFormControl(item[field]));
        });

        return formGroup;
    }

    private restoreColumns(): void {
        try {
            const fields: string[] = JSON.parse(this.localStorage.getItem(Constants.storageTokens.admin_translation_columns)!);
            if (!Array.isArray(fields) || fields.length === 0) {
                return;
            }
            const languageColumns = this.languageColumns;
            languageColumns.forEach((c) => {
                c.hidden = true;
            });
            (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).controls.forEach((c) => c.setValue(false));
            fields.forEach((field) => {
                const gridColumn = languageColumns.find((gc) => gc.field === field);
                if (gridColumn) {
                    gridColumn.hidden = false;
                    (this.filterFormGroup.controls.languages as OboMultiSelectFilter<any>).controls
                        .find((c) => c.filterValue === field)!
                        .setValue(true);
                }
            });
            const columnVisibilityIds = languageColumns.filter((l) => l.hidden).map((l) => l.field);
            this.grid.setColumnVisibility(columnVisibilityIds);
        } catch (e) {
            this.alertService.danger(e.toString());
        }
    }

    private storeColumns(): void {
        this.localStorage.setItem(Constants.storageTokens.admin_translation_columns, JSON.stringify(this.visibleColumnCodes));
    }

    public getControl(control: any): AbstractControl {
        return control as AbstractControl;
    }

    public getKey(key: any): string {
        return key as string;
    }

    loadLocalizations(): void {
        this.spinnerService.startSpinner();
        this.translationManagementService
            .getLocalizations()
            .pipe(
                map((result) => this.data.set(this.getDataGridResult(result))),
                finalize(() => this.spinnerService.stopSpinner())
            )
            .subscribe();
    }

    private createLocalization(localization: TranslationItem): void {
        this.spinnerService.startSpinner();
        this.translationManagementService
            .createLocalization(this.toTranslationItemDto(localization))
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    let result = Object.assign({}, this.data());
                    result.items.unshift(localization);
                    result.count++;
                    this.data.set(result);
                    this.alertService.success(this.translateService.instant('ADMIN_CREATE_SUCCESSFULL'));
                },
                error: (error) => this.alertService.warning(JSON.stringify(error))
            });
    }

    private deleteLocalization(localization: TranslationItem): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.spinnerService.startSpinner();
            this.translationManagementService
                .deleteLocalization(localization)
                .pipe(finalize(() => this.spinnerService.stopSpinner()))
                .subscribe({
                    next: () => {
                        let result = Object.assign({}, this.data());
                        let indexToDelete = result.items.indexOf(localization);
                        result.items.splice(indexToDelete, 1);
                        result.count--;
                        this.data.set(result);
                        this.alertService.success(this.translateService.instant('ADMIN_DELETE_SUCCESSFULL'));
                        resolve(true);
                    },
                    error: (error) => {
                        this.alertService.warning(JSON.stringify(error));
                        reject(false);
                    }
                });
        });
    }

    private updateLocalization(localization: TranslationItem): void {
        this.spinnerService.startSpinner();
        this.translationManagementService
            .updateLocalization(this.toTranslationItemDto(localization))
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    let result = Object.assign({}, this.data());
                    let indexToUpdate = result.items.indexOf(localization);
                    result.items.splice(indexToUpdate, 1);
                    result.items.unshift(localization);
                    this.data.set(result);
                    this.alertService.success(this.translateService.instant('ADMIN_UPDATE_SUCCESSFULL'));
                },
                error: (error) => this.alertService.warning(JSON.stringify(error))
            });
    }

    private getDataGridResult(translationItems: TranslationItemDto[]): DataGridResult {
        let result = new DataGridResult();
        result.count = translationItems.length + 1;
        result.items = translationItems.map((dto) => {
            let tI = new TranslationItem();
            tI.key = dto.key;
            tI.modificationDate = dto.modificationDate;
            tI.moduleId = dto.moduleId;
            dto.texts.forEach((text) => {
                tI[text.cultureName] = text.text;
            });
            return tI;
        });

        return result;
    }

    private toTranslationItemDto(localization: TranslationItem): TranslationItemDto {
        let dto = new TranslationItemDto();
        dto.key = localization.key;
        dto.moduleId = localization.moduleId;
        dto.modificationDate = localization.modificationDate;
        dto.texts = [];
        this.cultures.forEach((culture) => {
            dto.texts.push({ cultureName: culture.name, text: localization[culture.name] });
        });
        return dto;
    }
}
