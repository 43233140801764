<ng-template #confirmModal>
    <div class="modal">
        <div class="modal-header border-bottom-0">
            <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
            <button type="button" class="btn-close" (click)="dismiss()"></button>
        </div>
        <div class="modal-body">
            @if (questionTpl) {
                <ng-container [ngTemplateOutlet]="questionTpl.templateRef"></ng-container>
            } @else {
                <p>{{ question }}</p>
            }
        </div>
        <div class="modal-footer border-top-0 btn-container">
            <button type="button" class="btn btn-secondary" (click)="dismiss()" ngbAutofocus>
                {{ 'BTN_ABORT' | translate }}
            </button>
            <button type="button" class="btn btn-success" (click)="confirm()">
                {{ 'BTN_YES' | translate }}
            </button>
        </div>
    </div>
</ng-template>
