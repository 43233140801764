import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-no-products-modal',
    templateUrl: './noProductsFound.component.html'
})
export class NoProductsFoundComponent implements OnInit, OnDestroy {
    public isLoggedIn: boolean;
    private onDestroy = new Subject<any>();

    constructor(private authService: AuthService, private stateService: StateService) {}

    ngOnInit(): void {
        this.authService.onAuthenticationStatusChange.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
            this.isLoggedIn = status;
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public confirm(): void {
        this.stateService.go(this.isLoggedIn ? 'dashboard' : 'portal');
    }
}
