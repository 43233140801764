import { StateObject, Transition, TransitionService } from '@uirouter/core';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';

/**
 * starts the spinner before, and removes it after a transition
 * @param transitionService
 */
export function spinnerHook(transitionService: TransitionService) {
    const spinnerStartCriteria = {};
    const spinnerEndCriteria = {};

    const spinnerBeforeLazyLoadCriteria = {
        entering: (state?: StateObject) => state && state.data && state.data.lazyLoad
    };

    const spinnerStartAction = (transition: Transition) => {
        const spinnerService = transition.injector().get(SpinnerService) as SpinnerService;
        spinnerService.startSpinner();
    };
    const spinnerEndAction = (transition: Transition) => {
        const spinnerService = transition.injector().get(SpinnerService) as SpinnerService;
        spinnerService.stopSpinner();
    };
    // add the hook to transitionsservice
    transitionService.onBefore(spinnerBeforeLazyLoadCriteria, spinnerStartAction, { priority: 100 });
    transitionService.onStart(spinnerStartCriteria, spinnerStartAction, {
        priority: 100
    });
    transitionService.onFinish(spinnerEndCriteria, spinnerEndAction, {
        priority: 100
    });
    transitionService.onError(spinnerEndCriteria, spinnerEndAction, {
        priority: 100
    });
}
