import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'safeHTML' })
export class SafeHTMLPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    public transform(value: any): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
