@if (control && control.invalid && (control.dirty || control.touched)) { @if (customErrorMsg) {
<span class="form-text text-danger">{{ customErrorMsg }}</span>
} @else { @if (control.errors) {
<span class="form-text text-danger">
    @if (control.errors['required']) {
    {{ 'REQUIRED_FIELD' | translate }}
    } @if (control.errors['min']) {
    {{ 'ERROR_FIELD_MIN' | translate | stringFormat : [control.errors['min'].min.toString()] }}
    } @if (control.errors['max']) {
    {{ 'ERROR_FIELD_MAX' | translate | stringFormat : [control.errors['max'].max.toString()] }}
    } @if (control.errors['naming']) {
    {{ 'ERROR_FIELD_NAMING' | translate }}
    } @if (control.errors['onlySpace']) {
    {{ 'ERROR_FIELD_ONLY_SPACE' | translate }}
    }
</span>
} } }
