import { Component, Input, OnInit } from '@angular/core';
import { ProductRegionModel } from '@obo-admin/admin.models';
import { DataGridResult } from '@obo-admin/dataGrid/models/dataGrid.models';

@Component({
    selector: 'adm-product-regions-detail',
    templateUrl: './productRegionsDetail.component.html'
})
export class ProductRegionsDetailComponent implements OnInit {
    @Input()
    public dataItems: ProductRegionModel[];
    data: DataGridResult;

    ngOnInit(): void {
        this.data = { items: this.dataItems, count: this.dataItems.length + 1 };
    }
}
