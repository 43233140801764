import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert, AlertType } from '@obo-main/services/common/alert/alert.models';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';

const MAX_ALERTS: number = 5;

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    @Input()
    type: AlertType;
    @Output()
    closed: EventEmitter<void> = new EventEmitter<void>();

    public alerts: Alert[] = new Array<Alert>();

    constructor(private logger: Logger, private alertService: AlertService) {}

    public ngOnInit(): void {
        this.alertService.onNewAlert.subscribe((alert) => {
            this.logger.debug('onNewAlertObservable called:', alert);
            if (this.alerts.length >= MAX_ALERTS) {
                this.alerts.splice(0, 1);
            }
            this.alerts.push(alert);
        });
        this.alertService.onAlertDispose.subscribe((alert) => {
            this.logger.debug(`onAlertDispose called`);
            this.removeAlert(alert);
        });
        this.alertService.onAlertsReset.subscribe(() => {
            this.logger.debug('onAlertResetObservable called:');
            this.alerts = new Array<Alert>();
        });
    }

    public removeAlert(alert: Alert): void {
        this.alerts = this.alerts.filter((x) => x !== alert);
    }
}
