import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@obo-main/services/language/language.service';
import { getRouteLanguage } from '@obo-main/utils/staticHelperFunctions';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

//human-like way to describe Dates
@Pipe({ name: 'humanlyDate' })
export class HumanlyDate implements PipeTransform {
    constructor(public languageService: LanguageService, public translateService: TranslateService) {}

    public transform(dateStr: string, language: string, withSuffix: boolean = false): Promise<string> {
        return import(
            /* webpackInclude: /(\/|\\)(cs|en|nl|pt|de|fr|es|ru|fi|hr|hu|lt|lv|pl|sr)\.js$/ */
            /* webpackChunkName: "dayjs-locales" */
            `/node_modules/dayjs/locale/${getRouteLanguage(language)}`
        ).then((_) => {
            dayjs.locale(language);
            dayjs.extend(duration);
            dayjs.extend(relativeTime);

            const inputDate = new Date(dateStr);
            const currentDate = new Date();

            const timeDifference = inputDate.getTime() - currentDate.getTime();

            return dayjs.duration(timeDifference, 'milliseconds').humanize(withSuffix);
        });
    }
}
