<div class="admin-header">
    @if (customTitleTpl) {
        <ng-template [ngTemplateOutlet]="customTitleTpl.templateRef">
        </ng-template>
    } @else {
        <h2>{{ title }}</h2>
    }
    <div class="admin-header-actions" [class.has-toolbar]="toolbarTpl">
        @if (toolbarTpl) {
            <div class="admin-header-actions-toolbar">
                <ng-template [ngTemplateOutlet]="toolbarTpl.templateRef">
                </ng-template>
            </div>
        } @else {
            <div class="pseudo"></div>
        }
        <div class="admin-header-actions-buttons">
            @if (actionButtonsTpl) {
                <ng-template [ngTemplateOutlet]="actionButtonsTpl.templateRef"></ng-template>
            }
            <button class="btn btn-fa" uiSref="admin" [ngbTooltip]="'BTN_GO_TO_ADMIN_START' | translate">
                <fa-icon [icon]="['fal', 'arrow-turn-left']" size="2x"></fa-icon>
            </button>
        </div>
    </div>
</div>
