import { Directive, HostListener } from '@angular/core';
import { StateService } from '@uirouter/core';
import { StateHistoryService } from '@obo-main/services/stateHistory/stateHistory.service';

@Directive({
    selector: 'button[shdGoBack]'
})
export class GoBackButtonDirective {
    constructor(private stateService: StateService, private stateHistoryService: StateHistoryService) {}

    @HostListener('click', ['$event.target'])
    public onClick(btn: any) {
        let stateHistoryEntry = this.stateHistoryService.getPreviousStateFromHistory();

        if (stateHistoryEntry) {
            this.stateService.go(stateHistoryEntry.state, stateHistoryEntry.stateParams, {
                custom: {
                    goBack: true
                }
            });
        } else {
            this.stateService.go('portal');
        }
    }
}
