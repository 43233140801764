<section id="projects-container">
    <shd-headline [headline]="'PROFILE_TITLE'" [subHeadline]="'PROFILE_SUBTITLE'"></shd-headline>
    <div class="row">
        <div class="col-12 col-lg-8">
            <form #accountForm="ngForm" name="accountForm" id="accountForm" (ngSubmit)="updateAccount(user)" role="form">
                <h2 class="mb-3">
                    {{ 'PROFILE_SUBTITLE_GENERAL' | translate }}
                </h2>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label me-1">
                            {{ 'PROFILE_USERTITLE' | translate }}
                        </label>
                        <shd-radio-group [(ngModel)]="user.title" [ngModelOptions]="{ standalone: true }">
                            <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MR'">
                                {{ 'REGISTERFORM_USERTITLE_MR' | translate }}
                            </ng-template>
                            <ng-template shdRadioGroupOption [value]="'REGISTERFORM_USERTITLE_MRS'">
                                {{ 'REGISTERFORM_USERTITLE_MRS' | translate }}
                            </ng-template>
                        </shd-radio-group>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <label class="form-label" for="firstName">
                                    {{ 'PROFILE_FIRSTNAME' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.firstName"
                                    type="text"
                                    autocomplete="given-name"
                                    name="firstName"
                                    id="firstName"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="form-label" for="lastName">
                                    {{ 'PROFILE_LASTNAME' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.lastName"
                                    type="text"
                                    autocomplete="family-name"
                                    name="lastName"
                                    id="lastName"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="mb-3 required">
                            <label class="form-label" for="email">
                                {{ 'PROFILE_EMAIL' | translate }}
                            </label>
                            <input
                                [(ngModel)]="user.email"
                                type="email"
                                autocomplete="email"
                                email
                                name="email"
                                id="email"
                                tabindex="1"
                                class="form-control"
                                required
                                shdValidateInput />
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="phoneNumber">
                                {{ 'PROFILE_PHONE' | translate }}
                            </label>
                            <input
                                [(ngModel)]="user.phoneNumber"
                                type="text"
                                autocomplete="tel-national"
                                name="phoneNumber"
                                id="phoneNumber"
                                tabindex="1"
                                class="form-control" />
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="customergroup">
                                {{ 'CUSTOMERGROUP' | translate }}
                            </label>
                            <shd-single-select [(ngModel)]="user.customerGroup" name="customerGroup" id="customerGroup">
                                <ng-template shdSingleSelectLabel>
                                    {{ user.customerGroup | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_TRADE'">
                                    {{ 'CUSTOMERGROUP_TRADE' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTALLER'">
                                    {{ 'CUSTOMERGROUP_INSTALLER' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_GENERALCONTRACTOR'">
                                    {{ 'CUSTOMERGROUP_GENERALCONTRACTOR' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_ENDUSER'">
                                    {{ 'CUSTOMERGROUP_ENDUSER' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_CONSULTANT'">
                                    {{ 'CUSTOMERGROUP_CONSULTANT' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_INSTITUTION'">
                                    {{ 'CUSTOMERGROUP_INSTITUTION' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_MANUFACTURER'">
                                    {{ 'CUSTOMERGROUP_MANUFACTURER' | translate }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="'CUSTOMERGROUP_VIP'">
                                    {{ 'CUSTOMERGROUP_VIP' | translate }}
                                </ng-template>
                            </shd-single-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="mb-3 col-md-8">
                                <label class="form-label" for="street">
                                    {{ 'SHARED_STREET' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.street"
                                    type="text"
                                    autocomplete="address-line1"
                                    name="street"
                                    id="street"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                            <div class="mb-3 col-md-4">
                                <label class="form-label" for="housenumber">
                                    {{ 'PROFILE_HOUSENUMBER' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.houseNumber"
                                    type="text"
                                    autocomplete="address-line2"
                                    name="housenumber"
                                    id="housenumber"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-3 col-md-4">
                                <label class="form-label" for="postalCode">
                                    {{ 'PROFILE_ZIPCODE' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.postalCode"
                                    type="text"
                                    autocomplete="postal-code"
                                    name="postalCode"
                                    id="postalCode"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                            <div class="mb-3 col-md-8">
                                <label class="form-label" for="city">
                                    {{ 'SHARED_CITY' | translate }}
                                </label>
                                <input
                                    [(ngModel)]="user.city"
                                    type="text"
                                    autocomplete="address-level2"
                                    name="city"
                                    id="city"
                                    tabindex="1"
                                    class="form-control" />
                            </div>
                        </div>
                        <div class="mb-3 required">
                            <label class="form-label" for="country">
                                {{ 'SHARED_REGION' | translate }}
                            </label>
                            <select [(ngModel)]="user.regionId" name="region" id="region" class="form-control" required>
                                @for (region of regionList; track region) {
                                <option [ngValue]="region.id">
                                    {{ region.displayName | translate }}
                                </option>
                                }
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="company">
                                {{ 'PROFILE_COMPANY' | translate }}
                            </label>
                            <input
                                [(ngModel)]="user.company"
                                type="text"
                                autocomplete="organization"
                                name="company"
                                id="company"
                                tabindex="1"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="my-3">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    [(ngModel)]="user.isContactable"
                                    name="isContactable"
                                    id="isContactable"
                                    class="form-check-input" />
                                <label class="form-check-label text-muted" for="isContactable">
                                    {{ 'REGISTERFORM_ALLOWCONTACT' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <small class="form-text text-muted">
                    {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
                </small>
                <div class="my-3">
                    <button
                        class="btn btn-success"
                        [disabled]="accountForm.invalid"
                        type="submit"
                        name="profile-submit"
                        id="profile-submit"
                        tabindex="4">
                        {{ 'PROFILE_BTN_SAVE' | translate }}
                    </button>
                </div>
            </form>
        </div>
        <hr />
        <div class="col-12 col-lg-8">
            <form
                (ngSubmit)="changePassword(user); resetForm(changePasswordForm)"
                #changePasswordForm="ngForm"
                id="changePasswordForm"
                name="changePasswordForm"
                class="mt-3">
                <h2 class="mb-3">
                    {{ 'PROFILE_SUBTITLE_CHANGEPASSWORD' | translate }}
                </h2>
                <div class="row">
                    <div class="mb-3 required col-md-6">
                        <label class="form-label" for="oldPassword">
                            {{ 'PROFILE_OLDPASSWORD' | translate }}
                        </label>
                        <div class="position-relative">
                            <input
                                [(ngModel)]="user.OldPassword"
                                type="password"
                                shdToggle
                                name="oldPassword"
                                id="oldPassword"
                                tabindex="2"
                                class="form-control"
                                autocomplete="off"
                                required
                                shdValidateInput />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3 required">
                            <label class="form-label" for="password">
                                {{ 'PROFILE_PASSWORD' | translate }}
                            </label>
                            <div class="position-relative">
                                <input
                                    [(ngModel)]="user.NewPassword"
                                    type="password"
                                    shdToggle
                                    name="password"
                                    id="password"
                                    tabindex="2"
                                    class="form-control"
                                    pattern="{{ passwordPattern }}"
                                    required
                                    shdValidateEqual="confirmPassword"
                                    reverse="true"
                                    autocomplete="off"
                                    shdValidateInput
                                    [customErrorMsg]="'PROFILE_PASSWORDPATTERN_ERROR' | translate" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 required">
                            <label class="form-label" for="confirmPassword">
                                {{ 'PROFILE_CONFIRMPASSWORD' | translate }}
                            </label>
                            <div class="position-relative">
                                <input
                                    [(ngModel)]="user.CONFIRMPASSWORD"
                                    #confirmPassword="ngModel"
                                    type="password"
                                    shdToggle
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    tabindex="2"
                                    class="form-control"
                                    required
                                    shdValidateEqual="password"
                                    autocomplete="off"
                                    shdValidateInput
                                    [customErrorMsg]="'PROFILE_PASSWORDCONFIRM_ERROR' | translate" />
                            </div>
                        </div>
                    </div>
                </div>
                <small class="form-text text-muted mb-2">
                    {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
                </small>
                <div class="mb-3">
                    <button
                        class="btn btn-success"
                        [disabled]="changePasswordForm.invalid"
                        type="submit"
                        name="passwordChange-submit"
                        id="passwordChange-submit"
                        tabindex="4">
                        {{ 'PROFILE_BTN_PASSWORDCHANGE' | translate }}
                    </button>
                </div>
            </form>
        </div>
        <div class="col-12 col-lg-8">
            <form
                (ngSubmit)="accountConfirmDeleteDialog.show()"
                #deleteAccountForm="ngForm"
                id="deleteAccountForm"
                name="deleteAccountForm"
                class="mt-3">
                <h2 class="mb-3">
                    {{ 'PROFILE_SUBTITLE_DELETE_ACCOUNT' | translate }}
                </h2>
                <div class="mb-3">
                    <p class="form-text">
                        {{ 'PROFILE_INFO_DELETE_ACCOUNT' | translate }}
                    </p>
                    <button class="btn btn-fa" type="submit" name="deleteAccount-submit" id="deleteAccount-submit" tabindex="4">
                        <fa-icon [icon]="['fal', 'trash-alt']" size="2x"></fa-icon>
                        {{ 'BTN_DELETE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>

<!--Modals-->

<shd-confirm-modal
    #accountConfirmDeleteDialog
    [title]="'CONFIRM_MODAL_DELETE_ACCOUNT' | translate"
    [question]="'DELETE_ACCOUNT_QUESTION' | translate"
    (confirmed)="deleteAccount()"></shd-confirm-modal>
