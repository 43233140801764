import { SortingState } from '@tanstack/angular-table';
import { BehaviorSubject, Subject } from 'rxjs';

export class DataGridResult {
    items: Array<any>;
    count: number;
}

export class DataGridState {
    filterState: DataGridFilterState;
    sortingState: SortingState;
    paginationState: DataGridPaginationState;
}

export class DataGridPaginationState {
    skip: number;
    top: number;
}

export class DataGridColumnVisibility {
    [key: string]: boolean;
}

export class DataGridFilter {
    filters: DataGridFilterItem[];
    logic: string;
}

export class DataGridFilterItem {
    id: string;
    value: number | string;
    operator: string;
}

export class DataGridColumnCustomFilter {
    label: string;
    value: any;
}

export enum DataGridFilterOperator {
    Contains = 'contains',
    IsEqualTo = 'is equal to',
    NotEqualTo = 'is not equal to',
    DoesNotContain = 'does not contain',
    StartsWith = 'starts with',
    EndsWith = 'ends with',
    IsNull = 'is null',
    NotNull = 'is not null',
    GreaterThanOrEqualTo = 'greater than or equal to',
    LessThanOrEqualTo = 'less than or equal to'
}

export class DataGridExcelExportEvent {
    updatedRows: Subject<any[]>;
    rows: any[];

    constructor() {
        this.updatedRows = new BehaviorSubject<any[]>([]);
        this.rows = [];
    }
}

export type DataGridFilterState = DataGridFilter[];
