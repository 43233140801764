import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@obo-main/services/auth/auth.service';

@Component({
    selector: 'app-display-error',
    templateUrl: './displayError.component.html',
    styleUrls: ['./displayError.component.scss']
})
export class DisplayErrorComponent implements OnInit {
    @Input()
    public errorName: string | undefined;
    @Input()
    public error: any;
    @Input()
    public showErrorDetails: boolean = false;

    public showFallbackErrorText: boolean = false;
    public translateKey: string = 'TEMPLATES_ERROR';
    constructor(
        @Inject('DEBUG') private debugMode: boolean,
        @Inject('ENVIRONMENT') private environment: string,
        private authService: AuthService,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.translateKey = `TEMPLATES_${this.errorName ? this.errorName!.toUpperCase() : 'ERROR'}`;
        this.showFallbackErrorText = this.translateService.instant(this.translateKey) !== this.translateKey;
        this.showErrorDetails = this.authService.isAdmin() || this.debugMode || this.environment === 'jit';
    }
}
