import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettings {
    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    public init = false;

    public get appSettings() {
        return environment;
    }

    public getItem(key: string): any {
        const segments = key.split('.');
        const env: any = environment;
        let result = '';
        try {
            result = segments.length === 0 ? env[key] : segments.reduce((path, seg) => path[seg], env);
        } catch (err) {
            if (isPlatformBrowser(this.platformId)) {
                console.error(`path ${key} not available in environment Object!`, err); // only log in console when the device is a browser
            }
        }
        if (result === '') {
            if (isPlatformBrowser(this.platformId)) {
                console.error(`No Value for Key ${key} defined. Env:`, env); // only log in console when the device is a browser
            }
        } else {
            return result;
        }
    }

    public loadSettings(): Promise<any> {
        if (environment) {
            this.init = true;
            return Promise.resolve(environment);
        }
        return Promise.reject();
    }
}
