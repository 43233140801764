<section id="static-noProductsFound">
    <shd-headline [headline]="'SHARED_NO_PRODUCTS_AVAILABLE_TITLE'"></shd-headline>
    <div class="row">
        <div class="col-6">
            <p [innerHtml]="'SHARED_NO_PRODUCTS_AVAILABLE_TEXT' | translate"></p>
            <button class="btn btn-secondary mt-3" [uiSref]="'contact'">
                <fa-icon class="me-2" [icon]="['fal', 'chevron-right']"></fa-icon>
                {{ 'SHD_GO_TO_CONTACT' | translate }}
            </button>
        </div>
        <div class="col-6">
            <div class="text-end">
                <img class="img-fluid" src="assets/img/customersupport.png" />
            </div>
        </div>
    </div>
    <div class="mt-5 d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" shdGoBack>
            {{ 'BTN_GOBACK' | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="confirm()">
            {{ (isLoggedIn ? 'SHD_BACK_TO_DASHBOARD' : 'SHD_BACK_TO_PORTAL') | translate }}
        </button>
    </div>
</section>
