import { Directive, TemplateRef } from '@angular/core';
import { OboSingleSelectFilterOption } from '@obo-common/filter/models/singleSelect';
import { OboMultiSelectFilterOption } from '@obo-common/filter/models/multiSelect';

@Directive({ selector: 'ng-template[shdFilterOption]' })
export class FilterOptionDirective {
    constructor(
        public templateRef: TemplateRef<{
            $implicit: OboSingleSelectFilterOption<any> | OboMultiSelectFilterOption<any>;
        }>
    ) {}
}
