@if (active) {
<div class="">
    <div class="loading-layer container-fluid d-flex align-items-center justify-content-center h-100">
        <div class="d-flex flex-column align-items-center">
            <div id="spinner" class="spinner">
                <div class="inner"></div>
            </div>
            <p class="spinner-text">{{ spinnerText }}</p>
            @if (progress !== undefined) {
            <p class="progress">
                <ngb-progressbar
                    showValue="true"
                    type="warning"
                    [value]="progress"
                    [striped]="true"
                    [animated]="true"></ngb-progressbar>
            </p>
            }
        </div>
    </div>
</div>
}
