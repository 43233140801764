import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidebarTabsService {
    private tabsEvents: Map<number, BehaviorSubject<SidebarServiceEvent>>;
    constructor() {}

    public registerTabEventByIndex(tabIndex: number): BehaviorSubject<SidebarServiceEvent> {
        if (!this.tabsEvents) {
            this.tabsEvents = new Map<number, BehaviorSubject<SidebarServiceEvent>>();
        }

        let sidebarServiceEventSubject = this.tabsEvents.get(tabIndex);
        if (!sidebarServiceEventSubject) {
            sidebarServiceEventSubject = new BehaviorSubject<SidebarServiceEvent>({ event: undefined });
            this.tabsEvents.set(tabIndex, sidebarServiceEventSubject);
        }

        return sidebarServiceEventSubject;
    }
}

export class SidebarServiceEvent {
    event: any;

    constructor(event: any) {
        this.event = event;
    }
}
