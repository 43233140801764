import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ElbridgeData } from '@obo-main/services/elbridge/elbridge.models';
import { ElbridgeFormService } from '@obo-common/shared/services/elbridgeForm.service';

@Component({
    selector: 'app-elbridge-form',
    templateUrl: './elbridgeForm.component.html'
})
export class ElbridgeFormComponent implements AfterViewInit {
    @Input()
    elbridgeData: ElbridgeData;

    @ViewChild('elbridgeForm')
    elbridgeForm: ElementRef;

    constructor(private elbridgeFormService: ElbridgeFormService, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        const form = this.elbridgeFormService.getElbridgeDataForm(this.renderer, this.elbridgeForm, this.elbridgeData);
        form.submit();
    }
}
