import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectEditModalComponent } from '@obo-dashboard/components/dashboard/project/modals/project-edit-modal.component';
import { Project } from '@obo-dashboard/models/projectManagement.models';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@obo-common/shared/services/overlay.service';

@Component({
    selector: 'app-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
    @Input()
    public project: Project;
    @Output()
    public projectChange: EventEmitter<Project> = new EventEmitter<Project>();

    public plannedModules: string[] = [];
    public language: string;

    constructor(
        private translateService: TranslateService,
        private projectService: ProjectService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private stateService: StateService,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig,
        private viewContainerRef: ViewContainerRef,
        private overlayService: OverlayService
    ) {
        this.language = this.translateService.currentLang;
        this.overlayService.init(null, this.overlayConfig);
    }

    ngOnInit(): void {
        this.plannedModules = this.getPlannedModules();
    }

    public editProject(): void {
        const projectClone = Object.assign({}, this.project);
        const projectEditModalPortal = new ComponentPortal(
            ProjectEditModalComponent,
            this.viewContainerRef,
            Injector.create({
                providers: [
                    {
                        provide: 'project',
                        useValue: projectClone
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('PROJECT_EDIT_PROJECT')
                    },
                    {
                        provide: 'saveFn',
                        useValue: (project: Project): void => {
                            this.saveProject(project);
                            this.dismissModal();
                        }
                    },
                    {
                        provide: 'dismissFn',
                        useValue: () => this.dismissModal()
                    }
                ]
            })
        );
        this.overlayService.open(projectEditModalPortal);
    }

    public deleteProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .deleteProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (): void => {
                    this.alertService.success(this.translateService.instant('PROJECTCTRL_DELETEPROJECTSUCCESS'));
                    this.projectChange.emit(this.project);
                },
                error: () => this.alertService.danger(this.translateService.instant('PROJECTCTRL_DELETEPROJECTFAILURE'))
            });
    }

    /**
     * duplicates a project
     */
    public duplicateProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .duplicateProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (project: Project): void => {
                    this.alertService.success(this.translateService.instant('MAIN_DUPLICATEPROJECT_SUCCESS'));
                    this.stateService.go('projectDetail', {
                        projectId: project.id
                    });
                    this.projectChange.emit();
                },
                error: () => this.alertService.danger(this.translateService.instant('MAIN_DUPLICATEPROJECT_FAILURE'))
            });
    }

    private saveProject(project: Project) {
        this.spinnerService.startSpinner();
        this.projectService
            .createOrUpdateProject(project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (): void => {
                    this.project = project;
                    this.alertService.success(this.translateService.instant('SHARED_EDIT_SUCCESS'));
                    this.projectChange.emit();
                },
                error: () => this.alertService.danger(this.translateService.instant('SAVE_PROJECT_FAILURE'))
            });
    }

    private getPlannedModules(): string[] {
        let plannedModules: string[] = [];
        for (let id of this.project.plannedModules) {
            for (let key in Constants.ModuleId) {
                if (Constants.ModuleId[key as string] === id) {
                    plannedModules.push(key.toLowerCase());
                }
            }
        }
        return plannedModules;
    }

    private dismissModal() {
        this.overlayService.dismiss();
    }
}
