@if (currentStep) {
    <div class="module-action-bar">
        <div class="module-action-bar-stepper">
            <div class="module-action-bar-btn-container goBack">
                <div class="module-action-bar-btn" (click)="goBack()" [ngbTooltip]="'BTN_GOBACK' | translate"
                     placement="right">
                    <fa-icon [icon]="['fal', 'arrow-turn-left']" size="lg"></fa-icon>
                </div>
            </div>
            @for (group of stepGroups; track $index) {
                @if (showStepGroup(group)) {
                    <div class="module-action-bar-btn-container">
                        <div (click)="goTo(group)" [class.active]="isActive(group)"
                             [class.completed]="isCompleted(group)" class="module-action-bar-btn"
                             [ngbTooltip]="group.steps[0].label | translate"
                             placement="right">
                            @if (isCompleted(group)) {
                                <div class="badge">
                                    <fa-icon [icon]="['fas', 'check-circle']" size="lg"></fa-icon>
                                </div>
                            }
                            <fa-icon [icon]="['fal', group.steps[0].icon]" size="lg"></fa-icon>
                        </div>
                    </div>
                }
            }
        </div>
        <div class="module-action-bar-actions" [class.tour-active]="isTourActive">
            <div class="module-action-bar-btn-container">
                <div class="module-action-bar-btn" [ngbTooltip]="'BTN_ABORT' | translate" placement="right"
                     (click)="cancelConfirm.show()">
                    <fa-icon [icon]="['fal', 'times']" size="lg"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <shd-confirm-modal #cancelConfirm [title]="'SHARED_CANCEL_CONFIGURATION_TITLE' | translate"
                       [question]="'SHARED_CANCEL_CONFIGURATION_QUESTION' | translate"
                       (confirmed)="cancel()"></shd-confirm-modal>
}
