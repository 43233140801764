import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginData } from '@obo-account/models/account.models';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { StateService } from '@uirouter/angular';

@Component({
    selector: 'app-login-panel',
    templateUrl: './login-panel.component.html',
    styleUrls: ['./login-panel.component.scss']
})
export class LoginPanelComponent {
    constructor(
        private translateService: TranslateService,
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private alertSerice: AlertService,
        private stateService: StateService
    ) {}

    public user: LoginData = new LoginData();

    public login() {
        this.spinnerService.startSpinner();
        this.authService
            .login(this.user.username, this.user.password)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe(
                (res) => {
                    this.stateService.go('login'); // so the redirect is handlet by loginRedirect transition hook
                    this.spinnerService.stopSpinner();
                },
                (err) => {
                    this.alertSerice.danger(this.translateService.instant('LOGINCTRL_LOGINFAILURE'));
                }
            );
    }
}
