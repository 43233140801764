<div class="row justify-content-center justify-content-lg-start">
    <div class="col-12">
        <header>
            <div class="titles w-100">
                <h1>{{ headline | uppercase | translate }}</h1>
                <h2>{{ subHeadline | uppercase | translate }}</h2>
            </div>
        </header>
    </div>
</div>
