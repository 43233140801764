import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { ResetPasswordRequest } from '@obo-account/models/account.models';

@Injectable()
export class ResetPasswordService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private logger: Logger) {}

    /**
     * sets a new password
     * @param data
     */
    public resetAccount(data: ResetPasswordRequest) {
        this.logger.debug(`Changing password for ${data.id} with token: ${data.token}`);
        return this.http.put(`${this.apiPrefix}Accounts/ResetPassword`, data);
    }
}
