<aside
    #sidebar
    role="complementary"
    [attr.aria-label]="ariaLabel"
    [attr.position]="position"
    class="sidebar with-tabs sidebar--{{ mode }}"
    [ngClass]="{ 'sidebar--opened': opened, inAppNavActive: inAppNavActive }"
    shdClickOutside>
    @if (withoutTabs) {
    <div class="d-flex h-100">
        <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
    </div>
    } @else {
    <div class="d-flex h-100 w-100" [ngClass]="position === 'right' ? 'flex-row' : 'flex-row-reverse'">
        <lib-sidebar-tabs [position]="position" #sidebarTabs (toggleClicked)="toggleSidebar()">
            <lib-sidebar-tab
                (tabClicked)="!opened ? toggleSidebar() : null"
                [isActive]="true"
                [tabIndex]="0"
                [iconClass]="toggleButtonFaIcon"
                [showActiveFilter]="showActiveFilter"></lib-sidebar-tab>
        </lib-sidebar-tabs>
        <lib-sidebar-tab-content [title]="title" [isActive]="true" [tabIndex]="0">
            <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
        </lib-sidebar-tab-content>
    </div>
    }
</aside>

<ng-template #sidebarContent>
    <ng-content></ng-content>
</ng-template>

@if (showFAB) {
<shd-floating-action-button
    [isSecondBtn]="isFabSecond"
    class="d-lg-none d-block"
    [buttonColor]="'#7694a4'"
    [faPrefix]="'fal'"
    [faIcon]="toggleButtonFaIcon"
    (click)="toggleSidebar()"></shd-floating-action-button>
} @if ( { xs: true, sm: true, md: true, lg: false, xl: false, xxl: false, xxxl: false } | responsiveValue | async ) {
<div class="backdrop" [class.show]="opened" [class.hide]="!opened" (click)="toggleSidebar()"></div>
}
