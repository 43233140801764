import { Injectable } from '@angular/core';

@Injectable()
export class FlaggedEnumService {
    public formValueToBit(enumeration: object, selection: boolean[]) {
        const bits = FlaggedEnumService.enumToBitValues(enumeration);
        return selection.reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
    }

    public bitToFormValue(enumeration: object, bit: number): Array<boolean> {
        const bits = FlaggedEnumService.enumToBitValues(enumeration);
        return bits.map((b) => (bit & b) === b);
    }

    public selectedBitsToBit(enumeration: object, bit: number) {
        const bits = FlaggedEnumService.enumToBitValues(enumeration);
        return this.formValueToBit(
            enumeration,
            bits.map((x) => x == bit)
        );
    }

    public bitToSelectedBits(enumeration: object, bit: number): Array<number> {
        const bits = FlaggedEnumService.enumToBitValues(enumeration);
        return bits.filter((b) => (bit & b) === b);
    }

    private static enumToBitValues(enumeration: object) {
        return Object.keys(enumeration).map(Number).filter(Boolean);
    }
}
