export function StaticImplements<T>() {
    return (constructor: T) => {
        return constructor;
    };
}

export interface FromApiData<T> {
    new (): T;
    fromApiData(data: any): T;
}
