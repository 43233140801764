import { Injectable } from '@angular/core';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { DownloadTypeEnum } from '../models/download.models';

@Injectable()
export class DownloadService {
    constructor(private logger: Logger, private appSettings: AppSettings) {}

    /**
     * builds the file download url out of language and selectedModule.
     * if user language is german, it returns the german downloadUrl, otherwise the english one
     * @param lang
     * @param moduleName
     */
    public findDownloadUrl(lang: string, moduleName: DownloadTypeEnum, version: string = ''): string {
        let downloadUrl = '';
        const downloadUrlObj = this.appSettings.getItem('settings.downloadUrl');
        if (version) downloadUrl = downloadUrlObj[moduleName][version];
        else downloadUrl = downloadUrlObj[moduleName];

        this.logger.debug(`Downloading file: ${downloadUrl}`);
        if (lang.toLowerCase().startsWith('de')) {
            downloadUrl = downloadUrl.replace('{lang}', 'de');
        } else {
            downloadUrl = downloadUrl.replace('{lang}', 'en');
        }

        return downloadUrl;
    }
}
