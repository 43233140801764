import { Component, Input } from '@angular/core';
import { DataGridFilter, DataGridFilterOperator } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridFilterService } from '@obo-admin/dataGrid/services/dataGridFilter.service';

@Component({
    selector: 'adm-data-grid-filter-input',
    templateUrl: './dataGridFilterInput.component.html',
    styleUrls: ['./dataGridFilterInput.component.scss']
})
export class DataGridFilterInputComponent {
    @Input()
    public field: string;
    @Input()
    public filter: DataGridFilterService;
    @Input()
    type: 'number' | 'text';
    filterValue: string;

    clear(): void {
        this.filterValue = undefined;
        this.filter.removeFilter(this.field);
    }

    applyFilter(): void {
        this.filter.addFilter(this.columnFilter);
    }

    get columnFilter(): DataGridFilter {
        let filter = new DataGridFilter();
        filter.filters = [
            {
                value: this.filterValue,
                id: this.field,
                operator: DataGridFilterOperator.Contains
            }
        ];
        filter.logic = 'or';
        return filter;
    }
}
