import { NgModule } from '@angular/core';
import { ServicesModule } from '@obo-main/services/services.module';
import { SafeHTMLPipe } from '@obo-common/shared/pipes/safeHtml.pipe';
import { ProductNamePipe } from '@obo-common/shared/pipes/productName.pipe';
import { RegionPipe } from '@obo-common/shared/pipes/region.pipe';
import { AppSettingsPipe } from '@obo-common/shared/pipes/appSettings.pipe';
import { CatalogUrlPipe } from '@obo-common/shared/pipes/catalogUrlPipe/catalogUrl.pipe';
import { ApiImagePipe } from '@obo-common/shared/pipes/apiImagePipe/apiImage.pipe';
import { CableCategoryPipe } from '@obo-common/shared/pipes/cableCategoryPipe/cableCategory.pipe';
import { YesNoPipe } from '@obo-common/shared/pipes/yesNo.pipe';
import { ObjectEntriesPipe } from '@obo-common/shared/pipes/objectEntries.pipe';
import { SafeURLPipe } from '@obo-common/shared/pipes/safeURL.pipe';
import { AsAnyPipe } from '@obo-common/shared/pipes/asAny.pipe';
import { ToArrayPipe } from '@obo-common/shared/pipes/toArray.pipe';
import { LimitArrayPipe } from '@obo-common/shared/pipes/limitArray.pipe';
import { CableNamePipe } from '@obo-common/shared/pipes/cableName.pipe';
import { AbsNumberPipe } from '@obo-common/shared/pipes/absNumber.pipe';
import { ImageIdPipe } from '@obo-common/shared/pipes/imageId.pipe';
import { ParseIntPipe } from '@obo-common/shared/pipes/parseInt.pipe';
import { FilterPipe } from '@obo-common/shared/pipes/filter.pipe';
import { IsSelectedRegionPipe } from '@obo-common/shared/pipes/isSelectedRegion.pipe';
import { ResponsiveValuePipe } from './pipes/responsive-value.pipe';
import { StringFormatPipe } from '@obo-common/shared/pipes/stringFormat.pipe';
import { HumanlyDate } from './pipes/humanlyDate.pipe';
import { AddressLinePipe } from './pipes/addressLine.pipe';
import { GreetingPipe } from './pipes/greeting.pipe';

/**
 * Module which contains pipes used by the application
 */
@NgModule({
    imports: [ServicesModule],
    declarations: [
        SafeHTMLPipe,
        ProductNamePipe,
        RegionPipe,
        AppSettingsPipe,
        CatalogUrlPipe,
        ApiImagePipe,
        CableCategoryPipe,
        YesNoPipe,
        ObjectEntriesPipe,
        SafeURLPipe,
        AsAnyPipe,
        ToArrayPipe,
        LimitArrayPipe,
        CableNamePipe,
        AbsNumberPipe,
        ImageIdPipe,
        ParseIntPipe,
        FilterPipe,
        IsSelectedRegionPipe,
        ResponsiveValuePipe,
        StringFormatPipe,
        HumanlyDate,
        AddressLinePipe,
        GreetingPipe
    ],
    providers: [ProductNamePipe, RegionPipe, CatalogUrlPipe, ApiImagePipe, CableCategoryPipe, ImageIdPipe, ResponsiveValuePipe],
    exports: [
        SafeHTMLPipe,
        ProductNamePipe,
        RegionPipe,
        AppSettingsPipe,
        CatalogUrlPipe,
        ApiImagePipe,
        CableCategoryPipe,
        YesNoPipe,
        ObjectEntriesPipe,
        SafeURLPipe,
        AsAnyPipe,
        ToArrayPipe,
        LimitArrayPipe,
        CableNamePipe,
        AbsNumberPipe,
        ImageIdPipe,
        ParseIntPipe,
        FilterPipe,
        IsSelectedRegionPipe,
        ResponsiveValuePipe,
        StringFormatPipe,
        HumanlyDate,
        AddressLinePipe,
        GreetingPipe
    ]
})
export class SharedPipesModule {}
