import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Motd, MotdContent } from '@obo-admin/motdManagement/motd.models';
import { MotdManagementService } from '@obo-admin/motdManagement/motdManagement.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Language } from '@obo-main/services/language/language.models';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'adm-router-motd-management',
    templateUrl: './motdManagement.component.html',
    styleUrls: ['./motdManagement.component.scss']
})
export class MotdManagementComponent {
    @Input()
    public motdList: Motd[];
    @Input()
    public availableLanguages: Language[];
    @ViewChild('acc')
    public accordion: NgbAccordionDirective;

    public currentFormGroup: UntypedFormGroup | undefined;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private motdService: MotdManagementService,
        private datePipe: DatePipe
    ) {}

    /**
     * saves a motd
     */
    public saveMotd(): void {
        this.spinnerService.startSpinner();
        this.motdService
            .createOrUpdateMotd(this.transformFromFormGroup(this.currentFormGroup!))
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (res) => {
                    this.motdList = this.motdList.filter((m) => typeof m.id === 'number');
                    this.motdList = this.motdList.filter((m) => m.id !== res.id);
                    this.motdList.push(res);
                    this.alertService.success(this.translateService.instant('ADMIN_SAVEMOTD_SUCCESS'));
                },
                error: (err) => {
                    this.alertService.danger(this.translateService.instant('ADMIN_SAVEMOTD_ERROR'));
                }
            });
    }

    /**
     * removes a Motd
     * @param message
     */
    public removeMotd(message: Motd): void {
        if (message.id === undefined) {
            this.motdList = this.motdList.filter((m) => m !== message);
        } else {
            this.spinnerService.startSpinner();
            this.motdService
                .deleteMotd(message.id)
                .pipe(finalize(() => this.spinnerService.stopSpinner()))
                .subscribe({
                    next: () => {
                        this.motdList = this.motdList.filter((m) => m !== message);
                        this.alertService.success(this.translateService.instant('ADMIN_DELETEMOTD_SUCCESS'));
                    },
                    error: (err) => {
                        this.alertService.danger(this.translateService.instant('ADMIN_DELETEMOTD_ERROR'));
                    }
                });
        }
    }

    /**
     * adds en empty motd object to accordion
     */
    public addNewMotd(): void {
        const newMotd = new Motd();
        this.motdList.push(newMotd);
        setTimeout(() => this.accordion.toggle('undefined'));
    }

    /**
     * is executed on panelchange. Initializes the form group for the active panel
     * @param id
     */
    public onPanelChange(id: string): void {
        const message = this.motdList.find((m) => {
            if (id === 'undefined' || id === 'motd-undefined') {
                return m.id === undefined;
            } else {
                return 'motd-' + m.id!.toString() === id;
            }
        });
        if (!message) {
            throw new Error(`Message with id ${id} not found`);
        }
        this.currentFormGroup = this.createFormGroup(message);
    }

    /**
     * creates a form group by a motd Object or nithing
     * @param motd
     */
    public createFormGroup(motd?: Motd): UntypedFormGroup {
        const item = motd || new Motd();

        const formGroup = this.formBuilder.group({
            id: item.id,
            name: item.name,
            startDate: [this.datePipe.transform(item.startDate, "yyyy-MM-dd'T'HH:mm"), Validators.required],
            endDate: [this.datePipe.transform(item.endDate, "yyyy-MM-dd'T'HH:mm"), Validators.required]
        });

        this.availableLanguages.forEach((lang) => {
            const existingInMotd = item.contents.find((x) => x.cultureName === lang.name);

            formGroup.addControl(
                `lang-${lang.name}-title`,
                new UntypedFormControl(existingInMotd ? existingInMotd.title : undefined)
            );
            formGroup.addControl(
                `lang-${lang.name}-message`,
                new UntypedFormControl(existingInMotd ? existingInMotd.message : undefined)
            );
        });

        return formGroup;
    }

    /**
     * transforms the formgroup in a Motd Object
     * @param fg
     */
    private transformFromFormGroup(fg: UntypedFormGroup): Motd {
        const result = new Motd();
        result.id = fg.get('id')!.value;
        result.startDate = new Date(Date.parse(fg.get('startDate')!.value));
        result.endDate = new Date(Date.parse(fg.get('endDate')!.value));
        result.isActive = result.startDate.getTime() <= Date.now() && result.endDate.getTime() >= Date.now();
        result.name = fg.get('name')!.value;
        result.contents = this.availableLanguages.reduce((acc, lang) => {
            const titleInFg = fg.get(`lang-${lang.name}-title`);
            const messageInFg = fg.get(`lang-${lang.name}-message`);
            if (
                titleInFg !== undefined &&
                typeof titleInFg!.value === 'string' &&
                titleInFg!.value.length > 0 &&
                messageInFg !== undefined &&
                typeof messageInFg!.value === 'string' &&
                messageInFg!.value.length > 0
            ) {
                return [
                    ...acc,
                    {
                        cultureName: lang.name,
                        title: titleInFg!.value,
                        message: messageInFg!.value
                    }
                ];
            } else {
                return acc;
            }
        }, new Array<MotdContent>());
        return result;
    }
}
