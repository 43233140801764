import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Address } from '@obo-dashboard/models/projectManagement.models';

@Pipe({ name: 'addressLine' })
@Injectable()
export class AddressLinePipe implements PipeTransform {
    public transform(value: Address): string {
        return value
            ? [
                  value.street ? value.street : '',
                  value.houseNumber ? ` ${value.houseNumber}` : '',
                  value.postalCode ? ` ${value.postalCode}` : '',
                  value.city ? ` ${value.city}` : ''
              ]
                  .filter(Boolean)
                  .toString()
            : '';
    }
}
