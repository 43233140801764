import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    constructor() {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // adds the noCacheHeaders to fix IE caching crap
        if (request.url.toLocaleLowerCase().includes('image')) {
            return next.handle(request);
        }
        const newRequest = request.clone({
            headers: Object.entries(noCacheHeaders).reduce((acc, [key, value]) => acc.set(key, value), request.headers)
        });
        return next.handle(newRequest);
    }
}

export const noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
};
