import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMapsPlacesModule } from '@ng-maps/places';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { UploadImageModule } from '@obo-common/uploadImage/uploadImage.module';
import { DashboardComponent } from '@obo-dashboard/components/dashboard/dashboard.component';
import { DASHBOARD_STATES } from '@obo-dashboard/dashboard.states';
import { SidebarModule } from '@obo-libraries/ng-sidebar';
import { ServicesModule } from '@obo-main/services/services.module';
import { UIRouterModule } from '@uirouter/angular';
import { PlanningCardComponent } from './components/cards/planning-card/planning-card.component';
import { ProjectCardComponent } from './components/cards/project-card/project-card.component';
import { QuickStartCardComponent } from './components/cards/quick-start-card/quick-start-card.component';
import { ProjectDetailComponent } from './components/dashboard/project/project-detail/project-detail.component';
import { ProjectComponent } from './components/dashboard/project/project.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { BuildingService } from './services/building.service';
import { DownloadService } from './services/download.service';
import { ProjectService } from './services/project.service';
import { BuildingCardComponent } from './components/cards/building-card/building-card.component';
import { PlanningsComponent } from './components/dashboard/project/plannings/plannings.component';
import { BuildingEditModalComponent } from './components/dashboard/project/modals/building-edit-modal.component';
import { ProjectEditModalComponent } from './components/dashboard/project/modals/project-edit-modal.component';
import { ProjectBuildingSelectorModalComponent } from './components/dashboard/project/modals/project-building-selector-modal.component';
@NgModule({
    declarations: [
        DashboardComponent,
        DownloadsComponent,
        ProjectComponent,
        ProjectDetailComponent,
        PlanningsComponent,
        ProjectBuildingSelectorModalComponent,
        QuickStartCardComponent,
        ProjectCardComponent,
        PlanningCardComponent,
        BuildingCardComponent,
        ProjectEditModalComponent,
        BuildingEditModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        FontAwesomeModule,
        UIRouterModule.forChild({ states: DASHBOARD_STATES }),
        NgbModule,
        // self created Modules
        ServicesModule,
        UploadImageModule,
        NgMapsPlacesModule,
        SidebarModule
    ],
    providers: [ProjectService, BuildingService, DownloadService]
})
export class DashboardModule {}
