<form name="loginForm" id="login-form" (ngSubmit)="login()" role="form" #loginForm="ngForm">
    <div class="mb-3">
        <label class="form-label" for="username">
            {{ 'LOGINFORM_EMAIL' | translate }}
        </label>
        <input
            [(ngModel)]="user.username"
            type="email"
            name="username"
            id="username"
            tabindex="0"
            class="form-control form-control-border"
            shdValidateInput
            email
            required
            [customErrorMsg]="'REGISTERFORM_EMAIL_ERROR' | translate" />
    </div>
    <div class="mb-3">
        <label class="form-label" for="password">
            {{ 'LOGINFORM_PASSWORD' | translate }}
        </label>
        <div class="position-relative">
            <input
                [(ngModel)]="user.password"
                type="password"
                shdToggle
                name="password"
                id="password"
                tabindex="0"
                class="form-control form-control-border"
                required
                shdValidateInput />
        </div>
    </div>
    <div class="mb-3 d-flex justify-content-start">
        <a uiSref="requestPassword" tabindex="0" class="btn btn-more-details-small" [attr.data-track]="'landing_requestPassword'">
            <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
            {{ 'LOGINFORM_FORGOTPASSWORD' | translate }}
        </a>
    </div>
    <div class="mb-3">
        <button
            class="btn btn-success"
            [disabled]="loginForm.dirty && loginForm.invalid"
            type="submit"
            name="login-submit"
            id="login-submit"
            tabindex="0">
            {{ 'LOGINFORM_BTN_LOGIN' | translate }}
        </button>
    </div>
    @if (showRequestActivationMailInfo) {
    <div class="mb-3">
        <shd-infobox [headerText]="'LOGINFORM_ACCOUNT_NOT_ACTIVATED_TITLE' | translate">
            <ng-template #body>
                <div class="flex flex-column">
                    <p>{{ 'LOGINFORM_ACCOUNT_NOT_ACTIVATED' | translate }}</p>
                    <div class="d-flex justify-content-end">
                        <button
                            class="btn btn-more-details-small"
                            type="button"
                            name="requestActivationmail"
                            id="requestActivationmail"
                            (click)="requestConfirmationMail()">
                            <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
                            {{ 'LOGINFORM_RECEIVE_CONFIRMATION_EMAIL_AGAIN' | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </shd-infobox>
    </div>
    }
</form>
