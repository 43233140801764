import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Window } from '@obo-main/utils/window.service';
import { Constants } from 'app/constants';
import { Canvg, RenderingContext2D } from 'canvg';
import { PageScrollInstance, PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';
import { Observable, from, timer } from 'rxjs';

@Injectable()
export class Utils {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private pageScrollService: PageScrollService,
        @Optional() @Inject(DOCUMENT) private document: Document
    ) {}

    /**
     * Creates a FormData Object. Appends all Entries of obj to that newly created FormData
     * @param obj
     */
    public createFormDataFromObject(obj: any): FormData {
        return Object.entries(obj).reduce((formData, [key, value]) => {
            formData.append(key, `${value}`);
            return formData;
        }, new FormData());
    }

    /**
     * scrolls to the specified anchor
     * @param element id or class of the element we want to scroll
     * @param delay the delay in milliseconds
     * @param offset amount of pixels the top view shall be above the element
     * @param duration amount of time in milliseconds the scrolling will take
     */
    public scrollTo(element: string, delay: number = 0, offset: number = 200, duration: number = 500): void {
        if (isPlatformBrowser(this.platformId)) {
            let pageScrollInstance: PageScrollInstance = new PageScrollInstance({
                document: this.document,
                scrollTarget: element,
                scrollOffset: Math.max(offset, Constants.NAV_HEIGHT_IN_PX),
                duration: duration,
                _interval: 5,
                easingLogic: (t, b, c, d) => b
            });
            timer(delay).subscribe(() => {
                this.pageScrollService.start(pageScrollInstance);
            });
        }
    }

    /**
     * Parses the FileName from Content Disposition Header
     * @param header
     */
    public parseFileNameFromHeader(headers: HttpHeaders): string {
        let line = headers.get('Content-Disposition');
        if (line) {
            const regEx = new RegExp(/filename="?(.*?)"?;/);
            let fileName = regEx.exec(line);
            if (fileName) {
                return fileName[1];
            }
        }
        return 'UNKNOWN_FILENAME';
    }

    /**
     * returns true if the user uses a mobile device
     */
    public isMobileDevice(): boolean {
        const pattern = new RegExp(/iphone|ipod|ipad|android|iemobile|blackberry|bada/, 'i');
        return pattern.test(window.navigator.userAgent);
    }

    public createImageFromSVG(
        svg: SVGElement,
        width: number,
        height: number,
        backgroundColor: string = 'white',
        format: 'jpeg' | 'png' = 'jpeg',
        quality: number = 1
    ): Observable<Blob> {
        let canvas = new OffscreenCanvas(width, height);
        const ctx = canvas.getContext('2d')!;

        //function is called after the svg is rendered on the canvas. It draws a background in defined background color behind any rendered content on the canvas
        const finishedCanvas: (cv: OffscreenCanvas) => OffscreenCanvas = (cv: OffscreenCanvas) => {
            const ctx = cv.getContext('2d')! as OffscreenCanvasRenderingContext2D;
            ctx.globalAlpha = 1;
            ctx.globalCompositeOperation = 'destination-atop';
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(0, 0, cv.width, cv.height);

            return cv;
        };

        const renderCanvas = async (): Promise<Blob> => {
            const v = await Canvg.from(ctx as RenderingContext2D, svg.outerHTML);

            return await v.render().then(() => {
                if (format === 'jpeg') {
                    canvas = finishedCanvas(canvas);
                }
                return Promise.resolve(
                    canvas.convertToBlob({
                        type: `image/${format}`,
                        quality: quality
                    })
                );
            });
        };

        return from(renderCanvas());
    }

    /* Sorts by property */
    public dynamicSort(property: string, invertOrder: boolean = false) {
        let sortOrder = invertOrder ? -1 : 1;
        return function (a: any, b: any) {
            /* next line only works with strings and numbers */
            var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    public roundNumberTo(number: number, decimalPlaces: number): number {
        const multiplicand = Math.pow(10, decimalPlaces);
        return Math.round(number * multiplicand) / multiplicand;
    }

    public groupBy(key: any, array: any[]): Array<{ key: any; values: Array<any> }> {
        return array.reduce((rv, x) => {
            let v = key instanceof Function ? key(x) : x[key];
            let el = rv.find((r: any) => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({ key: v, values: [x] });
            }
            return rv;
        }, []);
    }

    public base64ToBlob(base64: string, contentType: string) {
        // Decode base64 string
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        // Convert every binary character to its byte representation
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Return Blob object
        return new Blob([bytes], { type: contentType });
    }

    public splitBase64String(base64: string): { mediaType: string; data: string } {
        const regex = new RegExp(/data:(?<mediaType>.+?);base64,(?<data>.+)/);
        if (regex.test(base64)) {
            const result = regex.exec(base64);
            return { mediaType: result.groups.mediaType, data: result.groups.data };
        }
        throw new Error('Invalid Base64 String');
    }

    public equals<T>(x: T, y: T) {
        if (x === y) {
            return true; // if both x and y are null or undefined and exactly the same
        } else if (!(x instanceof Object) || !(y instanceof Object)) {
            return false; // if they are not strictly equal, they both need to be Objects
        } else if (x.constructor !== y.constructor) {
            // they must have the exact same prototype chain, the closest we can do is
            // test their constructor.
            return false;
        } else {
            for (const p in x) {
                if (!x.hasOwnProperty(p)) {
                    continue; // other properties were tested using x.constructor === y.constructor
                }
                if (!y.hasOwnProperty(p)) {
                    return false; // allows to compare x[ p ] and y[ p ] when set to undefined
                }
                if (x[p] === y[p]) {
                    continue; // if they have the same strict value or identity then they are equal
                }
                if (typeof x[p] !== 'object') {
                    return false; // Numbers, Strings, Functions, Booleans must be strictly equal
                }
                if (!this.equals(x[p], y[p])) {
                    return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                    return false;
                }
            }
            return true;
        }
    }
}
// Type definitions for non-npm package offscreencanvas-browser 2019.6
// Project: https://html.spec.whatwg.org/multipage/canvas.html#the-offscreencanvas-interface
// Definitions by: Klaus Reimer <https://github.com/kayahr>
//                        Oleg Varaksin <https://github.com/ova2>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped
// TypeScript Version: 4.3

// https://html.spec.whatwg.org/multipage/canvas.html#dom-canvas-transfercontroltooffscreen
interface HTMLCanvasElement {
    transferControlToOffscreen(): OffscreenCanvas;
}

// https://html.spec.whatwg.org/multipage/canvas.html#offscreencanvasrenderingcontext2d
interface OffscreenCanvasRenderingContext2D
    extends CanvasState,
        CanvasTransform,
        CanvasCompositing,
        CanvasImageSmoothing,
        CanvasFillStrokeStyles,
        CanvasShadowStyles,
        CanvasFilters,
        CanvasRect,
        CanvasDrawPath,
        CanvasText,
        CanvasDrawImage,
        CanvasImageData,
        CanvasPathDrawingStyles,
        CanvasTextDrawingStyles,
        CanvasPath {
    readonly canvas: OffscreenCanvas;
}

declare var OffscreenCanvasRenderingContext2D: {
    prototype: OffscreenCanvasRenderingContext2D;
    new (): OffscreenCanvasRenderingContext2D;
};

// https://html.spec.whatwg.org/multipage/canvas.html#the-offscreencanvas-interface
// Possible contextId values are defined by the enum OffscreenRenderingContextId { "2d", "bitmaprenderer", "webgl", "webgl2" }
// See also description: https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas/getContext
interface OffscreenCanvas extends EventTarget {
    width: number;
    height: number;

    getContext(contextId: '2d', contextAttributes?: CanvasRenderingContext2DSettings): OffscreenCanvasRenderingContext2D | null;

    getContext(contextId: 'bitmaprenderer', contextAttributes?: WebGLContextAttributes): ImageBitmapRenderingContext | null;

    getContext(contextId: 'webgl', contextAttributes?: WebGLContextAttributes): WebGLRenderingContext | null;

    getContext(contextId: 'webgl2', contextAttributes?: WebGLContextAttributes): WebGL2RenderingContext | null;

    convertToBlob(options?: { type?: string | undefined; quality?: number | undefined }): Promise<Blob>;

    transferToImageBitmap(): ImageBitmap;
}

// https://html.spec.whatwg.org/multipage/canvas.html#canvasdrawimage
interface CanvasDrawImage {
    drawImage(image: CanvasImageSource | OffscreenCanvas, dx: number, dy: number): void;

    drawImage(image: CanvasImageSource | OffscreenCanvas, dx: number, dy: number, dw: number, dh: number): void;

    drawImage(
        image: CanvasImageSource | OffscreenCanvas,
        sx: number,
        sy: number,
        sw: number,
        sh: number,
        dx: number,
        dy: number,
        dw: number,
        dh: number
    ): void;
}

// https://html.spec.whatwg.org/multipage/imagebitmap-and-animations.html#dom-createimagebitmap
declare function createImageBitmap(image: ImageBitmapSource | OffscreenCanvas): Promise<ImageBitmap>;
declare function createImageBitmap(
    image: ImageBitmapSource | OffscreenCanvas,
    sx: number,
    sy: number,
    sw: number,
    sh: number
): Promise<ImageBitmap>;

// OffscreenCanvas should be a part of Transferable => extend all postMessage methods
interface Worker {
    postMessage(message: any, transfer?: Array<Transferable | OffscreenCanvas>): void;
}

interface ServiceWorker {
    postMessage(message: any, transfer?: Array<Transferable | OffscreenCanvas>): void;
}

interface MessagePort {
    postMessage(message: any, transfer?: Array<Transferable | OffscreenCanvas>): void;
}

interface Window {
    postMessage(message: any, targetOrigin: string, transfer?: Array<Transferable | OffscreenCanvas>): void;
}

declare function postMessage(message: any, targetOrigin: string, transfer?: Array<Transferable | OffscreenCanvas>): void;

declare var OffscreenCanvas: {
    prototype: OffscreenCanvas;
    new (width: number, height: number): OffscreenCanvas;
};
