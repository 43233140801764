import { Injectable, signal } from '@angular/core';
import { DataGridFilter, DataGridFilterState } from '@obo-admin/dataGrid/models/dataGrid.models';

@Injectable()
export class DataGridFilterService {
    readonly filterState = signal<DataGridFilterState>([]);

    addFilter(filter: DataGridFilter): void {
        let filters = [...this.filterState()];
        let filterToChange = filters.find((f) => f.filters[0].id === filter.filters[0].id);
        if (filterToChange) {
            let indexOfFilterToChange = filters.indexOf(filterToChange);
            filters.splice(indexOfFilterToChange, 1);
        }
        filters.push(filter);
        this.filterState.set(filters);
    }

    removeFilter(field: string): void {
        let filters = [...this.filterState()];
        const filterToRemove = filters.find((f) => f.filters.map((x) => x.id).includes(field));
        if (filterToRemove) {
            const indexToRemove = filters.indexOf(filterToRemove);
            filters.splice(indexToRemove, 1);
            this.filterState.set(filters);
        }
    }

    isFilterActive(field: string): boolean {
        return this.filterState().some((filter) => filter.filters.some((x) => x.id === field));
    }
}
