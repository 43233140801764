@if (currentTourStep && selectedElementRect && isOrbShowing) {
<div
    (mouseenter)="handleOrb()"
    class="tour-orb tour-{{ currentTourStep.orientation }}"
    [style.top.px]="orbTopPosition"
    [style.left.px]="orbLeftPosition"
    [style.transform]="orbTransform">
    <div class="tour-orb-ring"></div>
</div>
} @if (currentTourStep && !isOrbShowing) {
<div>
    <div class="guided-tour-user-input-mask" (click)="backdropClick($event)"></div>
    <div
        class="guided-tour-spotlight-overlay"
        [style.top.px]="overlayTop"
        [style.left.px]="overlayLeft"
        [style.height.px]="overlayHeight"
        [style.width.px]="overlayWidth"></div>
</div>
} @if (currentTourStep && !isOrbShowing) {
<div>
    @if (currentTourStep) {
    <div
        #tourStep
        class="tour-step tour-{{ currentTourStep.orientation }}"
        [ngClass]="{
            'page-tour-step': !currentTourStep.selector
        }"
        [style.top.px]="currentTourStep.selector && selectedElementRect ? topPosition : null"
        [style.left.px]="currentTourStep.selector && selectedElementRect ? leftPosition : null"
        [style.width.px]="currentTourStep.selector && selectedElementRect ? calculatedTourStepWidth : null"
        [style.transform]="currentTourStep.selector && selectedElementRect ? transform : null">
        @if (currentTourStep.selector) {
        <div class="tour-arrow"></div>
        }
        <div class="tour-block">
            @if ( progressIndicatorLocation === progressIndicatorLocations.TopOfTourBlock && !guidedTourService.onResizeMessage )
            {
            <div class="tour-progress-indicator">
                <ng-container *ngTemplateOutlet="progress"></ng-container>
            </div>
            }
            <div class="d-flex justify-content-between align-items-start">
                @if (currentTourStep.title && currentTourStep.selector) {
                <h3 class="tour-title">
                    {{ currentTourStep.title }}
                </h3>
                } @if (currentTourStep.title && !currentTourStep.selector) {
                <h2 class="tour-title">
                    {{ currentTourStep.title }}
                </h2>
                } @if (!guidedTourService.onFirstStep) {
                <button (click)="guidedTourService.skipTour()" class="btn p-0">
                    <fa-icon [icon]="['fal', 'times']"></fa-icon>
                </button>
                }
            </div>
            @if (isTemplate(currentTourStep.content)) {
            <div class="tour-content">
                <ng-template [ngTemplateOutlet]="currentTourStep.content"></ng-template>
            </div>
            } @else {
            <div class="tour-content" [innerHTML]="currentTourStep.content"></div>
            }
            <div class="tour-buttons">
                @if (!guidedTourService.onResizeMessage && guidedTourService.onFirstStep) {
                <button (click)="guidedTourService.skipTour()" class="btn btn-secondary">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                } @if (!guidedTourService.onFirstStep && !guidedTourService.onResizeMessage) {
                <button class="btn btn-step-back" (click)="guidedTourService.backStep()">
                    <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                    {{ 'BTN_GOBACK' | translate }}
                </button>
                } @if (!guidedTourService.onLastStep && !guidedTourService.onResizeMessage) {
                <button class="btn btn-success" (click)="guidedTourService.nextStep()" ngbAutofocus>
                    {{ 'BTN_GOAHEAD' | translate }}
                    @if (progressIndicatorLocation === progressIndicatorLocations.InsideNextButton) {
                    <ng-container *ngTemplateOutlet="progress"></ng-container>
                    }
                </button>
                } @if (guidedTourService.onLastStep) {
                <button class="btn btn-success" (click)="guidedTourService.nextStep()" ngbAutofocus>
                    {{ 'BTN_DONE' | translate }}
                </button>
                } @if (guidedTourService.onResizeMessage) {
                <button class="btn btn-success" (click)="guidedTourService.resetTour()" ngbAutofocus>
                    {{ 'BTN_CLOSE' | translate }}
                </button>
                }
            </div>
        </div>
    </div>
    }
</div>
}
<ng-template #progress>
    <ng-container
        *ngTemplateOutlet="
            progressIndicator || defaultProgressIndicator;
            context: {
                currentStepNumber: guidedTourService.currentTourStepDisplay,
                totalSteps: guidedTourService.currentTourStepCount
            }
        "></ng-container>
</ng-template>
<ng-template #defaultProgressIndicator let-currentStepNumber="currentStepNumber" let-totalSteps="totalSteps">
    @if (progressIndicatorLocation === progressIndicatorLocations.InsideNextButton) { &nbsp; }
    {{ currentStepNumber }}/{{ totalSteps }}
</ng-template>
