import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MotdMessage } from '@obo-main/services/motd/motd.models';
import { concatAll, groupBy, mergeMap, toArray } from 'rxjs/operators';

@Injectable()
export class MotdService {
    constructor(private http: HttpClient, @Inject('APIPREFIX') private apiPrefix: string) {}

    public getMotdMessages(): Observable<MotdMessage[][]> {
        return this.http.get<MotdMessage[]>(`${this.apiPrefix}Notifications`).pipe(
            concatAll(),
            groupBy((m) => m.id),
            mergeMap((m) => m.pipe(toArray())),
            toArray()
        );
    }
}
