<div class="modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'NAVIGATION_REGION_AND_LANGUAGE' | translate }}
        </h4>
        @if (dismiss) {
            <button type="button" class="btn-close" (click)="dismiss()"></button>
        }
    </div>
    <div class="modal-body pb-0 webkit-scrollbar" [class.alert-showing]="!selectedRegion || !selectedLanguage">
        @if (!selectedRegion || !selectedLanguage) {
            <div class="mb-3">
                <shd-infobox [headerText]="'REGIONSELECTORMODAL_TEXT' | translate"></shd-infobox>
            </div>
        }
        <div class="row h-100 d-block d-xl-flex">
            <div class="col-12 col-xl-4 selection-container webkit-scrollbar">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">{{ 'SELECT_REGION' | translate }}</label>
                            <a class="btn btn-blue" [class.active]="showRegionSelection"
                               (click)="showRegionSelection=true">
                                    <span class="d-flex align-items-center justify-content-between">
                                        @if (selectedRegion) {
                                            <ng-container [ngTemplateOutlet]="regionTpl"
                                                          [ngTemplateOutletContext]="{$implicit: selectedRegion}"></ng-container>
                                        } @else {
                                            -
                                        }
                                        <span><fa-icon [icon]="['fal', 'chevron-right']" size="lg"></fa-icon></span>
                                    </span>
                            </a>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label">{{ 'SELECT_LANGUAGE' | translate }}</label>
                        <a class="btn btn-blue" [class.active]="!showRegionSelection"
                           (click)="showRegionSelection=false">
                                   <span class="d-flex align-items-center justify-content-between">
                                       @if (selectedLanguage) {
                                           <ng-container [ngTemplateOutlet]="languageTpl"
                                                         [ngTemplateOutletContext]="{$implicit: selectedLanguage}"></ng-container>
                                       } @else {
                                           -
                                       }
                                       <span><fa-icon [icon]="['fal', 'chevron-right']" size="lg"></fa-icon></span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-8 regionLanguage-container webkit-scrollbar">
                @if (showRegionSelection) {
                    <shd-radio-group groupClass="row" optionClass="col-12 col-xl-6 mb-3"
                                     [ngModel]="selectedRegion"
                                     direction="horizontal">
                        @for (region of portalRegions; track region.id) {
                            <ng-template
                                shdRadioGroupOption
                                [value]="region" (selected)="selectedRegion= region">
                                <ng-container [ngTemplateOutlet]="regionTpl"
                                              [ngTemplateOutletContext]="{$implicit: region}"></ng-container>
                            </ng-template>
                        }
                    </shd-radio-group>
                } @else {
                    <shd-radio-group groupClass="row" optionClass="col-12 col-xl-6 mb-3"
                                     [ngModel]="selectedLanguage"
                                     direction="horizontal">
                        @for (language of languages; track language.id) {
                            <ng-template
                                shdRadioGroupOption
                                [value]="language" (selected)="selectedLanguage = language">
                                <ng-container [ngTemplateOutlet]="languageTpl"
                                              [ngTemplateOutletContext]="{$implicit: language}"></ng-container>
                            </ng-template>
                        }
                    </shd-radio-group>
                }
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success ms-auto" type="button"
                [disabled]="!selectedRegion || !selectedLanguage" (click)="submit()">
            {{ 'BTN_SAVE' | translate }}
        </button>
    </div>
</div>

<ng-template #languageTpl let-language>
      <span class="label-text d-flex align-items-center">
                                <span>{{ language?.displayName | translate }}</span>
                                     </span>
</ng-template>

<ng-template #regionTpl let-region>
       <span class="label-text d-flex align-items-center">
                                <span class="flag-icon flag-icon-{{ region?.name | lowercase }} me-2"></span>
                                <span>
                                    {{ region?.displayName | translate }}
                                </span>
                                              </span>
</ng-template>
