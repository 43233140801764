<ng-template #modal>
    @if (fg) {
        <form [formGroup]="fg">
            <div class="modal">
                <div class="modal-header">
                    <h3 class="modal-title" id="modal-basic-title">
                        {{ fg.value.displayName }}
                    </h3>
                    <button type="button" class="btn-close" (click)="close()"></button>
                </div>
                <div class="modal-body">
                    <div class="d-lg-flex d-none flex-column">
                        <div class="mb-3">
                            <h5>Page Url</h5>
                        </div>
                    </div>
                    @for (fg of formFeatures.controls; track fg; let i = $index) {
                        <div class="d-flex flex-column" [formGroup]="fg">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    formControlName="isActive"
                                    [id]="i + 'isActive'"
                                    class="form-check-input" />
                                <label class="form-check-label text-muted" [for]="i + 'isActive'">
                                    {{ fg.value.name }}
                                </label>
                            </div>
                            <div class="mb-3">
                                <label [for]="i + 'url'" class="form-label d-lg-none d-inline-block">Page Url</label>
                                <input class="form-control" type="text" [id]="i + 'url'" formControlName="url"
                                       placeholder="Page Url" />
                            </div>
                        </div>
                    }
                </div>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <button class="btn btn-secondary" type="button" (click)="close()">
                            {{ 'BTN_ABORT' | translate }}
                        </button>
                        <button class="btn btn-success" type="button" (click)="confirm()">
                            {{ 'BTN_SAVE' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    }
</ng-template>
