import { Component, ContentChild, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExpandableCardContentDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardContent.directive';
import { ExpandableCardHeaderDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardHeader.directive';
import { ExpandableCardToggleButtonDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardToggleButton.directive';

@Component({
    selector: 'shd-expandable-card',
    templateUrl: './expandableCard.component.html',
    styleUrls: ['./expandableCard.component.scss']
})
export class ExpandableCardComponent implements OnDestroy {
    @Input()
    public showAsCard: boolean = true;
    @Input()
    public isExpanded: boolean = false;
    @Input()
    public showToggleButton: boolean = true;
    @Input()
    loadData: () => Observable<any>;

    @Output()
    toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    public dataLoaded: boolean = false;

    @ContentChild(ExpandableCardHeaderDirective)
    public header: ExpandableCardHeaderDirective;
    @ContentChild(ExpandableCardContentDirective)
    public content: ExpandableCardContentDirective;
    @ContentChild(ExpandableCardToggleButtonDirective)
    public toggleButton: ExpandableCardToggleButtonDirective;

    private onDestroy = new Subject();

    constructor() {}

    toggle(): void {
        this.isExpanded = !this.isExpanded;
        this.toggled.emit(this.isExpanded);
        if (this.loadData && !this.dataLoaded) {
            this.loadData().subscribe(() => {
                this.dataLoaded = true;
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }
}
