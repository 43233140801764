import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ElbridgeFormService } from '@obo-common/shared/services/elbridgeForm.service';
import { ElbridgeData, ElbridgeInfo } from '@obo-main/services/elbridge/elbridge.models';
import { Observable } from 'rxjs';

@Directive({ selector: '[shdElbridgeRedirect], [shdElbridgeAbort]' })
export class ElbridgeRedirectDirective {
    @Input()
    public shdElbridgeRedirect: () => Observable<ElbridgeData>; // function to call after the button is clicked, but before the form is submitted. This function must return an Observable which emits the ElbridgeData
    @Input()
    public shdElbridgeAbort: () => Observable<ElbridgeInfo>;

    constructor(private elementRef: ElementRef, private elbridgeFormService: ElbridgeFormService, private renderer: Renderer2) {}

    @HostListener('click')
    public onButtonClick(): void {
        if (this.shdElbridgeAbort) {
            this.shdElbridgeAbort().subscribe((elbridgeInfo) => {
                const form = this.elbridgeFormService.getElbridgeInfoForm(this.renderer, this.elementRef, elbridgeInfo);
                form.submit();
            });
        } else if (this.shdElbridgeRedirect) {
            this.shdElbridgeRedirect().subscribe((elbridgeData) => {
                this.elbridgeFormService.getElbridgeDataForm(this.renderer, this.elementRef, elbridgeData).submit();
            });
        } else {
            throw new Error('No Datasource available for Elbridge action');
        }
    }
}
