import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { STATIC_STATES } from '@obo-static/static.states';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { ContactModule } from '@obo-static/contact/contact.module';
import { AbstractStaticComponent } from '@obo-static/abstractStatic/AbstractStatic.component';
import { DisplayContentComponent } from '@obo-static/displayContent/displayContent.component';
import { DisplayErrorComponent } from '@obo-static/displayError/displayError.component';
import { ContactFormService } from '@obo-static/contact/contactForm/contactForm.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { ElbridgeFormComponent } from '@obo-static/elbridgeForm/elbridgeForm.component';
import { NoProductsFoundComponent } from '@obo-static/noProductsFound/noProductsFound.component';

/**
 * This Module contains some static site content such as imprint, termsofservice
 * and other simple content which does not require much logic and does not belonh to any other specifig module
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        UIRouterModule.forChild({ states: STATIC_STATES }),
        NgbModule,
        ReactiveFormsModule,
        SharedDirectivesModule,
        SharedPipesModule,
        ContactModule,
        FontAwesomeModule,
        SharedComponentsModule
    ],
    declarations: [
        AbstractStaticComponent,
        DisplayContentComponent,
        DisplayErrorComponent,
        ElbridgeFormComponent,
        NoProductsFoundComponent
    ],
    providers: [ContactFormService],
    exports: [
        AbstractStaticComponent,
        DisplayContentComponent,
        DisplayErrorComponent,
        ElbridgeFormComponent,
        NoProductsFoundComponent
    ]
})
export class StaticModule {}
