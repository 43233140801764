import { DownloadsComponent } from '@obo-dashboard/components/downloads/downloads.component';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { Constants } from 'app/constants';

export const downloadsState: Ng2StateDeclaration = {
    parent: 'app',
    url: '/downloads',
    name: 'downloadsOverwiew',
    component: DownloadsComponent,
    data: {
        module: 'main',
        roles: [Constants.Role.User]
    }
};
