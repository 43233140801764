@if (data) {
    <shd-single-select withBorder="true" [(ngModel)]="customValue">
        <ng-template shdSingleSelectLabel>
            {{ customValue.label }}
        </ng-template>
        @for (customFilter of data; track $index) {
            <ng-template shdSingleSelectOption [value]="customFilter">
                {{ customFilter.label }}
            </ng-template>
        }
    </shd-single-select>
} @else {
    <shd-single-select withBorder="true" [(ngModel)]="filterOperator">
        <ng-template shdSingleSelectLabel>
            {{ filterOperator }}
        </ng-template>
        @for (operator of filterOperators; track $index) {
            <ng-template shdSingleSelectOption [value]="operator">
                {{ operator }}
            </ng-template>
        }
    </shd-single-select>
    <input [type]="type" required class="form-control form-control-border"
           [(ngModel)]="filterValue" />
}
<div class="data-grid-filter-buttons">
    <button type="button" class="btn btn-secondary" (click)="clear()">Clear</button>
    <button type="button" class="btn btn-success"
            [disabled]="!filterValue && !customValue"
            (click)="applyFilter()">Filter
    </button>
</div>

               
