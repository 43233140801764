import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[shdRadioGroupOption]' })
export class RadioGroupOptionDirective {
    @Input()
    public value: any;
    @Input()
    public active: boolean = true;
    @Input()
    public showRadio: boolean = true;
    @Output()
    public selected: EventEmitter<any> = new EventEmitter<any>();
    constructor(public templateRef: TemplateRef<any>) {}
}
