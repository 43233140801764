<div class="custom-dropdown w-100">
    <div (openChange)="onOpenChange($event)" ngbDropdown [placement]="placement" [display]="display"
         [container]="container" class="d-block" [id]="id" autoClose="false">
        <button
            type="button"
            [disabled]="disabled"
            class="form-control d-flex justify-content-between align-items-center"
            ngbDropdownToggle
            [class.form-control-border]="withBorder">
            <div class="label-container">
                @if (selectedValue) {
                    @if (labelTpl) {
                        <ng-template [ngTemplateOutlet]="labelTpl.templateRef"></ng-template>
                    } @else {
                        <ng-template [ngTemplateOutlet]="selectedOptionTpl.templateRef"></ng-template>
                    }
                } @else {
                    {{ placeHolder }}
                }
            </div>
            <fa-icon [icon]="['fal', 'angle-down']" size="2x"></fa-icon>
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
            <div class="items">
                @if (grouped) {
                    @for (optionGroup of groupedOptions; track optionGroup) {
                        <div class="text-muted">
                            {{ optionGroup.key }}
                        </div>
                        @for (option of optionGroup.values; track option) {
                            <a
                                class="item"
                                (click)="selectOption(option)"
                                [attr.data-track]="option.attDataTrack ? option.attDataTrack : null">
                                <ng-template [ngTemplateOutlet]="option.templateRef"></ng-template>
                            </a>
                        }
                    }
                } @else {
                    @for (optionTpl of optionTemplates; track optionTpl) {
                        @if (optionTpl.active) {
                            <a
                                class="item"
                                (click)="selectOption(optionTpl)"
                                [attr.data-track]="optionTpl.attDataTrack ? optionTpl.attDataTrack : null">
                                <ng-template [ngTemplateOutlet]="optionTpl.templateRef"></ng-template>
                            </a>
                        }
                    }
                }
            </div>
        </div>
    </div>
</div>
