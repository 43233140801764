import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@obo-main/services/language/language.service';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private languageService: LanguageService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.has('Accept-Language')) {
            //apply language
            const newRequest = request.clone({
                headers: request.headers.set('Accept-Language', this.languageService.applicationLanguage.name)
            });
            return next.handle(newRequest);
        }
        return next.handle(request);
    }
}
