<div ngbDropdown autoClose="outside" [display]="'static'" (openChange)="handleOpenChange($event)">
    <button
        class="form-control"
        [ngClass]="{ 'form-control-border': withBorder, 'ng-invalid': filter.invalid }"
        type="button"
        [id]="id"
        ngbDropdownToggle
        [disabled]="filter.disabled">
        <span>{{ filter.label }}</span>
        <fa-icon [icon]="['fal', 'angle-down']" size="2x"></fa-icon>
    </button>
    <form [formGroup]="filter" (ngSubmit)="handleSubmit()" ngbDropdownMenu [attr.aria-labelledby]="id">
        <div class="items">
            @for (option of filter.controls; track option) {
            <a ngbDropdownItem class="item">
                <ng-template
                    [ngTemplateOutlet]="filterOptionTpl ? filterOptionTpl?.templateRef : defaultFilterOptionTpl"
                    [ngTemplateOutletContext]="{
                        $implicit: option
                    }"></ng-template>
            </a>
            }
        </div>
        <div class="actions">
            @if (showAllSelectButtons) { @if (selectAllBtnText) {
            <button class="btn btn-more-details-small" type="button" (click)="selectAll()">
                {{ selectAllBtnText }}
                <fa-icon [icon]="['fal', 'check-square']"></fa-icon>
            </button>
            } @if (unselectAllBtnText) {
            <button class="btn btn-more-details-small" type="button" (click)="unselectAll()">
                {{ unselectAllBtnText }}
                <fa-icon [icon]="['fal', 'square']"></fa-icon>
            </button>
            } }
            <button class="btn btn-success apply" type="submit">
                {{ applyBtnText }}
            </button>
        </div>
    </form>
</div>
<ng-template #defaultFilterOptionTpl let-option>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" [id]="'option_' + option.label" [formControl]="option" />
        <label class="form-check-label" [for]="'option_' + option.label">{{ option.label }} {{ option.prediction }}</label>
    </div>
</ng-template>
