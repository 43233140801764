import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FloatingActionButtonItemComponent } from '@obo-common/shared/components/floating-action-button/floating-action-button-item/floating-action-button-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'shd-floating-action-button',
    templateUrl: './floating-action-button.component.html',
    styleUrls: ['./floating-action-button.component.scss']
})
export class FloatingActionButtonComponent implements AfterContentInit, OnDestroy, OnChanges {
    public state: BehaviorSubject<any>;

    @Input()
    fabId: string;
    @Input()
    faIcon: string;
    @Input()
    faPrefix: string;
    @Input()
    isSecondBtn: boolean = false;
    @Input()
    direction: string;
    @Input()
    label: string;
    @Input()
    buttonColor: string;
    @Input()
    spaceBetweenButtons = 60;
    @Input()
    open: Subject<boolean>;
    @Input()
    disabled = false;
    @Output()
    events: Subject<any> = new Subject();
    @ContentChildren(FloatingActionButtonItemComponent)
    buttons: QueryList<FloatingActionButtonItemComponent>;

    private _destroy$ = new Subject();

    public hasNoChildren = false;

    constructor(private cd: ChangeDetectorRef) {
        this.state = new BehaviorSubject({
            display: false,
            direction: 'top',
            event: 'start',
            spaceBetweenButtons: this.spaceBetweenButtons
        });
    }

    public toggle() {
        this.state.next({
            ...this.state.getValue(),
            display: !this.state.getValue().display,
            event: !this.state.getValue().display ? 'open' : 'close'
        });
    }

    public get isOpen(): boolean {
        return this.state.getValue()?.event === 'open';
    }

    // only top and bottom support content element
    private checkDirectionType() {
        if (this.buttons.toArray()) {
            let display = 'block';

            if (this.direction === 'right' || this.direction === 'left') {
                display = 'none';
            }

            this.buttons.toArray().forEach((element: any) => {
                element.contentref.nativeElement.style.display = display;
            });
        }
    }

    // transition
    private animateButtons(eventType: any) {
        this.buttons.toArray().forEach((btn: FloatingActionButtonItemComponent, i: number) => {
            i += 1;
            const style = btn.elementRef.nativeElement.style;

            if (eventType !== 'directionChanged' && this.state.getValue().display) {
                style['transform'] = 'scale(1)';
                style['transition-duration'] = '0s';

                if (btn.timeout) {
                    clearTimeout(btn.timeout);
                }
            }

            setTimeout(() => {
                style['transition-duration'] = this.state.getValue().display ? `${90 + 100 * i}ms` : '';
                style['transform'] = this.state.getValue().display ? this.getTranslate(i) : '';
            }, 50);

            if (eventType !== 'directionChanged' && !this.state.getValue().display) {
                btn.timeout = setTimeout(() => {
                    style['transform'] = 'scale(0)';
                }, 90 + 100 * i);
            }
        });
    }

    // get transition direction
    private getTranslate(i: number) {
        let animation: string;

        switch (this.direction) {
            case 'right':
                animation = `translate(${this.state.getValue().spaceBetweenButtons * i}px,0)`;
                break;
            case 'bottom':
                animation = `translate(0,${this.state.getValue().spaceBetweenButtons * i}px)`;
                break;
            case 'left':
                animation = `translate(-${this.state.getValue().spaceBetweenButtons * i}px,0)`;
                break;
            default:
                animation = `translate(0,-${this.state.getValue().spaceBetweenButtons * i}px)`;
                break;
        }

        return animation;
    }

    ngAfterContentInit() {
        if (this.direction) {
            // first time to check
            this.checkDirectionType();
        }

        this.buttons.toArray().map((v: FloatingActionButtonItemComponent) => {
            v.clicked.pipe(takeUntil(this._destroy$)).subscribe(() => {
                this.state.next({
                    ...this.state.getValue(),
                    display: false,
                    event: 'close'
                });
            });
        });

        this.state.pipe(takeUntil(this._destroy$)).subscribe((v) => {
            this.animateButtons(v.event);

            this.events.next({
                display: v.display,
                event: v.event,
                direction: v.direction
            });
        });

        this.hasNoChildren = this.buttons.length == 0;
    }

    // if @Input values changes, we need check the direction type
    ngOnChanges(changes: any) {
        if (changes.direction && !changes.direction.firstChange) {
            this.state.next({
                ...this.state.getValue(),
                event: 'directionChanged',
                direction: changes.direction.currentValue
            });
            // if changes happens
            this.checkDirectionType();
        }

        if (changes.open && changes.open.currentValue) {
            this.open.pipe(takeUntil(this._destroy$)).subscribe((v) => {
                if (v !== this.state.getValue().display) {
                    this.state.next({
                        ...this.state.getValue(),
                        event: v ? 'open' : 'close',
                        display: v
                    });

                    // make angular happy
                    this.cd.markForCheck();
                }
            });
        }

        if (changes.spaceBetweenButtons && changes.spaceBetweenButtons.currentValue) {
            this.state.next({
                ...this.state.getValue(),
                event: 'spaceBetweenButtonsChanged',
                spaceBetweenButtons: changes.spaceBetweenButtons.currentValue
            });
        }
    }

    ngOnDestroy() {
        this._destroy$.next(1);
    }
}
