import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FloatingActionButtonItemContentDirective } from '@obo-common/shared/components/floating-action-button/floating-action-button-item/floatingActionButtonItemContent.directive';

@Component({
    selector: 'shd-floating-action-button-item',
    templateUrl: './floating-action-button-item.component.html',
    styleUrls: ['./floating-action-button-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingActionButtonItemComponent {
    @Input()
    buttonClass: string;
    @Input()
    faIcon: string;
    @Input()
    faPrefix: string;
    @Input()
    content: string;
    @Input()
    disabled: boolean = false;
    @Input()
    hasText: boolean = true;
    @ContentChild(FloatingActionButtonItemContentDirective)
    contentTpl: FloatingActionButtonItemContentDirective;

    @Output()
    clicked: EventEmitter<any> = new EventEmitter();

    timeout: any;

    @ViewChild('elementRef', { static: true })
    elementRef: ElementRef;

    emitClickEvent($event: Event): void {
        this.clicked.emit($event);
    }
}
