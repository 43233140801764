import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Utils } from '@obo-main/utils/utils.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Culture, ExcelExportResponse } from '@obo-admin/admin.models';
import { DataGridResult } from '@obo-admin/dataGrid/models/dataGrid.models';
import { TranslationItem, TranslationItemDto } from '@obo-admin/translationManagement/models/translationManagementItem.model';

@Injectable()
export class TranslationManagementService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private utils: Utils) {}

    public exportTranslationFile(
        sourceId: number,
        targetIds: number[],
        moduleIds: number[],
        options: number
    ): Observable<ExcelExportResponse> {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return this.http
            .get(`${this.apiPrefix}Administration/Localizations/Exports/Xliff`, {
                headers: new HttpHeaders().append('accept', fileType),
                params: new HttpParams()
                    .append('sourceId', sourceId.toString())
                    .append('targetIds', targetIds.join(','))
                    .append('moduleIds', moduleIds.join(','))
                    .append('options', options.toString()),

                observe: 'response',
                responseType: 'arraybuffer'
            })
            .pipe(
                map((response: HttpResponse<ArrayBuffer>) => {
                    return <ExcelExportResponse>{
                        name: this.utils.parseFileNameFromHeader(response.headers),
                        blob: new Blob([response.body!], {
                            type: fileType
                        })
                    };
                })
            );
    }

    public importTranslationFile(files: File[]): Observable<any> {
        let formData: FormData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file-${index}`, file);
        });
        return this.http.post(`${this.apiPrefix}Administration/Localizations/Imports/Xliff`, formData);
    }

    public getTranslatableModules(): Observable<{ id: number; name: string }[]> {
        return this.http.get<any>(`${this.apiPrefix}Administration/Modules`);
    }

    public getTranslatableCultures(): Observable<Culture[]> {
        return this.http.get<DataGridResult>(`${this.apiPrefix}Administration/Cultures`).pipe(map((data) => data.items));
    }

    public getLocalizations(): Observable<TranslationItemDto[]> {
        return this.http.get<TranslationItemDto[]>(`${this.apiPrefix}Administration/Localizations`);
    }

    public deleteLocalization(localization: TranslationItem): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('key', localization.key);
        httpParams = httpParams.set('moduleId', localization.moduleId);
        return this.http.delete(this.localizationUrl, { params: httpParams });
    }

    public createLocalization(localization: TranslationItemDto): Observable<any> {
        return this.http.post(this.localizationUrl, localization);
    }

    public updateLocalization(localization: TranslationItemDto): Observable<any> {
        return this.http.put(this.localizationUrl, localization);
    }

    private get localizationUrl(): string {
        return `${this.apiPrefix}Administration/Localizations`;
    }
}
