import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { DataGridResult } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridExcelColumnComponent } from '@obo-admin/dataGrid/components/dataGridExcel/dataGridExcelColumn/dataGridExcelColumn.component';

@Component({
    selector: 'adm-data-grid-excel',
    template: ''
})
export class DataGridExcelComponent {
    @Input()
    public fileName: string;
    @Input()
    public fetchData: () => Observable<DataGridResult>;

    @ContentChildren(DataGridExcelColumnComponent)
    excelColumns: QueryList<DataGridExcelColumnComponent>;
}
