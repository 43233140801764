import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { RegionService } from '@obo-main/services/region/region.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Observable, of, throwError } from 'rxjs';
import { Region } from '@obo-main/services/region/region.models';
import { catchError } from 'rxjs/operators';

@Pipe({ name: 'region' })
@Injectable()
export class RegionPipe implements PipeTransform {
    constructor(private regionService: RegionService, private logger: Logger) {}

    public transform(value: number): Observable<Region> {
        if (typeof value !== 'number') {
            const errMsg = 'Regionpipe required number as input!';
            this.logger.error(errMsg);
            return throwError(errMsg);
        }
        return this.regionService.findRegionById(value).pipe(
            catchError(() =>
                of({
                    id: value,
                    displayName: 'Unknown_Region',
                    name: '..'
                } as Region)
            )
        );
    }
}
