<section class="container" id="admin-overview">
    <div class="row">
        @for (item of adminNav; track item) {
        <div class="col-12 col-md-6 col-lg-3 my-4" *shdCheckRoles="item.roles">
            <div class="card card-no-shadow card-no-border h-100 mb-0">
                <div class="card-block d-flex flex-column justify-content-between">
                    <a class="urlhover" [uiSref]="item.uiSref" (click)="closed.next(1)">
                        <div class="d-flex justify-content-center p-5 card-icon">
                            <fa-icon [icon]="item.icon" size="3x"></fa-icon>
                        </div>
                        <div class="p-3">
                            <h3 class="card-title">
                                {{ item.key | translate }}
                            </h3>
                            <p class="card-text">
                                {{ item.description | translate }}
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        }
        <div class="col-12 col-md-6 col-lg-3 my-4">
            <div class="card card-no-shadow card-no-border h-100 mb-0">
                <div class="card-block d-flex flex-column justify-content-between">
                    <a class="urlhover" (click)="downloadSitemap()" role="button">
                        <div class="d-flex justify-content-center p-5 card-icon">
                            <fa-icon [icon]="['fal', 'sitemap']" size="3x"></fa-icon>
                        </div>
                        <div class="p-3">
                            <h3 class="card-title">
                                {{ 'ADMIN_DOWNLOADSITEMAP' | translate }}
                            </h3>
                            <p class="card-text">
                                {{ 'ADMIN_DOWNLOADSITEMAP_DESC' | translate }}
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
