import { AnimationBuilder, AnimationPlayer, animate, style } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Window } from '@obo-main/utils/window.service';
import { Observable, Subject, empty, fromEvent, interval } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil, takeWhile, throttleTime } from 'rxjs/operators';
import { InfiniteCarouselItemDirective } from './infiniteCarouselItem.directive';

@Component({
    selector: 'shd-infinite-carousel',
    templateUrl: './infiniteCarousel.component.html',
    styleUrls: ['./infiniteCarousel.component.scss'],
    exportAs: 'infiniteCarousel'
})
export class InfiniteCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public gridClasses: string = 'col-6 col-md-4 col-lg-2'; // classes to apply at every carouselItemContainer
    @Input()
    public useGrid: boolean = true; // wether you use the bootstrap grid classes or not
    @Input()
    public gap: number = 32; // the flex grid to use if useGrid is false
    @Input()
    public cycleTime: number = 5000; // time in milliseconds to go forward automatically. Set to 0 to disable cycling!
    @Input()
    public stopCycleOnClick: boolean = true; // if true, the cycle stops if the user clicks any element in the carousel
    @Input()
    public cylceDirection: 'left' | 'right' = 'right';
    @Input()
    public animationTime: number = 200; // millisoconds the next/prev animation shall take

    @ContentChildren(InfiniteCarouselItemDirective)
    public items: QueryList<InfiniteCarouselItemDirective>;

    @ViewChild('viewPort')
    public viewPort: ElementRef; // the viewPort
    @ViewChild('itemContainer')
    public itemContainer: ElementRef; // the container of carouselItems. Will grow the more items are there
    @ViewChildren('itemRef')
    public itemRefs: QueryList<ElementRef>; // just an elementRef to the carouselItemContainers

    @HostListener('click')
    public onClick(): void {
        if (this.stopCycleOnClick) {
            this.stopCycle();
        }
    }

    public showControls: boolean = false; // display next/prev button or not
    public itemsArray: Array<InfiniteCarouselItemDirective> = new Array();
    public cycleActive: boolean = true;

    private onDestroy = new Subject<any>();
    private currentTranslateValue: number = 0;
    private translateXMultiplier: number = 1;
    private cycleSubject = new Subject<number>();
    private onButtonClick = new Subject<() => void>();

    constructor(
        private logger: Logger,
        private renderer: Renderer2,
        @Inject(Window) private window: Window,
        private animationBuilder: AnimationBuilder
    ) {}

    public ngOnInit(): void {
        fromEvent(this.window, 'resize')
            .pipe(debounceTime(500), takeUntil(this.onDestroy))
            .subscribe(() => {
                this.updateDisplayControls();
                this.updateItems().subscribe(() => {
                    this.updateTranslateXValue();
                });
            });
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.itemsArray = this.items.toArray();
            setTimeout(() => {
                this.updateItems().subscribe(() => {
                    this.updateTranslateXValue();
                });
            });
        });

        this.items.changes.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.updateItems().subscribe(() => {
                this.updateTranslateXValue();
            });
        }); // update items when amount of items changes

        this.cycleSubject
            .pipe(
                startWith(this.cycleTime),
                switchMap((ms) => {
                    this.logger.debug(`InfiniteCarousel: cycleInterval changed to ${ms} milliseconds`);
                    if (ms > 0) {
                        if (ms < this.animationTime) {
                            this.logger.error(
                                `InfiniteCarousel: Cycletime cannot be higher than animationTime! CycleTime is: ${this.cycleTime}, animationTime is: ${this.animationTime}`
                            );
                        }
                        return interval(Math.max(this.cycleTime, this.animationTime));
                    } else {
                        return empty();
                    }
                }),
                takeWhile(() => this.cycleActive),
                takeUntil(this.onDestroy)
            )
            .subscribe((idx) => {
                if (this.cylceDirection === 'right') this.next();
                else this.prev();
            });

        this.onButtonClick
            .pipe(
                throttleTime(this.animationTime) // throttle the action, so we sont have glitchy animations when user clicks to fast
            )
            .subscribe((fn) => fn());
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public trackByFn(index: number, item: InfiniteCarouselItemDirective): any {
        return item.identifier;
    }

    /**
     * moves the carousel one step to the left
     */
    public prev(): void {
        if (this.showControls) {
            // only available when there are more items than fitting in the viewPort
            this.onButtonClick.next(() => {
                const animationPlayer = this.createSlideAnimation(false);
                animationPlayer.onDone(() => {
                    const firstElem = this.itemsArray.shift();
                    this.itemsArray.push(firstElem!);
                    animationPlayer.destroy();
                });
                animationPlayer.play();
            });
        }
    }

    /**
     * move the carousel one step to the right
     */
    public next(): void {
        if (this.showControls) {
            // only available when there are more items than fitting in the viewPort
            this.onButtonClick.next(() => {
                const animationPlayer = this.createSlideAnimation(true);
                animationPlayer.onDone(() => {
                    const lastElem = this.itemsArray.pop();
                    this.itemsArray.unshift(lastElem!);
                    animationPlayer.destroy();
                });
                animationPlayer.play();
            });
        }
    }

    /**
     * stops the cycling of items
     */
    public stopCycle(): void {
        this.cycleActive = false;
    }

    /**
     * updates the item Index Array
     * Very creepy function with 3 timeouts. But they are needed :()
     */
    private updateItems(): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            this.cycleSubject.next(0);
            this.updateDisplayControls(); // because the displaycontrols affect the container width, we need to refresh it before updating the array
            setTimeout(() => {
                this.createItemArray(this.items);
                setTimeout(() => {
                    this.updateDisplayControls(); // after creation of array we must refresh controls again, because we could have more or less elements than before
                    setTimeout(() => {
                        if (this.cycleActive && this.showControls) {
                            this.cycleSubject.next(this.cycleTime);
                        }
                        obs.next(true);
                        obs.complete();
                    });
                });
            });
        });
    }

    /**
     * returns the width of one carouselItem.
     */
    private getCarouselItemWidth(): number {
        if (!this.itemRefs.first) return 0;
        return this.useGrid
            ? this.itemRefs.first.nativeElement.offsetWidth
            : this.itemRefs.first.nativeElement.offsetWidth + this.gap;
    }

    /**
     * returns the width of the carouselItemContainer
     */
    private getCarouselItemContainerWidth(): number {
        return this.viewPort.nativeElement.offsetWidth;
    }

    /**
     * creates the indexArray from the CarouselItem's queryList
     * @param items
     */
    private createItemArray(items: QueryList<InfiniteCarouselItemDirective>): void {
        const fittingItemAmount = Math.floor(this.getCarouselItemContainerWidth() / this.getCarouselItemWidth());
        if (fittingItemAmount < items.length) {
            // items are larger than container
            this.translateXMultiplier = -1;
            if (fittingItemAmount + 1 === items.length) {
                this.itemsArray = [...items.toArray(), ...items.toArray()]; // double the array. Otherwise we would have a gap while cycling
            } else {
                this.itemsArray = items.toArray();
            }
            this.itemsArray.unshift(this.itemsArray.pop()!);
        } else {
            this.itemsArray = items.toArray();
            this.translateXMultiplier = 0;
        }
    }

    /**
     * updates the translateX Style of the carousel itemContainer
     */
    private updateTranslateXValue(): void {
        this.renderer.setStyle(
            this.itemContainer.nativeElement,
            'transform',
            `translateX(${Math.ceil(this.getCarouselItemWidth() * this.translateXMultiplier)}px)`
        );
    }

    /**
     * uses animationBuilder to create an animation
     * @param forward
     */
    private createSlideAnimation(forward: boolean): AnimationPlayer {
        return this.animationBuilder
            .build([
                style({
                    transform: `translateX(${this.getCarouselItemWidth() * this.translateXMultiplier}px)`
                }),
                animate(
                    this.animationTime,
                    style({
                        transform: `translateX(${
                            this.getCarouselItemWidth() * this.translateXMultiplier +
                            this.getCarouselItemWidth() * (forward ? 1 : -1)
                        }px)`
                    })
                )
            ])
            .create(this.itemContainer.nativeElement);
    }

    /**
     * calculates wether the controls shall be shown or not
     */
    private updateDisplayControls(): void {
        this.showControls = this.itemContainer.nativeElement.scrollWidth > this.viewPort.nativeElement.offsetWidth;
    }
}
