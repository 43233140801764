import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, Injector, Input, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@obo-dashboard/models/projectManagement.models';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { ProjectEditModalComponent } from '../modals/project-edit-modal.component';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent {
    @Input()
    public project: Project;

    private editProjectOverlay: OverlayRef | undefined;

    constructor(
        private projectService: ProjectService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private stateService: StateService,
        private translateService: TranslateService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig
    ) {}

    public editProject(): void {
        const projectClone = Object.assign({}, this.project);
        this.editProjectOverlay = this.overlay.create(this.overlayConfig);
        const projectEditModalPortal = new ComponentPortal(
            ProjectEditModalComponent,
            this.viewContainerRef,
            Injector.create({
                providers: [
                    {
                        provide: 'project',
                        useValue: projectClone
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('PROJECT_EDIT_PROJECT')
                    },
                    {
                        provide: 'saveFn',
                        useValue: (project) => {
                            this.saveProject(project);
                            this.dismissModal();
                        }
                    },
                    {
                        provide: 'dismissFn',
                        useValue: () => this.dismissModal()
                    }
                ]
            })
        );
        this.editProjectOverlay.attach(projectEditModalPortal);
    }

    public deleteProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .deleteProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe(
                (res: Project) => {
                    this.alertService.success(this.translateService.instant('PROJECTCTRL_DELETEPROJECTSUCCESS'));
                    this.stateService.go('dashboard');
                },
                (error: any) => {
                    this.alertService.danger(this.translateService.instant('PROJECTCTRL_DELETEPROJECTFAILURE'));
                }
            );
    }

    /**
     * duplicates a project
     * @param building
     */
    public duplicateProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .duplicateProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe(
                (project) => {
                    this.alertService.success(this.translateService.instant('MAIN_DUPLICATEPROJECT_SUCCESS'));
                    this.stateService.go('projectDetail', {
                        projectId: project.id
                    });
                },
                () => {
                    this.alertService.danger(this.translateService.instant('MAIN_DUPLICATEPROJECT_FAILURE'));
                }
            );
    }

    private saveProject(project: Project) {
        this.spinnerService.startSpinner();
        this.projectService
            .createOrUpdateProject(project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe(
                () => {
                    this.project = project;
                    this.alertService.success(this.translateService.instant('SHARED_EDIT_SUCCESS'));
                },
                () => {
                    this.alertService.danger(this.translateService.instant('SAVE_PROJECT_FAILURE'));
                }
            );
    }

    private dismissModal() {
        this.editProjectOverlay.detach();
        this.editProjectOverlay = undefined;
    }
}
