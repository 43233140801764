import { Injectable } from '@angular/core';
import { ModuleActionBarStep } from '@obo-main/components/moduleActionBar/moduleActionBarStep.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ModuleActionBarService {
    private _currentStep: BehaviorSubject<ModuleActionBarStep> = new BehaviorSubject<ModuleActionBarStep>(undefined);
    private _steps: BehaviorSubject<ModuleActionBarStep[]> = new BehaviorSubject<ModuleActionBarStep[]>(undefined);
    private _projectId: number;

    public set currentStep(step: ModuleActionBarStep) {
        this._currentStep.next(step);
    }

    public set steps(steps: ModuleActionBarStep[]) {
        this._steps.next(steps);
    }

    public get currentStep(): Observable<ModuleActionBarStep> {
        return this._currentStep.asObservable();
    }

    public get steps(): Observable<ModuleActionBarStep[]> {
        return this._steps.asObservable();
    }

    public set projectId(projectId: number) {
        this._projectId = projectId;
    }

    public get projectId(): number {
        return this._projectId;
    }
}
