import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountService } from '@obo-account/account/account.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { PortalRegion } from '@obo-portal/models/portal.models';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[shdCheckFeatures]'
})
export class CheckUserFeaturesDirective implements OnInit, OnDestroy {
    private readonly destruction = new Subject();

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private regionService: RegionService,
        private accountService: AccountService,
        private authService: AuthService
    ) {}

    @Input()
    public shdCheckFeatures: number[];

    public activeRegion: PortalRegion;

    public ngOnInit(): void {
        this.authService.onAuthenticationStatusChange
            .pipe(
                filter((authenticated) => authenticated),
                switchMap(() => this.accountService.getAccount()),
                map((user) => this.regionService.portalRegions.find((r) => r.id === user.regionId)!),
                takeUntil(this.destruction)
            )
            .subscribe((activeUserRegion) => {
                this.activeRegion = activeUserRegion;
                this.updateVisibility();
            });

        this.updateVisibility();
    }

    public ngOnDestroy(): void {
        this.destruction.next(1);
        this.destruction.complete();
    }

    private updateVisibility() {
        this.viewContainerRef.clear();
        if (this.includesFeature()) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

    private includesFeature() {
        if (this.shdCheckFeatures.length === 0) return true;
        return this.activeRegion?.features.some((rf) => this.shdCheckFeatures.includes(rf.id));
    }
}
