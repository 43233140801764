export class Motd {
    public id: number | undefined;
    public name: string = 'new Motd';
    public startDate: Date = new Date();
    public endDate: Date = new Date();
    public isActive: boolean;
    public contents: MotdContent[] = new Array();
}

export class MotdContent {
    public cultureName: string;
    public title: string;
    public message: string;
}
