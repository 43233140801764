export enum LoggerLevel {
    error = 1,
    warn = 2,
    info = 3,
    log = 4,
    debug = 5
}

export namespace LoggerLevel {
    export function getLevels(): { key: string; value: number }[] {
        let map: { key: string; value: number }[] = [];

        for (var l in LoggerLevel) {
            if (typeof LoggerLevel[l] === 'number') {
                map.push({
                    key: l,
                    value: LoggerLevel[l] as unknown as number
                });
            }
        }

        return map;
    }
}
