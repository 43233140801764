import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AmountInputComponent } from '@obo-common/shared/components/amountInput/amount-input.component';
import { CollapseComponent } from '@obo-common/shared/components/collapse/collapse.component';
import { ConfirmDialogComponent } from '@obo-common/shared/components/confirmDialog/confirmDialog.component';
import { ConfirmModalComponent } from '@obo-common/shared/components/confirmModal/confirmModal.component';
import { ContextmenuComponent } from '@obo-common/shared/components/contextmenu/contextmenu.component';
import { DownloadDropdownItemComponent } from '@obo-common/shared/components/downloadSection/downloadDropDownItem/downloadDropdownItem.component';
import { DownloadItemComponent } from '@obo-common/shared/components/downloadSection/downloadItem/downloadItem.component';
import { DownloadSectionComponent } from '@obo-common/shared/components/downloadSection/downloadSection.component';
import { FloatingActionButtonItemComponent } from '@obo-common/shared/components/floating-action-button/floating-action-button-item/floating-action-button-item.component';
import { FloatingActionButtonComponent } from '@obo-common/shared/components/floating-action-button/floating-action-button.component';
import { HeadlineComponent } from '@obo-common/shared/components/headline/headline.component';
import { InfiniteCarouselComponent } from '@obo-common/shared/components/infiniteCarousel/infiniteCarousel.component';
import { InfoboxComponent } from '@obo-common/shared/components/infobox/infobox.component';
import { InfoButtonComponent } from '@obo-common/shared/components/infoButton/infoButton.component';
import { InlineEditComponent } from '@obo-common/shared/components/inlineEdit/inlineEdit.component';
import { LoadingLayerComponent } from '@obo-common/shared/components/loadingLayer/loadingLayer.component';
import { RecaptchaComponent } from '@obo-common/shared/components/recaptcha/recaptcha.component';
import { StepComponent } from '@obo-common/shared/components/stepper/step/step.component';
import { StepperComponent } from '@obo-common/shared/components/stepper/stepper.component';
import { TableTileComponent } from '@obo-common/shared/components/tableTile/tableTile.component';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { DebugOverlayModalContentComponent } from '@obo-main/components/debugOverlay/debugOverlay.component';
import { UIRouterModule } from '@uirouter/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { BrowserRedirectModalComponent } from './components/browserRedirectModal/browserRedirectModal.component';
import { DashedHeadlineComponent } from './components/dashed-headline/dashed-headline.component';
import { DesktopOnlyModalComponent } from './components/desktopOnlyModal/desktopOnlyModal.component';
import { SharedServicesModule } from './sharedServices.module';
import { RadioCardComponent } from '@obo-common/shared/components/radioCard/radioCard.component';
import { ExpandableCardComponent } from '@obo-common/shared/components/expandableCard/expandableCard.component';
import { ModuleIconComponent } from './components/icon/moduleIcon.component';
import { GridLayoutComponent } from './components/layout/grid/grid-layout.component';
import { ErrorFieldComponent } from '@obo-common/shared/components/errorField/errorField.component';
import { SingleSelectComponent } from '@obo-common/shared/components/singleSelect/singleSelect.component';
import { MultiSelectComponent } from '@obo-common/shared/components/multiSelect/multiSelect.component';
import { RadioGroupComponent } from '@obo-common/shared/components/radioGroup/radioGroup.component';
import { SidebarComponent } from '@obo-common/shared/components/sidebar/sidebar.component';

/**
 * Module containing some useful components used in various other modules
 */
@NgModule({
    declarations: [
        DashedHeadlineComponent,
        InfoboxComponent,
        DebugOverlayModalContentComponent,
        LoadingLayerComponent,
        ConfirmDialogComponent,
        ConfirmModalComponent,
        InfiniteCarouselComponent,
        InfoButtonComponent,
        HeadlineComponent,
        CollapseComponent,
        InlineEditComponent,
        ContextmenuComponent,
        TableTileComponent,
        FloatingActionButtonComponent,
        FloatingActionButtonItemComponent,
        AmountInputComponent,
        BrowserRedirectModalComponent,
        RecaptchaComponent,
        DownloadSectionComponent,
        DownloadItemComponent,
        DownloadDropdownItemComponent,
        StepperComponent,
        StepComponent,
        RadioCardComponent,
        DesktopOnlyModalComponent,
        ExpandableCardComponent,
        ModuleIconComponent,
        GridLayoutComponent,
        SingleSelectComponent,
        MultiSelectComponent,
        ErrorFieldComponent,
        RadioGroupComponent,
        SidebarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        UIRouterModule,
        NgbModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        SharedDirectivesModule,
        SharedPipesModule,
        SharedServicesModule,
        FontAwesomeModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    exports: [
        InfoboxComponent,
        HeadlineComponent,
        LoadingLayerComponent,
        ConfirmDialogComponent,
        ConfirmModalComponent,
        InfiniteCarouselComponent,
        InfoButtonComponent,
        CollapseComponent,
        InlineEditComponent,
        ContextmenuComponent,
        TableTileComponent,
        DashedHeadlineComponent,
        FloatingActionButtonComponent,
        FloatingActionButtonItemComponent,
        AmountInputComponent,
        BrowserRedirectModalComponent,
        RecaptchaComponent,
        DownloadSectionComponent,
        DownloadItemComponent,
        DownloadDropdownItemComponent,
        StepperComponent,
        StepComponent,
        RadioCardComponent,
        DesktopOnlyModalComponent,
        ExpandableCardComponent,
        ModuleIconComponent,
        GridLayoutComponent,
        ErrorFieldComponent,
        SingleSelectComponent,
        MultiSelectComponent,
        RadioGroupComponent,
        SidebarComponent
    ]
})
export class SharedComponentsModule {}
