import { NgModule } from '@angular/core';
import { ApiImageSrcDirective } from '@obo-common/shared/directives/apiImageSrc.directive';
import { CatalogUrlDirective } from '@obo-common/shared/directives/catalogUrl.directive';
import { CheckUserPermissionsDirective } from '@obo-common/shared/directives/checkUserPermissions.directive';
import { ElbridgeRedirectDirective } from '@obo-common/shared/directives/elbridgeRedirect.directive';
import { EqualValidator } from '@obo-common/shared/directives/equalValidator.directive';
import { GoBackButtonDirective } from '@obo-common/shared/directives/goBackButton.directive';
import { ImageIdDirective } from '@obo-common/shared/directives/imageId.directive';
import { FocusInputOnClickDirective } from '@obo-common/shared/directives/inputGroupFocus.directive';
import { LongPressActionDirective } from '@obo-common/shared/directives/longPressAction.directive';
import { MouseWheelMovedDirective } from '@obo-common/shared/directives/mousewheel.directive';
import { ProductNameDirective } from '@obo-common/shared/directives/productName.directive';
import { ValidateMultipleControlsMaxValueDirective } from '@obo-common/shared/directives/validateMultipleControlsValue.directive';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { ServicesModule } from '@obo-main/services/services.module';
import { InfiniteCarouselItemDirective } from './components/infiniteCarousel/infiniteCarouselItem.directive';
import { InlineEditButtonDirective } from './components/inlineEdit/helperDirectives/inlineEditButton.directive';
import { InlineEditInputDirective } from './components/inlineEdit/helperDirectives/inlineEditInput.directive';
import { TableTileContentDirective } from './components/tableTile/helperDirectives/tableTileContent.directive';
import { TableTileEntryDirective } from './components/tableTile/helperDirectives/tableTileEntry.directive';
import { TableTileHeaderDirective } from './components/tableTile/helperDirectives/tableTileHeader.directive';
import { AutoFocusDirective } from './directives/autoFocus.directive';
import { CheckUserFeaturesDirective } from './directives/checkUserFeatures.directive';
import { TogglePasswordDirective } from './directives/togglePassword.directive';
import { ExpandableCardContentDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardContent.directive';
import { ExpandableCardHeaderDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardHeader.directive';
import { ExpandableCardToggleButtonDirective } from '@obo-common/shared/components/expandableCard/helperDirectives/expandableCardToggleButton.directive';
import { ClickOutsideDirective } from '@obo-common/shared/directives/clickOutside.directive';
import { SelectOnFocusDirective } from './directives/selectOnFocus.directive';
import { UnitInputDirective } from '@obo-common/shared/directives/unitInput.directive';
import { ValidateNamingDirective } from '@obo-common/shared/directives/validateNaming.directive';
import { ValidateInputDirective } from '@obo-common/shared/directives/validateInput.directive';
import { SingleSelectOptionDirective } from '@obo-common/shared/components/singleSelect/singleSelectOption.directive';
import { SingleSelectLabelDirective } from '@obo-common/shared/components/singleSelect/singleSelectLabel.directive';
import { MultiSelectOptionDirective } from '@obo-common/shared/components/multiSelect/multiSelectOption.directive';
import { MultiSelectLabelDirective } from '@obo-common/shared/components/multiSelect/multiSelectLabel.directive';
import { RadioGroupOptionDirective } from '@obo-common/shared/components/radioGroup/radioGroupOption.directive';
import { FloatingActionButtonItemContentDirective } from '@obo-common/shared/components/floating-action-button/floating-action-button-item/floatingActionButtonItemContent.directive';
import { ConfirmModalQuestionDirective } from '@obo-common/shared/components/confirmModal/confirmModalQuestion.directive';
import { ClickInsideDirective } from './directives/clickInside.directive';
import { SidebarContentDirective } from '@obo-common/shared/components/sidebar/sidebarContent.directive';
import { SidebarTitleDirective } from '@obo-common/shared/components/sidebar/sidebarTitle.directive';

/**
 * Module containing some useful app-wide directives
 */
@NgModule({
    imports: [ServicesModule, SharedPipesModule],
    declarations: [
        CheckUserPermissionsDirective,
        EqualValidator,
        GoBackButtonDirective,
        LongPressActionDirective,
        FocusInputOnClickDirective,
        MouseWheelMovedDirective,
        ProductNameDirective,
        ElbridgeRedirectDirective,
        CatalogUrlDirective,
        ApiImageSrcDirective,
        ValidateMultipleControlsMaxValueDirective,
        InlineEditButtonDirective,
        InlineEditInputDirective,
        AutoFocusDirective,
        ImageIdDirective,
        CheckUserFeaturesDirective,
        TogglePasswordDirective,
        TableTileContentDirective,
        TableTileHeaderDirective,
        TableTileEntryDirective,
        InfiniteCarouselItemDirective,
        ExpandableCardContentDirective,
        ExpandableCardHeaderDirective,
        ExpandableCardToggleButtonDirective,
        ClickOutsideDirective,
        ClickInsideDirective,
        SelectOnFocusDirective,
        UnitInputDirective,
        ValidateInputDirective,
        ValidateNamingDirective,
        SingleSelectOptionDirective,
        SingleSelectLabelDirective,
        MultiSelectOptionDirective,
        MultiSelectLabelDirective,
        ValidateNamingDirective,
        RadioGroupOptionDirective,
        FloatingActionButtonItemContentDirective,
        ConfirmModalQuestionDirective,
        SidebarTitleDirective,
        SidebarContentDirective
    ],
    providers: [],
    exports: [
        CheckUserPermissionsDirective,
        EqualValidator,
        GoBackButtonDirective,
        LongPressActionDirective,
        FocusInputOnClickDirective,
        MouseWheelMovedDirective,
        ProductNameDirective,
        ElbridgeRedirectDirective,
        CatalogUrlDirective,
        ApiImageSrcDirective,
        ValidateMultipleControlsMaxValueDirective,
        InlineEditButtonDirective,
        InlineEditInputDirective,
        AutoFocusDirective,
        ImageIdDirective,
        CheckUserFeaturesDirective,
        TogglePasswordDirective,
        TableTileContentDirective,
        TableTileHeaderDirective,
        TableTileEntryDirective,
        InfiniteCarouselItemDirective,
        ExpandableCardHeaderDirective,
        ExpandableCardContentDirective,
        ExpandableCardToggleButtonDirective,
        ClickOutsideDirective,
        ClickInsideDirective,
        SelectOnFocusDirective,
        UnitInputDirective,
        ValidateInputDirective,
        ValidateNamingDirective,
        SingleSelectOptionDirective,
        SingleSelectLabelDirective,
        MultiSelectOptionDirective,
        MultiSelectLabelDirective,
        ValidateNamingDirective,
        RadioGroupOptionDirective,
        FloatingActionButtonItemContentDirective,
        ConfirmModalQuestionDirective,
        SidebarTitleDirective,
        SidebarContentDirective
    ]
})
export class SharedDirectivesModule {}
