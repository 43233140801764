export class PortalRegion {
    id: number;
    name: string;
    displayName: string;
    features: PortalFeature[];
}

export class PortalFeature {
    public id: PortalFeatureEnum;
    public url: string | null;
    public teaserUrl: string | null;
    public webinarUrl: string | null;
}

export enum PortalFeatureEnum {
    AutoCad = 1, // ........Download
    BSS = 2, // ............Construct Modul
    BSSOld = 3, // .........Altsystem - Weiterleitung
    BSSApp = 4, // .........Altsystem - Mobile App
    Cablefilling = 5, // ...Construct Modul
    CablefillingOld = 6, // Download
    Egress = 7, // .........Construct Modul
    Photovoltaic = 8, // ...Construct Modul
    GRD = 9, // ............Construct Modul
    TBS = 10, // ............Construct Modul
    TBSOld = 11, // ........Altsystem - Weiterleitung
    UFS = 12, // ...........Construct Modul
    UFSOld = 13, // ........Altsystem - Weiterleitung
    Video = 14 // .........Construct Tutorial
}

export function hasDownloadCondition(featureId: number): boolean {
    return featureId === PortalFeatureEnum.AutoCad || featureId === PortalFeatureEnum.CablefillingOld;
}

export function hasConstructModuleCondition(featureId: number): boolean {
    return (
        featureId === PortalFeatureEnum.BSS ||
        featureId === PortalFeatureEnum.Cablefilling ||
        featureId === PortalFeatureEnum.UFS ||
        featureId === PortalFeatureEnum.GRD ||
        featureId === PortalFeatureEnum.TBS ||
        featureId === PortalFeatureEnum.Photovoltaic ||
        featureId === PortalFeatureEnum.Egress
    );
}
