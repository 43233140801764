export class PlanningNotAllowedError extends Error {
    constructor(msg: string | undefined) {
        super(msg);
        Object.setPrototypeOf(this, PlanningNotAllowedError.prototype);
    }
}
export class ElementNotFoundError extends Error {
    constructor(msg: string | undefined) {
        super(msg);
        Object.setPrototypeOf(this, ElementNotFoundError.prototype);
    }
}
