<form name="requestPasswordForm" id="login-form" (ngSubmit)="request()" role="form" #requestPasswordForm="ngForm">
    <div>
        <div class="mb-3 required">
            <label class="form-label" for="email">
                {{ 'LOGINFORM_EMAIL' | translate }}
            </label>
            <input
                [(ngModel)]="email"
                type="email"
                name="email"
                id="email"
                tabindex="0"
                class="form-control form-control-border"
                required
                shdValidateInput />
        </div>
    </div>
    <div class="row">
        <div class="col-12 required">
            <label class="form-label">
                {{ 'REQUESTTYPE' | translate }}
            </label>

            <div class="mb-3 d-flex gap-2">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        [(ngModel)]="requestType"
                        name="type"
                        type="radio"
                        id="request-type-password"
                        value="password" />
                    <label for="request-type-password" class="form-check-label">
                        {{ 'REQUESTTYPE_PASSWORD' | translate }}
                    </label>
                </div>
                <div class="form-check">
                    <input
                        class="form-check-input"
                        [(ngModel)]="requestType"
                        name="type"
                        type="radio"
                        id="request-type-activationemail"
                        value="activationemail" />
                    <label for="request-type-activationemail" class="form-check-label">
                        {{ 'REQUESTTYPE_ACTIVATIONEMAIL' | translate }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <shd-recaptcha [(valid)]="reCaptchaValid"></shd-recaptcha>
    <small class="form-text text-muted">
        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
    </small>
    <div class="mb-3">
        <button
            class="btn btn-success"
            [disabled]="requestPasswordForm.invalid || !reCaptchaValid"
            type="submit"
            name="submit"
            id="submit"
            tabindex="0">
            {{ 'LOGINFORM_BTN_REQUEST' | translate }}
        </button>
    </div>
</form>
