import { animate, stagger, state, style, transition, trigger } from '@angular/animations';

const flyIn = transition('void => *', [style({ transform: 'translateX(100%)' }), animate(200)]);

const flyOut = transition('* => void', [animate(200, style({ transform: 'translateX(100%)' }))]);

export const flyOutFlyInAnimation = [trigger('flyOutFlyIn', [flyOut, flyIn])];

export const flyInAnimation = [trigger('flyIn', [state('in', style({})), flyIn])];

export const flyOutAnimation = [trigger('flyOut', [state('in', style({})), flyOut])];
