import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CableCategory } from '@obo-common/shared/models/cable.models';
import { Observable } from 'rxjs';

@Injectable()
export class CableManagementService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient) {}

    /**
     * uploads an portalmoduleImage to api
     * @param file
     * @param dataItem
     */
    public uploadCableCategoryImage(file: File, dataItem: CableCategory): Observable<any> {
        let formData: FormData = new FormData();
        Object.entries(dataItem).forEach(([key, value]) => formData.append(key, value));
        formData.append('upload', file);
        return this.http.put(`${this.apiPrefix}Administration/CableCategories/${dataItem.id}/Images`, formData);
    }
}
