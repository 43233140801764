import { Inject, Injectable } from '@angular/core';
import { PasswordPattern, User } from '@obo-account/models/account.models';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AccountService {
    private passwordPattern: string;
    private currentlyLoggedInUser: User | undefined;
    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private http: HttpClient,
        private logger: Logger,
        private authService: AuthService
    ) {
        this.authService.onAuthenticationStatusChange.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.getAccount().subscribe();
            } else {
                this.logger.debug('AccountService: Removing currentUser');
                this.currentlyLoggedInUser = undefined;
            }
        });
    }

    public getEmptyUser() {
        return new User();
    }

    /**
     * retrieves account information of the user currently logged in
     */
    public getAccount(forceRefresh = false): Observable<User> {
        if (!forceRefresh && this.currentlyLoggedInUser != null) {
            return of(this.currentlyLoggedInUser!);
        } else {
            return this.getUserAccount();
        }
    }

    private getUserAccount(): Observable<User> {
        return this.http.get<User>(`${this.apiPrefix}Accounts/Me`).pipe(
            map((usr) => Object.assign(new User(), usr)),
            tap((usr) => (this.currentlyLoggedInUser = usr))
        );
    }

    /**
     * Updates an existing account
     * @param user
     */
    public updateAccount(user: User): Observable<User> {
        return this.http.put<User>(`${this.apiPrefix}Accounts/Me`, user).pipe(tap((usr) => (this.currentlyLoggedInUser = usr)));
    }

    /**
     *
     * @param user
     */
    public deleteAccount(): Observable<User> {
        return this.http.delete<User>(`${this.apiPrefix}Accounts/Me`);
    }

    /**
     * retrieves the passwordpattern from api
     */
    public getPasswordPattern(): Observable<string> {
        if (this.passwordPattern) {
            return of(this.passwordPattern);
        } else {
            return (this.http.get(`${this.apiPrefix}Accounts/PasswordPattern`) as Observable<PasswordPattern>).pipe(
                map((res) => {
                    this.passwordPattern = res.pattern;
                    return this.passwordPattern;
                })
            );
        }
    }

    /**
     * initialites the password change request
     * @param user
     */
    public changePassword(user: User): Observable<any> {
        return this.http.put(`${this.apiPrefix}Accounts/ChangePassword`, user);
    }
}
