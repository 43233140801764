import { Component, Input, OnInit } from '@angular/core';
import { DataGridColumnCustomFilter, DataGridFilter, DataGridFilterOperator } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridFilterService } from '@obo-admin/dataGrid/services/dataGridFilter.service';

@Component({
    selector: 'adm-data-grid-filter-dropdown',
    templateUrl: './dataGridFilterDropdown.component.html',
    styleUrls: ['./dataGridFilterDropdown.component.scss']
})
export class DataGridFilterDropdownComponent implements OnInit {
    @Input()
    public field: string;
    @Input()
    public filter: DataGridFilterService;
    @Input()
    public data: DataGridColumnCustomFilter[];
    @Input()
    type: 'number' | 'text' | 'date';

    filterOperator: string;
    customValue: DataGridColumnCustomFilter;
    filterValue: string;

    filterOperators: Array<string>;

    ngOnInit(): void {
        this.filterOperator = this.type === 'text' ? DataGridFilterOperator.Contains : DataGridFilterOperator.IsEqualTo;
        this.filterOperators = this.getOperators();
    }

    clear(): void {
        this.filterOperator = this.type === 'text' ? DataGridFilterOperator.Contains : DataGridFilterOperator.IsEqualTo;
        this.filterValue = undefined;
        this.customValue = undefined;
        this.filter.removeFilter(this.field);
    }

    applyFilter(): void {
        this.filter.addFilter(this.columnFilter);
    }

    get columnFilter(): DataGridFilter {
        let filter = new DataGridFilter();
        filter.filters = [
            {
                value: this.customValue ? this.customValue.value : this.filterValue,
                id: this.field,
                operator: this.customValue ? DataGridFilterOperator.IsEqualTo : this.filterOperator
            }
        ];
        filter.logic = 'or';
        return filter;
    }

    private getOperators(): Array<string> {
        switch (this.type) {
            case 'text':
                return [
                    DataGridFilterOperator.Contains,
                    DataGridFilterOperator.EndsWith,
                    DataGridFilterOperator.StartsWith,
                    DataGridFilterOperator.NotEqualTo,
                    DataGridFilterOperator.IsEqualTo,
                    DataGridFilterOperator.DoesNotContain
                ];
            case 'date':
            case 'number':
                return [
                    DataGridFilterOperator.NotNull,
                    DataGridFilterOperator.IsNull,
                    DataGridFilterOperator.IsEqualTo,
                    DataGridFilterOperator.NotEqualTo,
                    DataGridFilterOperator.GreaterThanOrEqualTo,
                    DataGridFilterOperator.LessThanOrEqualTo
                ];
        }
    }
}
