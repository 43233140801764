import { Component, ElementRef, Inject, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DebugService } from '@obo-main/services/debugging/debug.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { StateService } from '@uirouter/core';
import { TranslateHttpLoader } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.service';
import { FileSavingService } from '@obo-common/shared/services/fileSaving.service';

@Component({
    selector: 'app-debug-overlay',
    templateUrl: './debugOverlay.component.html',
    styleUrls: ['./debugOverlay.component.scss']
})
export class DebugOverlayComponent implements OnInit {
    private readonly LOGSIZE = 5;
    @ViewChild('overlay')
    public overlay: TemplateRef<any>;

    constructor(
        @Inject('DEBUG') private debugMode: boolean,
        private viewContainerRef: ViewContainerRef,
        private debugService: DebugService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private modalService: NgbModal,
        private authService: AuthService,
        private stateService: StateService,
        private translateloader: TranslateHttpLoader,
        private fileSaver: FileSavingService
    ) {}

    public ngOnInit(): void {
        if (this.debugMode) {
            this.viewContainerRef.createEmbeddedView(this.overlay);
            this.debugService.onOverlayEvent.subscribe((evt) => {
                const modalInstance = this.modalService.open(DebugOverlayModalContentComponent, {
                    size: 'lg'
                } as NgbModalOptions);
                modalInstance.componentInstance.title = evt.title;
                modalInstance.componentInstance.error = evt.error;
            });
        } else {
            this.closeComponent();
        }
    }

    /**
     * disables the Component for the Session
     */
    public closeComponent(): void {
        this.viewContainerRef.clear();
    }

    /**
     * stops the spinner
     */
    public hideSpinner(): void {
        this.spinnerService.stopSpinner();
    }
    public showSpinner(): void {
        this.spinnerService.startSpinner();
    }

    public clearAlerts(): void {
        this.alertService.resetAlerts();
    }

    public showTestAlert(): void {
        this.alertService.success('test', 60000, 'test');
    }

    /**
     * forces a refresh of access token
     */
    public refreshAccessToken(): void {
        this.authService.refreshAccessToken().subscribe(() => {});
    }

    public getUserRoles(): any {
        return this.authService.userRoles;
    }

    public clearLocalStorage(): void {
        localStorage.clear();
    }

    public getCurrentState(): any {
        return this.stateService.current.name!;
    }

    public getCurrentStateParams(): any {
        return this.stateService.params;
    }

    public exportTranslationsJson(): void {
        this.translateloader.retrieveTranslationsForAllModules().subscribe(() => {
            const translateStore = this.translateloader.getTranslateStore();
            translateStore.forEach((s) => {
                const blob = new Blob([JSON.stringify(s.translations, null, 4)], {
                    type: 'text/plain'
                });
                const moduleName = s.translations[`${s.partId}_MODULEDISPLAYNAME`];
                this.fileSaver.saveFile(blob, `${s.langCode}-${moduleName || s.partId}.json`);
            });
        });
    }
}

@Component({
    selector: 'app-debug-modal-content',
    template: `
        <div class="modal border border-danger">
            <div class="modal-header">
                <h4 class="modal-title">{{ title }}</h4>
            </div>
            <div class="modal-body" style="overflow: scroll">
                <p>{{ error | json }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="activeModal.close()">Close</button>
            </div>
        </div>
    `
})
export class DebugOverlayModalContentComponent {
    @Input()
    public title: string;
    @Input()
    public error: any;

    constructor(public activeModal: NgbActiveModal) {}
}
