import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Observable } from 'rxjs';

@Injectable()
export class RequestPasswordService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private logger: Logger) {}

    public requestActivationMail(email: string): Observable<any> {
        this.logger.debug(`Requesting new Activationmail for email: ${email}`);
        return this.http.post(`${this.apiPrefix}Accounts/ForgotConfirm`, {
            email: email
        });
    }

    public requestNewPassword(email: string): Observable<any> {
        this.logger.debug(`Requesting new Password for email: ${email}`);
        return this.http.post(`${this.apiPrefix}Accounts/ForgotPassword`, {
            email: email
        });
    }
}
