@if (elbridgeInfo | async) {
<ngb-alert style="min-height: unset" class="d-flex justify-content-center elbridge-banner" type="secondary" [dismissible]="false">
    <div>
        <span class="d-none d-lg-inline-block">
            {{ 'MAIN_ELBRIDGE_INFO' | translate }}
        </span>
        <button class="btn btn-more-details-small" [shdElbridgeAbort]="elbridgeAbortFn">
            <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
            {{ 'BTN_ELBRIDGE_ABORT' | translate }}
        </button>
    </div>
</ngb-alert>
}
