import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InlineEditButtonDirective } from './helperDirectives/inlineEditButton.directive';
import { InlineEditContext } from './models/inlineEditContext.model';

@Component({
    selector: 'shd-inline-edit',
    templateUrl: './inlineEdit.component.html',
    styleUrls: ['./inlineEdit.component.scss'],
    exportAs: 'inlineEditor'
})
export class InlineEditComponent implements AfterContentInit {
    @Output()
    public editSaved: EventEmitter<string> = new EventEmitter();
    @Output()
    public editDiscarded: EventEmitter<void> = new EventEmitter();
    @Output()
    public editModeToggled: EventEmitter<string> = new EventEmitter();

    @HostBinding('class.inline-editor')
    public inlineEditorClass = true;

    public formGroup = new UntypedFormGroup({
        formControl: new UntypedFormControl('', [Validators.required])
    });
    private _content: string;

    @Input()
    public set content(val: string) {
        this.formGroup.controls.formControl.setValue(val);
        this._content = val;
        this.context.value = val;
    }
    public template: TemplateRef<InlineEditContext>;
    @ViewChild('standardRead', { read: TemplateRef, static: true })
    public standardRead: TemplateRef<InlineEditContext>;
    @ViewChild('standardWrite', { read: TemplateRef, static: true })
    public standardWrite: TemplateRef<InlineEditContext>;

    @ContentChildren(InlineEditButtonDirective)
    public buttons: QueryList<InlineEditButtonDirective>;

    @ViewChildren(InlineEditButtonDirective)
    public standardButtons: QueryList<InlineEditButtonDirective>;

    @ContentChild('read')
    public read: TemplateRef<InlineEditContext>;
    @ContentChild('write')
    public write: TemplateRef<InlineEditContext>;

    public get value() {
        return this.formGroup.controls.formControl.value;
    }

    public context: InlineEditContext = {
        openEditModeFn: () => this.changeMode(),
        value: this.value,
        formGroup: this.formGroup,
        resetFn: () => this.reset(),
        updateFn: () => this.updateValue()
    };

    public ngAfterContentInit(): void {
        this.template = this.read || this.standardRead;
    }

    private isWriteMode(): boolean {
        return this.template === this.write || this.template === this.standardWrite;
    }

    public updateValue(): void {
        this.editSaved.emit(this.value);
        this.reset();
    }

    public reset(): void {
        this.formGroup.reset({ formControl: this._content });
        this.changeMode();
    }

    public changeMode(): void {
        if (this.isWriteMode()) {
            this.template = this.read || this.standardRead;
        } else {
            this.template = this.write || this.standardWrite;
        }
    }
}
