<div #loginDropdown="ngbDropdown" ngbDropdown autoClose="outside">
    <button class="btn-login justify-content-center px-4" id="login-panel-dropdown" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'sign-in']" size="lg"></fa-icon>
        {{ 'NAVIGATION_LOGIN' | translate }}
    </button>
    <div ngbDropdownMenu class="login-panel-dropdown dropdown-menu-right" aria-labelledby="login-panel-dropdown">
        <form class="px-3 py-1" (ngSubmit)="login()" #loginDropdownForm="ngForm">
            <div class="mb-3">
                <label class="form-label" for="username">
                    {{ 'LOGINFORM_EMAIL' | translate }}
                </label>
                <input
                    [(ngModel)]="user.username"
                    email
                    type="email"
                    name="username"
                    id="username"
                    tabindex="0"
                    class="form-control form-control-border"
                    required
                    shdValidateInput
                    [customErrorMsg]="'REGISTERFORM_EMAIL_ERROR' | translate" />
            </div>
            <div class="mb-3">
                <label class="form-label" for="password">
                    {{ 'LOGINFORM_PASSWORD' | translate }}
                </label>
                <div class="position-relative">
                    <input
                        [(ngModel)]="user.password"
                        type="password"
                        shdToggle
                        name="password"
                        id="password"
                        tabindex="0"
                        class="form-control form-control-border"
                        required
                        shdValidateInput />
                </div>
            </div>
            <div class="mb-3">
                <a
                    uiSref="requestPassword"
                    tabindex="0"
                    class="btn btn-more-details-small"
                    (click)="loginDropdown.close()"
                    [attr.data-track]="'landing_requestPassword'">
                    <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
                    {{ 'LOGINFORM_FORGOTPASSWORD' | translate }}
                </a>
            </div>
            <div class="mb-3">
                <a uiSref="register" tabindex="0" class="btn btn-more-details-small" (click)="loginDropdown.close()">
                    <fa-icon [icon]="['fal', 'angle-right']" size="2x"></fa-icon>
                    {{ 'REGISTERFORM_TITLE' | translate }}
                </a>
            </div>
            <div class="mb-3">
                <button
                    class="btn btn-success w-100"
                    [disabled]="loginDropdownForm.dirty && loginDropdownForm.invalid"
                    type="submit">
                    {{ 'LOGINFORM_BTN_LOGIN' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
