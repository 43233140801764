<div class="modal border">
    <div class="modal-header">
        <h4 class="modal-title">
            {{ 'ADMIN_TRANSLATIONS_EXPORT_TITLE' | translate }}
        </h4>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup">
            <div class="empty-state-icon">
                <fa-icon [icon]="['fal', 'file-archive']" size="10x"></fa-icon>
            </div>
            <p [innerHTML]="'ADMIN_TRANSLATIONS_EXPORT_DESC' | translate"></p>
            <div class="row">
                <shd-single-filter-select
                    class="col-12 col-md-4 mt-1"
                    [filter]="formGroup.controls.sourceLanguage"
                    [showSelectedValue]="true"
                    [applyBtnText]="'BTN_APPLY' | translate"
                    [resetBtnText]="'BTN_RESET' | translate"></shd-single-filter-select>
                <shd-multi-filter-select
                    class="col-12 col-md-4 mt-1"
                    [filter]="formGroup.controls.languages"
                    [applyBtnText]="'BTN_APPLY' | translate"
                    [selectAllBtnText]="'CHECK_ALL' | translate"
                    [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
                <shd-multi-filter-select
                    class="col-12 col-md-4 mt-1"
                    [filter]="formGroup.controls.modules"
                    [applyBtnText]="'BTN_APPLY' | translate"
                    [selectAllBtnText]="'CHECK_ALL' | translate"
                    [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="my-3">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                formControlName="isAcrossExport"
                                id="isAcrossExport"
                                class="form-check-input" />
                            <label class="form-check-label text-muted" for="isAcrossExport">
                                {{ 'ADMIN_TRANSLATION_ACROSS_EXPORT' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer d-flex align-items-baseline justify-content-between btn-container">
        <button type="button" class="btn btn-step-back" (click)="closeModal()">
            <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
            {{ 'BTN_ABORT' | translate }}
        </button>
        <button type="button" class="btn btn-success" [disabled]="formGroup.invalid" (click)="exportTranslations()">
            {{ 'ADMIN_EXPORT_XLIFF_BTN' | translate }}
        </button>
    </div>
</div>
