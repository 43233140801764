<div [ngClass]="groupClass ? groupClass : ''">
    @for (optionTpl of optionTemplates; track optionTpl; let i = $index) { @if (optionTpl.active) { @if (!optionTpl.showRadio) {
    <a [ngClass]="optionClass ? optionClass + ' me-0' : ''" (click)="selectOption(optionTpl)">
        <ng-container [ngTemplateOutlet]="optionTpl.templateRef"></ng-container>
    </a>
    } @if (optionTpl.showRadio) {
    <div
        [ngClass]="optionClass ? optionClass + ' me-0' : ''"
        [class.form-check-inline]="direction === 'horizontal'"
        class="form-check"
        (click)="selectOption(optionTpl)">
        <input
            class="form-check-input"
            [name]="id"
            [id]="id + '_option_' + i"
            type="radio"
            [checked]="optionTpl.value === selectedValue" />
        <label class="form-check-label" [for]="id + '_option_' + i">
            <ng-container [ngTemplateOutlet]="optionTpl.templateRef"></ng-container>
        </label>
    </div>
    } } }
</div>
