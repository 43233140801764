import { Transition, TransitionService } from '@uirouter/core';
import { Utils } from '@obo-main/utils/utils.service';
import { StateHistoryService } from '@obo-main/services/stateHistory/stateHistory.service';

/**
 * saves the leaving state into utils service. used for the goback button
 * @param transitionService
 */
export function savePreviousStateHook(transitionService: TransitionService) {
    const savePreviousStateCriteria = {
        to: (state: any) => true
    };

    const action = (transition: Transition) => {
        const stateHistoryService = transition.injector().get(StateHistoryService) as StateHistoryService;

        const previousState = transition.from().name;
        const previousStateParams = transition.params('from');
        const directionBackwards = transition.options().custom.goBack;

        if (!directionBackwards) {
            stateHistoryService.addPreviousStateToHistory(previousState, previousStateParams);
        }
    };
    // add the hook to transitionsservice
    transitionService.onSuccess(savePreviousStateCriteria, action, {
        priority: 9
    });
}
