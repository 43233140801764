@if (!isDismissed) {
    <div #alertContainer [class]="'infobox alert alert-' + type">
        @if (isExpandable) {
            <button (click)="toggle()" type="button" class="mr-2 toggle-btn d-flex align-items-center"
                    [class.expanded]="isExpanded">
                <fa-icon [icon]="['fal', 'angle-down']" size="xl"></fa-icon>
            </button>
        }
        <div>
            <div class="alert-header">
                <div class="d-flex align-items-center">
                    <div class="icon-container">
                        <fa-icon class="fa-icon" size="1x" [icon]="['fal', getAlertIcon(type)]"></fa-icon>
                    </div>
                    <ng-container [ngTemplateOutlet]="headerTpl ?? defaultHeaderTpl"></ng-container>
                </div>
                @if (isDismissable && !isExpandable) {
                    <button class="close-btn" (click)="dismiss()">
                        <fa-icon size="lg" [icon]="['fal','xmark']"></fa-icon>
                    </button>
                }
            </div>
            @if (isExpanded && bodyText) {
                <div class="alert-body">
                    <ng-container [ngTemplateOutlet]="bodyTpl ?? defaultBodyTpl"></ng-container>
                </div>
            }
        </div>
    </div>
}

<ng-template #defaultHeaderTpl>
    <h3 class="mb-0 h-100" [class.fw-bold]="bodyText" [innerHTML]="headerText | safeHTML"></h3>
</ng-template>

<ng-template #defaultBodyTpl><span [innerHTML]="bodyText | safeHTML"></span></ng-template>
