import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class PrintService {
    private readonly printEvent: Array<Subject<any> | undefined>;

    constructor() {
        this.printEvent = new Array<any>();
    }

    public get getPrintEvent() {
        return this.printEvent;
    }

    public print(timeOut: number) {
        this.printEvent.push(new BehaviorSubject<any>({ isIntersecting: true }));
        setTimeout(() => {
            window.print();
        }, timeOut);
    }
}
