<div class="sidebar-tab" [class.isActive]="isActive">
    <button class="sidebar-tab-button" (click)="tabClicked.emit(tabIndex)">
        <div class="sidebar-tab-icon">
            <fa-icon [icon]="['fal', iconClass]" size="lg"></fa-icon>
        </div>
        @if (activeFilterCount) {
        <div class="sidebar-tab-badge">
            <div class="badge badge-secondary">{{ activeFilterCount }}</div>
        </div>
        }
    </button>
</div>
