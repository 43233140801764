import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AlertType } from '@obo-main/services/common/alert/alert.models';

@Component({
    selector: 'shd-infobox',
    templateUrl: './infobox.component.html',
    styleUrls: ['./infobox.component.scss']
})
export class InfoboxComponent implements OnInit {
    @Output()
    public dismissed = new EventEmitter<void>();
    @Input()
    public type: string = 'info';
    @Input()
    public headerText: string;
    @Input()
    public bodyText: string;
    @Input()
    public isExpandable: boolean = false;
    @Input()
    public isExpanded: boolean = true;
    @Input()
    public isDismissable: boolean = true;

    @ContentChild('header')
    public headerTpl: TemplateRef<any>;
    @ContentChild('body')
    public bodyTpl: TemplateRef<any>;

    @ViewChild('alertContainer')
    public alertContainer: ElementRef;

    public isDismissed: boolean = false;
    private initialIsExpandable: boolean;
    private initialWidth: number = null;

    constructor() {}

    public ngOnInit(): void {
        this.initialIsExpandable = this.isExpandable;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        if (this.alertContainer) {
            this.checkHeightToWidthRatio();
        }
    }

    public dismiss(): void {
        this.isDismissed = true;
        setTimeout(() => {
            this.dismissed.emit();
        }, 1000);
    }

    public getAlertIcon(alertType: string): IconProp {
        switch (alertType) {
            case AlertType.Success:
                return 'circle-check';
            case AlertType.Error:
                return 'circle-xmark';
            case AlertType.Warning:
                return 'triangle-exclamation';
            default:
                return 'circle-info';
        }
    }

    public toggle(): void {
        this.isExpanded = !this.isExpanded;
    }

    public checkHeightToWidthRatio(): void {
        const element = this.alertContainer.nativeElement;
        const height = element.clientHeight;
        const width = element.clientWidth;
        const ratio = width / height;

        if (!this.initialIsExpandable) {
            if (ratio < 2 && !this.isExpandable) {
                this.isExpandable = true;
                if (this.isExpanded) {
                    this.isExpanded = false;
                }
                if (this.initialWidth === null) {
                    this.initialWidth = width;
                }
            } else if (ratio >= 2 && this.isExpandable) {
                if (width > this.initialWidth) {
                    this.isExpandable = false;
                    if (!this.isExpanded) {
                        this.isExpanded = true;
                    }
                    this.initialWidth = null;
                }
            }
        }
    }
}
