import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from '@obo-main/services/auth/auth.models';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { DebugService } from '@obo-main/services/debugging/debug.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService } from '@uirouter/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private logger: Logger,
        private authService: AuthService,
        private debugService: DebugService,
        private stateService: StateService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpEvent<any>) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.logger.error('401 Interceptor: Received HTTP 401 from API. Trying to get Access...');
                        return this.authService.refreshAccessToken().pipe(
                            catchError((err: TokenResponse) => of(err)), // Error is also of type tokenresponse so we'll just pass it through
                            switchMap((tokenResponse) => {
                                if (tokenResponse.error) {
                                    this.logger.error('401 Interceptor: Auth failed. redirecting to login');
                                    return throwError(this.stateService.target('login'));
                                } else {
                                    this.logger.info('401 Interceptor: Authentication successful');
                                    const newRequest = request.clone({
                                        headers: request.headers.set('Authorization', `Bearer ${tokenResponse.access_token}`)
                                    });
                                    return next.handle(newRequest);
                                }
                            })
                        );
                    } else {
                        this.debugService.addOverlayEvent('ApiError ' + err.status, err.error);
                        return throwError(err);
                    }
                } else {
                    return throwError(err); // no knowledge how to handle this crap
                }
            })
        );
    }
}
