import { Injector } from '@angular/core';
import { languagesUpdateHook } from '@obo-main/factories/transitionHooks/languageUpdate.hook';
import { loadTranslationHook } from '@obo-main/factories/transitionHooks/loadTranslation.hook';
import { redirectHook } from '@obo-main/factories/transitionHooks/loginRedirect.hook';
import { moduleActiveHook } from '@obo-main/factories/transitionHooks/moduleActive.hook';
import { preLoadStatesHook } from '@obo-main/factories/transitionHooks/preloadStates.hook';
import { requiredRoleHook } from '@obo-main/factories/transitionHooks/requiredRole.hook';
import { savePreviousStateHook } from '@obo-main/factories/transitionHooks/savePreviousState.hook';
import { anchorScrollHook } from '@obo-main/factories/transitionHooks/scrollAnchor.hook';
import { spinnerHook } from '@obo-main/factories/transitionHooks/spinner.hook';
import { DebugService } from '@obo-main/services/debugging/debug.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { LoggerLevel } from '@obo-main/utils/logger/loggerLevel.enum';
import { Category, TargetState, UIRouter, UrlService } from '@uirouter/angular';
import { inAppNavigationHook } from './transitionHooks/inAppNavigation.hook';
import { goBackFromOverviewHook } from '@obo-main/factories/transitionHooks/goBackFromOverviewRedirect.hook';
import { tourHook } from '@obo-main/factories/transitionHooks/tour.hook';
import { moduleActionBarHook } from '@obo-main/factories/transitionHooks/moduleActionBar.hook';

export function RouterConfigFactory(router: UIRouter, injector: Injector) {
    return new Promise((resolve, reject) => {
        const transitionService = router.transitionService;
        const appSettings = injector.get(AppSettings) as AppSettings;
        const urlService = injector.get(UrlService) as UrlService;
        //register hooks(defined below)
        requiredRoleHook(transitionService);
        loadTranslationHook(transitionService);
        moduleActiveHook(transitionService);
        languagesUpdateHook(transitionService);
        savePreviousStateHook(transitionService);
        anchorScrollHook(transitionService);
        spinnerHook(transitionService);
        redirectHook(transitionService);
        moduleActionBarHook(transitionService);
        preLoadStatesHook(transitionService);
        tourHook(transitionService);
        inAppNavigationHook(transitionService);
        goBackFromOverviewHook(transitionService);

        //default error handler
        router.stateService.defaultErrorHandler((error: any) => {
            const logger = injector.get(Logger) as Logger;
            const debugService = injector.get(DebugService) as DebugService;
            logger.debug('Transition was unsuccessful. Details in Object output', error);
            debugService.onOverlayEvent.next({
                title: 'Transition Error',
                error: error
            });
            if (error.detail && error.detail instanceof TargetState) {
                router.stateService.transitionTo(error.detail.name());
            } else if (error && error.detail && error.detail.status === 401) {
                // handle httprobots401 in transition
                logger.error('Transition was unsuccessful because User is not logged in. Will redirect to LoginPage');
                router.stateService.go('login');
            } else {
                logger.error('Transition was unsuccessful. You will be redirected to ErrorPage');
                router.stateService.go('errorState', {
                    errorName: 'Transition Error',
                    error: error
                });
            }
        });

        if (appSettings.getItem('settings.logger_level') >= LoggerLevel.debug) {
            router.trace.enable(Category.TRANSITION);
        }
        if (appSettings.getItem('settings.logger_level') > LoggerLevel.debug) {
            //WARNING: enabling the following three debug tools will cause huge log spam. Internet explorer cant handle this and will freeze frequently!!!
            router.trace.enable(Category.UIVIEW);
            router.trace.enable(Category.RESOLVE);
            router.trace.enable(Category.VIEWCONFIG);
        }

        urlService.listen();
        urlService.sync();
        resolve('');
    });
}
