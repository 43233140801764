import { Component, Inject } from '@angular/core';
import { Project } from '@obo-dashboard/models/projectManagement.models';

@Component({
    selector: 'app-project-edit-modal',
    template: `
        <div id="projectEditModal" class="modal" cdkScrollable>
            <div class="modal-header justify-content-between">
                <h3 class="m-0">
                    {{ title }}
                </h3>

                <button class="btn-close" type="button" (click)="dismissFn()"></button>
            </div>
            <form #projectForm="ngForm" (ngSubmit)="saveFn(project)">
                <div class="modal-body">
                    <div class="mb-3 required">
                        <label class="form-label control-label" for="name">
                            {{ 'PROJECT_NAME' | translate }}
                        </label>
                        <input
                            [(ngModel)]="project.name"
                            type="text"
                            name="name"
                            id="name"
                            class="form-control form-control-border"
                            required
                            #name="ngModel"
                            shdValidateInput
                            shdValidateNaming />
                    </div>
                    <div class="mb-3">
                        <label class="form-label control-label" for="abbreviation">
                            {{ 'PROJECT_ABBREVIATION' | translate }}
                        </label>
                        <input
                            [(ngModel)]="project.abbreviation"
                            type="text"
                            name="abbreviation"
                            id="abbreviation"
                            tabindex="0"
                            class="form-control form-control-border" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label control-label" for="number">
                            {{ 'PROJECT_NUMBER' | translate }}
                        </label>
                        <input
                            [(ngModel)]="project.number"
                            type="text"
                            name="number"
                            id="number"
                            tabindex="0"
                            class="form-control form-control-border" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label control-label" for="customerName">
                            {{ 'PROJECT_CUSTOMERNAME' | translate }}
                        </label>
                        <input
                            [(ngModel)]="project.customerName"
                            type="text"
                            name="customerName"
                            id="customerName"
                            tabindex="0"
                            class="form-control form-control-border" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label control-label" for="customerNumber">
                            {{ 'PROJECT_CUSTOMERNUMBER' | translate }}
                        </label>
                        <input
                            [(ngModel)]="project.customerNumber"
                            type="text"
                            name="customerNumber"
                            id="customerNumber"
                            tabindex="0"
                            class="form-control form-control-border" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label control-label" for="orderDate">
                            {{ 'PROJECT_ORDERDATE' | translate }}
                        </label>
                        <input
                            class="form-control form-control-border"
                            id="orderDate"
                            type="date"
                            name="orderDate"
                            [(ngModel)]="project.orderDate" />
                    </div>

                    <small class="form-text text-muted">
                        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
                    </small>
                </div>

                <div class="modal-footer btn-container d-flex justify-content-end align-items-stretch">
                    <button class="btn btn-secondary me-2" type="button" (click)="dismissFn()">
                        {{ 'BTN_ABORT' | translate }}
                    </button>
                    <button class="btn btn-success mh-100" type="submit" [disabled]="projectForm.invalid">
                        {{ 'BTN_SAVE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    `
})
export class ProjectEditModalComponent {
    constructor(
        @Inject('project') public project: Project,
        @Inject('title') public title: string,
        @Inject('saveFn') public saveFn: (project: Project) => void,
        @Inject('dismissFn') public dismissFn: () => void
    ) {}
}
