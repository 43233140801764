import { Transition, TransitionService } from '@uirouter/core';
import { Utils } from '@obo-main/utils/utils.service';

/**
 * checks if the document shall be scrolled to the destination state. This is determined by the existence of scrollTo property in state's data object
 * @param transitionService
 */
export function anchorScrollHook(transitionService: TransitionService) {
    const pageScrollCriteria = {
        to: (state: any) => state.data && state.data.scrollTo
    };

    const action = (transition: Transition) => {
        const utils = transition.injector().get(Utils) as Utils;
        utils.scrollTo(transition.to().data.scrollTo, 100);
    };
    // add the hook to transitionsservice
    transitionService.onSuccess(pageScrollCriteria, action, { priority: 1 });
}
