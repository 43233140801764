import { AfterViewInit, Directive, ElementRef, Host, Inject, Input, Renderer2, forwardRef } from '@angular/core';
import { InlineEditComponent } from '../inlineEdit.component';

@Directive({
    selector: '[shdInlineEditButton]'
})
export class InlineEditButtonDirective implements AfterViewInit {
    @Input()
    public shdInlineEditButton: 'save' | 'discard' | 'edit';

    constructor(
        @Host()
        @Inject(forwardRef(() => InlineEditComponent))
        private inlineEditComponent: InlineEditComponent,
        private element: ElementRef,
        private renderer: Renderer2
    ) {}

    public ngAfterViewInit(): void {
        if (this.shdInlineEditButton === 'save') {
            this.renderer.setAttribute(this.element.nativeElement, 'type', 'submit');
        } else if (this.shdInlineEditButton === 'discard') {
            this.renderer.setAttribute(this.element.nativeElement, 'type', 'button');
            this.renderer.listen(this.element.nativeElement, 'click', () => this.inlineEditComponent.reset());
        } else if (this.shdInlineEditButton === 'edit') {
            this.renderer.listen(this.element.nativeElement, 'click', () => this.inlineEditComponent.changeMode());
        }
    }
}
