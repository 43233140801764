import { Transition, TransitionService } from '@uirouter/core';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { LanguageService } from '@obo-main/services/language/language.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * updates the available languages on module change
 * @param transitionService
 */
export function languagesUpdateHook(transitionService: TransitionService) {
    const languageUpdateNeededCriteria = {
        to: (state: any) => state.data && state.data.module
    };

    const action = (transition: Transition) => {
        const moduleService = transition.injector().get(ModuleService) as ModuleService;
        const logger = transition.injector().get(Logger) as Logger;
        const languageService = transition.injector().get(LanguageService) as LanguageService;
        const moduleId = moduleService.getModuleByName(transition.to().data.module).id;
        return languageService
            .getAvailableLanguagesForModuleById(moduleId)
            .pipe(
                mergeMap((result) => {
                    languageService.onAvailableLanguagesChange.next(result);
                    return of(true);
                }),
                catchError((err: any) => {
                    const msg = `Retrieving Languages for module ${moduleId} failed`;
                    logger.error(msg, err);
                    return of(true);
                })
            )
            .toPromise();
    };
    // add the hook to transitionsservice
    transitionService.onEnter(languageUpdateNeededCriteria, action, {
        priority: 10
    });
}
